import { Grid } from "@mui/material";
import CustomLink from "../CustomLink";
import useStyles from "./styles";

type Props = {
  tabs: string[];
  activeTab: number;
  setActiveTab: (tabId: number) => void;
};

export default function TabsList({ tabs, activeTab, setActiveTab }: Props) {
  const { classes: s } = useStyles();
  return (
    <Grid container justifyContent="center" className={s.linkWrapper}>
      {tabs.map((t, i) => (
        <Grid item key={t} alignItems="center" className={s.links}>
          <CustomLink
            label={t}
            isActive={i === activeTab}
            onClick={() => setActiveTab(i)}
          />
        </Grid>
      ))}
    </Grid>
  );
}
