import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import useStyles from "./WallpaperThemeStyles";
import { Blog } from "../../__generated__/graphql";

type Props = {
  coverImage: string;
  mobData: Pick<Blog, "theme" | "userId" | "id" | "name">;
  tabComponent: () => JSX.Element;
  renderMobCard: () => JSX.Element;
  renderMobList: () => JSX.Element;
  renderMobActivity: () => JSX.Element;
  renderCreateBtn: () => JSX.Element;
};

export default function WallpaperTheme({
  mobData,
  coverImage,
  renderMobCard,
  renderMobList,
  renderMobActivity,
  tabComponent,
  renderCreateBtn,
}: Props) {
  const { classes: s } = useStyles();
  return (
    <Box
      sx={{
        height: "100vh",
        maxHeight: "100vh",
        overflowY: "auto",
        backgroundImage: `url(${coverImage})`,
        backgroundSize: "cover",
      }}
    >
      <Box className={s.overlay}>
        {mobData.name && (
          <Box
            className={s.title}
            style={{
              position: "relative",
              marginTop: "25px",
            }}
          >
            {mobData.name}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box sx={{ mt: 2 }}>{tabComponent()}</Box>
          <Box sx={{ mt: 2 }}>{renderCreateBtn()}</Box>

          {/* main area */}
          <Container maxWidth="xl">
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {/* mob details section */}
              <Grid item xs={12} md={3}>
                {renderMobCard()}
              </Grid>
              {/* Post list section */}
              <Grid item xs={12} md={6}>
                {renderMobList()}
              </Grid>
              {/* Activity section */}
              <Grid item xs={12} md={3}>
                {renderMobActivity()}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}
