import { BLOGS_PRIVACY_LEVEL } from "./../utils/constants";
import { createContext, Dispatch, SetStateAction } from "react";
import { BlogTypes } from "../__generated__/graphql";

interface ThemeContextInterface {
  mode: string;
  changeMode: () => void;
}
interface SearchContextInterface {
  showSearch: boolean;
  setShowSearch: Dispatch<SetStateAction<boolean>>;
}

interface UserContextInterface {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
}
type MobTypes = {
  isOwner: boolean;
  alreadyMember: boolean;
  blogType: BlogTypes;
  blogPrivacyLevel: number;
  refetch: () => void;
};

// export interface Profile {
//   avatarImage?: string;
//   bio?: string;
//   createdAt: string;
//   dob?: Date;
//   firstName?: string;
//   gender?: string;
//   lastName?: string;
//   updatedAt?: string;
//   userId: number;
// }

export interface User {
  email: any;
  userId: number;
  userName: string;
  avatarImage?: string | null;
  isAdmin?: boolean;
  isModerator?: boolean;
  isCompleted?: boolean;
}

export const SearchContext = createContext<SearchContextInterface | null>(null);
export const ThemeContext = createContext<ThemeContextInterface | null>(null);
export const UserContext = createContext<UserContextInterface>({
  user: null,
  setUser: () => {},
});

const intialMobValues = {
  isOwner: false,
  alreadyMember: false,
  blogType: BlogTypes.Mobs,
  blogPrivacyLevel: BLOGS_PRIVACY_LEVEL.PUBLIC,
  refetch: () => {},
};

export const MobsContext = createContext<MobTypes>(intialMobValues);
