// import useStyles from "./styles";

interface Props {
  album: any;
  openModel: boolean;
  handleClodeModel: () => void;
  images: [];
  setImage: (data: any) => void;
}

export default function GalleryImageUploadModel(props: Props): JSX.Element {
  // const { album, openModel, handleClodeModel, images, setImage } = props;
  // const { classes } = useStyles();
  // const [albumImages, setAlbumImages] = useState<File[]>([]);
  // const [uploadingImages, setUploadingImages] = useState(false);

  // function imageUploadingProgress(value: number): void {
  //   // Image progress
  // }
  // function uploadAlbumImages(): void {
  //   // Upload the images to the album
  // }

  return <></>;

  // TODO
  // return (
  //   <Modal
  //     open={openModel}
  //     onClose={() => {
  //       handleClodeModel();
  //     }}
  //   >
  //     <Modal.Header>
  //       <Modal.Title style={{ fontSize: "20px", color: "#fff" }}>
  //         Create a New Album
  //       </Modal.Title>
  //     </Modal.Header>
  //     <Modal.Body>
  //       <h2 className={classes.subText}>Upload Images</h2>
  //       <Uploader
  //         draggable
  //         accept="image/png, image/jpeg, image/jpg"
  //         multiple
  //         disableMultipart
  //         autoUpload={false}
  //         style={{ backgroundColor: "transparent" }}
  //         onChange={(value: any) => {
  //           setAlbumImages(value);
  //         }}
  //       >
  //         <div>
  //           <BsCamera size="50px" />
  //           <HeightBox height="10px" />
  //           <p style={{ color: "#fff", fontSize: "18px" }}>
  //             Click or Drag the image
  //           </p>
  //         </div>
  //       </Uploader>
  //       <HeightBox height="20px" />

  //       <Button
  //         appearance="primary"
  //         onClick={() => {
  //           if (!uploadingImages) {
  //             uploadAlbumImages();
  //           }
  //         }}
  //       >
  //         {uploadingImages ? <Loader /> : "Upload"}
  //       </Button>
  //     </Modal.Body>
  //   </Modal>
  // );
}
