import { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { Box, Theme } from "@mui/material";

// pages
import ProtectedAdminRoute, {
  ProtectedModeratorRoutes,
} from "./utils/protectedAdminRoute";
import AdminDashboard from "./adminPages/AdminDashboard";
import UsersPage from "./adminPages/users";
import { UserContext } from "./Context";
import { LOCAL_KEY } from "./utils/constants";
import PostsPage from "./adminPages/Posts";
import AdminProfilePage from "./adminPages/ProfilePage";
import OverviewPage from "./adminPages/OverviewPage";
import AdminMobsPage from "./adminPages/AdminMobsPage";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));
export default function AdminRoutes() {
  const { classes } = useStyles();
  const { user } = useContext(UserContext);
  const token = localStorage.getItem(LOCAL_KEY.ACCESS_TOKEN);

  return (
    <Box className={classes.root}>
      <Routes>
        {/* authenticated admin routes */}
        <Route element={<ProtectedModeratorRoutes />}>
          <Route path="/admin/dashboard" element={<AdminDashboard />}>
            <Route path="" element={<OverviewPage />} />
            <Route path="mobs" element={<AdminMobsPage />} />
            <Route path="mobs/posts/:blogId" element={<PostsPage />} />
            <Route path="posts" element={<PostsPage />} />
            <Route path="profile" element={<AdminProfilePage />} />
            <Route element={<ProtectedAdminRoute />}>
              <Route path="/admin/dashboard/users" element={<UsersPage />} />
            </Route>
          </Route>
        </Route>

        <Route path="/logout" element={<Logout />} />
        {/* other routes redirect to 404 page */}
        <Route
          path="*"
          element={
            <Navigate to={user && token ? "/admin/dashboard" : "/"} replace />
          }
        />
      </Routes>
    </Box>
  );
}

const Logout = () => {
  useEffect(() => {
    localStorage.clear();
    window.location.replace("/");
  }, []);
  return <div></div>;
};
