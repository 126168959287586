import { PaletteMode, colors, createTheme } from "@mui/material";

const getLightThemeColors = () => {
  const mode: PaletteMode = "light";
  return {
    mode,
    background: { default: "#fff", paper: "#fff" },
    primary: { main: "#023047", light: "#8ECAE6", dark: "#219EBC" },
    secondary: { main: "#FB8500", light: "#FFB703" },
    error: { main: "#f44336" },
    text: { primary: "#023047", secondary: "#219EBC" },
    action: {
      disabledBackground: "#023047",
    },
  };
};

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#000",
      paper: "#000",
    },
    primary: { main: "#219EBC", dark: "#023047", light: "#8ECAE6" },
    secondary: { main: "#8ECAE6", light: "#FFB703" },
    error: { main: "#f44336" },
    text: { primary: "#219EBC", secondary: "#fff" },
  },
  typography: {
    fontFamily: ["Roboto Slab", "serif"].join(","),
    fontSize: 14,
    button: {
      textTransform: "capitalize",
    },
  },
});
const lightTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "6px 24px",
          "&.Mui-disabled": {
            background: colors.grey[300],
            border: `1px solid ${colors.grey[500]}`,
            cursor: "not-allowed",
          },
        },
        outlined: {
          color: getLightThemeColors().primary.main,
          "&:hover": {
            background: getLightThemeColors().secondary.main,
            border: `1px solid ${getLightThemeColors().secondary.main}`,
            color: getLightThemeColors().primary.main,
          },
        },

        contained: {
          color: getLightThemeColors().primary.light,
          border: `1px solid ${getLightThemeColors().primary.main}`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1.71,
          minWidth: "auto",
          paddingLeft: 0,
          paddingRight: 0,
          textTransform: "none",
          "& + &": {
            marginLeft: 24,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "15px 16px",
        },
      },
    },
  },
  palette: getLightThemeColors(),
  typography: {
    fontFamily: ["Roboto Slab", "serif"].join(","),
    fontSize: 14,
    button: {
      textTransform: "capitalize",
    },
  },
});

export { darkTheme, lightTheme };
