import React, { useState } from "react";

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import { getPostImage } from "../../utils/postHelper";
import ReactModal from "../ReactModal";
import { Post } from "../../__generated__/graphql";
import MobsListViewCard from "../MobsListViewCard";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../utils/routes";

type HighlightPostType =
  | Pick<Post, "id" | "originalPost" | "media" | "isShared" | "title">
  | undefined
  | null;

type Props = {
  postList: HighlightPostType[] | [];
  hasMore: boolean;
  loading: boolean;
  total: number;
  fetchMore: () => void;
};

export default function HighlightPosts({
  postList,
  hasMore,
  loading,
  total,
  fetchMore,
}: Props) {
  const [isOpen, setModal] = useState(false);

  const { classes } = useStyles();
  const navigate = useNavigate();

  if (!postList) return null;

  return (
    <div>
      <div
        className={classes.topPhotosContainer}
        tabIndex={0}
        onClick={() => {
          // show the top photos view
          // setShowTopPhotos(true);
        }}
        role="button"
        onKeyDown={() => {
          // show the top photos view
        }}
      >
        <p className={classes.subtitle}>Highlights</p>
        {postList && postList.length === 0 && (
          <Box sx={{ px: 2, pb: 1, textAlign: "center" }}>
            <p className={classes.info}>No posts are available</p>
          </Box>
        )}
        {!loading && postList.length > 0 && (
          <Box className={classes.imageWrapper}>
            {!!postList?.length && (
              <AvatarGroup
                total={total}
                spacing={"medium"}
                key={Math.random()}
                variant="rounded"
              >
                {postList.map((item: any) => (
                  <Tooltip title={item.title} key={item.id}>
                    <Avatar
                      src={getPostImage(item)}
                      onClick={() =>
                        navigate(`${ROUTES.POST_VIEW_PAGE}/${item.id}`)
                      }
                    />
                  </Tooltip>
                ))}
              </AvatarGroup>
            )}
            <Box
              sx={{ color: "text.secondary", fontSize: 12, fontWeight: 600 }}
            >
              <span
                onClick={() => setModal(true)}
                style={{ cursor: "pointer" }}
              >
                View all
              </span>
            </Box>
          </Box>
        )}
      </div>
      {isOpen && (
        <ReactModal isOpen={isOpen} handleClose={() => setModal(false)}>
          <Grid container spacing={2} sx={{ pb: 1 }}>
            <Grid item xs={12}>
              <Typography variant="h6">Highlights</Typography>
            </Grid>
            {/* {loading && <PageLoader />} */}
            {postList.map((m) => {
              if (!m) return null;
              return (
                <MobsListViewCard
                  title={m.title}
                  id={m.id}
                  imageUrl={getPostImage(m)}
                />
              );
            })}
            {hasMore && (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center", mt: 2 }}
              >
                <Button
                  onClick={() => fetchMore()}
                  sx={{
                    borderRadius: 5,
                    fontSize: 12,
                    color: "text.secondary",
                  }}
                >
                  Load more...
                </Button>
              </Grid>
            )}
          </Grid>
        </ReactModal>
      )}
    </div>
  );
}
