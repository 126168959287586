import { gql } from "../../__generated__";
import { useQuery } from "@apollo/client";
import UserEditPage from "./account";
import { Account } from "../../__generated__/graphql";

type AccountWrapperProps = {
  userId: number;
  handleClose: () => void;
  refetch: () => void;
};

export default function AccountWrapper({
  userId,
  handleClose,
  refetch,
}: AccountWrapperProps) {
  const { data, loading } = useQuery(USER_PROFILE, {
    variables: { userId },
    fetchPolicy: "network-only",
  });

  const getUserDetails = (
    user: Pick<Account, "userId" | "profile" | "email" | "roles">
  ) => {
    return {
      userId: user.userId,
      firstName: user.profile.firstName || "",
      lastName: user.profile.lastName || "",
      email: user.email,
      userName: user.profile.userName || "",
      dob: user.profile.dob || null,
      roles: user.roles,
      name: `${user.profile.firstName} ${user.profile.lastName || ""}`,
      avatarImage: user.profile.avatarImage || "",
    };
  };

  return (
    <UserEditPage
      handleClose={handleClose}
      refetchData={refetch}
      loading={loading}
      userDetails={
        data?.profileByUserId ? getUserDetails(data.profileByUserId) : null
      }
    />
  );
}

const USER_PROFILE = gql(`
  query ProfileByAdmin($userId:Int!){
      profileByUserId(userId:$userId){
        userId
        email
        userName
        privacyLevel
        taggable
        mentionable
        createdAt
        roles
        profile{
          userName
          avatarImage
          createdAt
          dob
          firstName
          lastName
          updatedAt
          userId
        }
      }
    }
`);
