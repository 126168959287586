import { TextField } from "@mui/material";
import { Controller, Control } from "react-hook-form";

type Options = {
  label: string;
  value: string | number;
};

type SelectInputProps = {
  name: string;
  label: string;
  control: Control<any, any>;
  options: Options[] | [];
};

export default function SelectInput({
  options,
  control,
  name,
  label,
}: SelectInputProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <>
          <TextField
            fullWidth
            name={name}
            select
            value={value || "empty"}
            label={label}
            onChange={(e) => onChange(e.target.value)}
            SelectProps={{ native: true }}
          >
            <option key="" value="empty">
              None
            </option>
            {options.map((op) => (
              <option key={op.value} value={op.value}>
                {op.label}
              </option>
            ))}
          </TextField>
        </>
      )}
    />
  );
}
