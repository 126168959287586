import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
    position: "relative",
    height: "45vh",
    backgroundSize: "cover",
    backgroundColor: "rgb(176 216 229 / 25%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
    padding: "20px 0",
    color: theme.palette.text.primary,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(21, 21, 21, 0.5)"
        : "rgba(255, 255, 255, 0.5)",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    // textTransform: "capitalize",
  },
  uploadWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  uploader: {
    padding: 15,
    borderRadius: "50%",
    border: `1px solid ${theme.palette.text.primary}`,
    width: 80,
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginBottom: 5,
    "&:hover": {
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  editCover: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  editLabel: {
    padding: "10px 15px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    color: theme.palette.text.secondary,
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100%",
    backgroundColor: "rgb(176 216 229 / 70%)",
  },
  uploadBtnWrapper: {
    marginBottom: 15,
  },
}));

export default useStyles;
