import { Theme, colors } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    color: theme.palette.text.primary,
    marginBottom: 50,
  },
  backgroundContainer: {
    backgroundColor: "#E8EAE6",
  },
  bottomIcons: {
    marginTop: 5,
    marginLeft: 5,
    cursor: "pointer",
  },
  blogHead: {
    paddingTop: "80px",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "350px",
    color: "black",
    overflowY: "hidden",

    backgroundSize: "cover",
  },
  mainContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: 10,
    minHeight: "100vh",
    overflowX: "hidden",
  },
  blogName: {
    fontSize: "45px",
    fontWeight: "bold",
    color: "#000",
  },
  topContainer: {
    height: 250,
    overflowY: "hidden",
  },
  imageContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  blogTitleContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 50,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  mainTitle: {
    fontSize: 30,
    textTransform: "capitalize",
    textAlign: "center",
  },
  postTitle: {
    fontSize: 24,
    fontWeight: 600,
    textTransform: "capitalize",
  },
  blogTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  postBox: {
    marginTop: 20,
    position: "relative",
  },
  images: {
    width: "100%",
    borderRadius: "10px 10px 0 0",
  },
  commentInputContainer: {
    width: "100%",
    padding: 10,
  },
  commentInput: {
    fontSize: 15,
  },
  iconContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  descriptionContainer: {
    maxWidth: 800,
    marginLeft: "auto",
    marginRight: "auto",
  },
  commentContainer: {
    maxWidth: 800,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 30,
    paddingRight: 30,
  },
  commentSecDiv: {
    backgroundColor: "#F1F1F6",
    paddingTop: 10,
    marginBottom: 20,
    borderRadius: 5,
  },
  tagContainer: {
    display: "flex",
    flexDirection: "row",
    maxWidth: 800,
    marginLeft: "auto",
    marginRight: "auto",
  },
  titleContainer: {
    maxWidth: 800,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "left",
  },
  postDescription: {
    padding: "10px 0",
  },
  tagText: {
    margin: 0,
    fontSize: 14,
    fontWeight: "bold",
    marginRight: 15,
    color: theme.palette.text.secondary,
  },
  infoContainer: {
    color: theme.palette.text.secondary,
  },
  postInfo: {
    padding: 20,
  },
  activityContainer: {
    backgroundColor: "#F1F1F6",
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 5,
  },
  acitivityTopic: {
    fontWeight: "bolder",
    fontSize: 28,
  },
  commentBox: {
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: 10,
    padding: 20,
    marginBottom: 30,
  },
  commentUserDetails: {
    display: "flex",
    flexDirection: "row",
  },
  userName: {
    marginTop: 0,
    fontSize: "16px",
    color: "rgba(0,0,0,0.5)",
  },
  locationPlace: {
    fontSize: "16px",
    color: "#000",
    marginLeft: 0,
  },
  followingCount: {
    fontWeight: "bold",
    color: "#000",
    fontSize: 16,
  },
  followTag: {
    marginLeft: -3,
    fontSize: 16,
  },
  settingsButton: {
    height: "40px",
    width: "100px",
    fontSize: "15px",
    borderRadius: "20px",
    backgroundColor: "#000",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.5)",
    },
  },
  followButton: {
    height: "40px",

    fontSize: "15px",
    borderRadius: "20px",
  },
  commentText: {
    fontSize: 16,
  },
  postWrapper: {
    borderRadius: 10,
    color: theme.palette.text.primary,
    background: "rgba(33, 158, 188, 0.15)",
    position: "relative",
  },
  actionButtons: {
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  actionGroup: {
    "&.selected svg": {
      cursor: "pointer",
    },
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  commetsWrapper: {
    marginTop: 40,
  },
  imageWrapper: {
    position: "relative",
  },
  cover: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "auto",
    width: "100%",
    borderRadius: "10px 10px 0 0",
  },
  coverImage: {
    maxWidth: "100%",
    borderRadius: "10px 10px 0 0",
    width: "100%",
  },
  wallpaper: {
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgb(15 15 15 / 85%)"
        : "rgb(255 255 255 / 85%)",
  },
  shared: {
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgb(15 15 15 / 85%)"
        : "rgb(255 255 255 / 85%)",
  },
  sharedCover: {
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgb(15 15 15 / 85%)"
        : "rgb(33 158 188 / 15%)",
  },
  icon: {
    cursor: "pointer",
  },
  postedBy: {
    marginBottom: "5px",
    "&:hover": {
      textDecoration: "underline",
    },
    cursor: "pointer",
  },
  deleteButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px 4px",
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: 6,
    marginLeft: 8,
    color: "primary",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid white",
      color: "white",
    },
  },
  cancelButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px 4px",
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: 6,
    marginLeft: 8,
    color: "primary",
    cursor: "pointer",
    background: colors.grey[600],
    "&:hover": {
      border: "1px solid white",
      color: "white",
    },
  },
  shareDate: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginLeft: 4,
  },
  // userName: {
  //   fontWeight: 500,
  //   cursor: "pointer",
  //   "&:hover": {
  //     textDecoration: "underline",
  //   },
  //   textTransform: "capitalize",
  // },
}));

export default useStyles;
