import React, { useContext, useState } from "react";
import { Blog } from "../../__generated__/graphql";
import WallpaperTheme from "./WallpaperTheme";
import TabsList from "../../components/TabsList";
import { MobsContext, UserContext } from "../../Context";
import MobDetailsCard from "../../components/MobDetailsCard";
import MobPostList from "../../components/MobPostList";
import MobActivity from "../../components/MobActivity";
import moment from "moment";
import CustomButton from "../../components/CutomButton";
import TimelineTheme from "./TimelineTheme";

type Props = {
  isWallpaperTheme: boolean;
  isTimelineTheme: boolean;
  mobData: Pick<
    Blog,
    | "theme"
    | "userId"
    | "id"
    | "name"
    | "createdAt"
    | "themeMediaInfo"
    | "members"
    | "privacyLevelInfo"
    | "membersJoiningType"
  >;
};

const tabs = ["Posts", "Likes", "Comments"];

export default function ThemeWrapper({
  mobData,
  isTimelineTheme,
  isWallpaperTheme,
}: Props) {
  // state
  const [activeTab, setActiveTab] = useState(0);
  const [showModal, setModal] = useState(false);

  // hooks
  const { user } = useContext(UserContext);
  const { isOwner, alreadyMember } = useContext(MobsContext);

  const renderTab = () => {
    return (
      <TabsList
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={(id) => setActiveTab(id)}
      />
    );
  };

  //   Component list start

  const renderMobCard = () => {
    return (
      <MobDetailsCard
        mobId={mobData.id}
        title={mobData.name || ""}
        members={mobData.members?.map((u) => u?.userId || 0) || []}
        currentPrivacy={mobData.privacyLevelInfo.id}
        currentPermission={mobData.membersJoiningType || 0}
        createdAt={moment(mobData.createdAt).utc().format("MMMM YYYY")}
      />
    );
  };

  const renderMobList = () => {
    return (
      <MobPostList
        mobId={mobData.id}
        activeTabId={activeTab}
        showModal={showModal}
        setModal={setModal}
      />
    );
  };

  const renderMobActivity = () => {
    return (
      <MobActivity
        blogId={mobData.id}
        pageOwner={true}
        userId={user?.userId || 0}
      />
    );
  };

  const renderCreateBtn = () => {
    const showCreateBtn = isOwner || alreadyMember;
    if (!showCreateBtn) return <></>;
    return (
      <CustomButton
        label="Create post"
        onClick={() => {
          setModal(true);
        }}
      />
    );
  };

  //   Component List end

  const childProps = {
    coverImage: mobData.themeMediaInfo?.url || "",
    mobData,
    tabComponent: renderTab,
    renderMobCard,
    renderMobList,
    renderMobActivity,
    renderCreateBtn,
  };

  return (
    <div>
      {isTimelineTheme && <TimelineTheme {...childProps} />}

      {isWallpaperTheme && <WallpaperTheme {...childProps} />}
    </div>
  );
}
