import { ApolloError } from "@apollo/client/errors";

function graphqlErrorToMsg(error: ApolloError | undefined) {
  const getErrorMsg = () => {
    if (error) {
      const { graphQLErrors, networkError } = error;
      if (networkError) {
        return networkError.message;
      }
      if (graphQLErrors) {
        const [gglError] = graphQLErrors;
        return gglError.message.replace("GraphQL error:", "");
      }
    }
    return "Something went wrong";
  };

  let finalError = getErrorMsg();

  return finalError;
}
export default graphqlErrorToMsg;
