import {
  Box,
  Container,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
  colors,
  SvgIcon,
} from "@mui/material";
import { gql } from "../../__generated__";
import { useMutation, useQuery } from "@apollo/client";
import PageLoader from "../../components/PageLoader";
import AdminBreadcrumbs from "../AdminBreadcrumbs/AdminBreadcrumbs";
import PostForm from "../../components/PostForm";
import AdminCardActions from "../../containers/PostCardContainer/adminActions";
import { BsTrash } from "react-icons/bs";
import { useState } from "react";
import PostDeleteModal from "../../components/SinglePostComponents/PostDeleteModal";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { postEditStateToApi } from "../../utils/postHelper";

type PostEditPageProps = {
  handleClose: () => void;
  postId: number;
  refetchPostList: () => void;
};

const PostEditPage = ({
  handleClose,
  postId,
  refetchPostList,
}: PostEditPageProps) => {
  const [showDeletePost, setDeletePost] = useState(false);

  const [updatePost, { loading: isUpdating }] = useMutation(UPDATE_POST, {
    onCompleted: () => {
      toast.success("Post updated successfully");
      refetchPostList();
    },
    onError: (error) => toast.error(graphqlErrorToMsg(error)),
  });

  const { data, loading } = useQuery(POST_VIEW, {
    variables: { postId },
    fetchPolicy: "network-only",
  });

  if (loading) return <PageLoader />;

  if (!data?.viewPost) {
    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Typography variant="h4">
          Something went wrong with post details
        </Typography>
      </Box>
    );
  }
  const breadcrumbs = [
    <Typography
      key="2"
      color="inherit"
      onClick={handleClose}
      sx={{ cursor: "pointer" }}
    >
      Posts
    </Typography>,
    <Typography key="3" color="text.primary">
      View
    </Typography>,
  ];

  const renderButtons = () => {
    if (data?.viewPost) {
      return (
        <AdminCardActions
          blogId={data.viewPost?.blogId}
          postId={data.viewPost?.id}
          flagged={!!data.viewPost.flagged}
          highlighted={!!data.viewPost?.highlighted?.postId}
          refetch={() => refetchPostList()}
        />
      );
    }
    return <></>;
  };

  const renderPostDelete = () => {
    if (data.viewPost) {
      return (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              right: 10,
              top: 15,
              background: "white",
              borderRadius: 1,
              p: 0.5,
              cursor: "pointer",
              "&:hover": {
                background: colors.red[400],
                color: "white",
              },
            }}
          >
            <SvgIcon onClick={() => setDeletePost(true)}>
              <BsTrash />
            </SvgIcon>
          </Box>
        </Box>
      );
    }
    return <></>;
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <Box sx={{ px: 3 }}>
              <AdminBreadcrumbs breadcrumbs={breadcrumbs} />
            </Box>
            <div>
              <Grid container spacing={0}>
                <Grid xs={12}>
                  <PostForm
                    isUpdate
                    isPersonalBlogPost={!!data.viewPost.blog?.default}
                    loading={isUpdating}
                    postId={data.viewPost.id}
                    renderAdminButtons={renderButtons}
                    renderPostDelete={renderPostDelete}
                    handleCancel={() => handleClose()}
                    handleSubmit={(values) => {
                      if (data.viewPost) {
                        const apiReq = postEditStateToApi({
                          states: values,
                          blogId: data.viewPost.blogId,
                          postId: data.viewPost.id,
                        });
                        updatePost(apiReq);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
        {showDeletePost && (
          <PostDeleteModal
            id={data.viewPost.id}
            handleClose={() => setDeletePost(false)}
            isOpen={showDeletePost}
            handleComplete={() => {
              setDeletePost(false);
              refetchPostList();
              handleClose();
            }}
          />
        )}
      </Box>
    </>
  );
};

export default PostEditPage;

const POST_VIEW = gql(`
  query ViewPostByAdmin($postId:Int!){
    viewPost(postId:$postId){
      id
      userId
      blogId
      title
      description
      privacyLevel
      hidden
      flagged
      highlighted{
        postId
      }
      allowReshare
      media{
        id
        postId
        fileUrl
      }
      originalPost{
        id
        userId
        blogId
        title
        description
        privacyLevel
        isShared
        media{
          id
          postId
          fileUrl
        }
      }
      blog{
        id
        default
      }
      isShared
      createdAt
      updatedAt
      
    }
  }
`);

const UPDATE_POST = gql(`
  mutation updatePost($info: UpdatePostInputs!, $file: Upload) {
    updatePost(info: $info, file: $file) {
      id
      userId
      blogId
      title
      description
      privacyLevel
      hidden
      isShared
      shareText
      flagged
      media{
        id
        postId
        fileUrl
      }
      originalPost{
        id
        userId
        blogId
        title
        description
        privacyLevel
        isShared
        blog{
          id
          name
        }
        media{
          id
          postId
          fileUrl
        }
      }
    }
  }`);
