import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { gql } from "../../../__generated__";
import ReactHooksInput from "../../ReactHooksInput";
import { useMutation } from "@apollo/client";
import graphqlErrorToMsg from "../../../utils/graphqlErrorToMsg";
import useStyles from "./styles";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import CustomButton from "../../CutomButton";

const schema = yup
  .object()
  .shape({
    name: yup.string().required().label("Moblog name"),
    about: yup.string().required().label("About"),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export default function BlogForm({
  blogId,
  blogName,
  about,
  isMob,
}: {
  blogId: number;
  blogName: string;
  about: string;
  isMob?: boolean;
}) {
  const { classes: s } = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      name: blogName,
      about,
    },
  });

  const [editBlogByUser, { loading, error }] = useMutation(EDIT_BLOG, {
    onCompleted: () => {
      toast.success("Moblog details updated successfully");
    },
  });

  const handleEditBlog = (values: FormData) => {
    editBlogByUser({
      variables: {
        info: {
          blogId,
          name: values.name,
          about: values.about,
        },
      },
    });
  };

  return (
    <div className={s.form}>
      <form onSubmit={handleSubmit(handleEditBlog)}>
        <ReactHooksInput
          label={isMob ? "Mob Name" : "Moblog Name"}
          name="name"
          required
          placeholder="Name"
          register={register}
          error={errors.name?.message}
          touched={touchedFields.name}
        />

        <ReactHooksInput
          label={isMob ? "About Mob" : "About Moblog"}
          name="about"
          required
          inputType="textarea"
          placeholder="About"
          register={register}
          error={errors.about?.message}
          touched={touchedFields.about}
        />

        {error && <div className={s.errorMsg}>{graphqlErrorToMsg(error)}</div>}

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CustomButton
            label={
              loading ? "Updating..." : isMob ? "Update Mob" : "Update Moblog"
            }
            onClick={() => {}}
            type="submit"
          />
        </Box>
      </form>
    </div>
  );
}

const EDIT_BLOG = gql(`
  mutation editBlogByUser($info: BlogEditUserInput!) {
    editBlogByUser(info: $info) {
        id
        name
        title
        userId
        blogType
        theme
        privacyLevel
        privacyLevelInfo{
          id
          name
        }
        themeInfo{
          id
          name
        }
        themeMediaInfo{
          blogId
          themeId
          url
        }
        followingCount
        friendsCount
        followersCount 
        createdAt
    }
  }
`);
