import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    color: theme.palette.text.primary,
    background: theme.palette.background.default,
  },
  overlay: {
    backgroundColor: theme.palette.mode === "dark" ? "#00000066" : "#00000026",
  },
  cover: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "45vh",
  },
  backgroundContainer: {
    backgroundColor: "#E8EAE6",
  },
  bottomIcons: {
    marginTop: 5,
    marginLeft: 5,
    cursor: "pointer",
  },
  blogHead: {
    paddingTop: "80px",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "350px",
    color: "black",
    overflowY: "hidden",

    backgroundSize: "cover",
  },
  mainContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: 10,
    minHeight: "100vh",
    overflowX: "hidden",
  },
  blogName: {
    fontSize: "45px",
    fontWeight: "bold",
    color: "#000",
  },
  topContainer: {
    height: 250,
    overflowY: "hidden",
  },
  coverImage: {
    marginTop: -100,
    width: "100vw",
  },
  imageContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  blogTitleContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 50,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  postTitle: {
    fontSize: 30,

    textAlign: "left",
  },
  postBox: {
    backgroundColor: "rgba(255,255,255,0.7)",
    padding: 20,
  },
  images: {
    width: "100%",
  },
  commentInputContainer: {
    width: "100%",
    padding: 10,
  },
  commentInput: {
    fontSize: 15,
  },
  iconContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  descriptionContainer: {
    maxWidth: 800,
    marginLeft: "auto",
    marginRight: "auto",
  },
  commentContainer: {
    maxWidth: 800,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 30,
    paddingRight: 30,
  },
  commentSecDiv: {
    backgroundColor: "#F1F1F6",
    paddingTop: 10,
    marginBottom: 20,
    borderRadius: 5,
  },
  tagContainer: {
    display: "flex",
    flexDirection: "row",
    maxWidth: 800,
    marginLeft: "auto",
    marginRight: "auto",
  },
  titleContainer: {
    maxWidth: 800,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "left",
  },
  postDescription: {
    fontSize: 15,
  },
  tagText: {
    margin: 0,
    fontSize: 14,
    fontWeight: "bold",
    marginRight: 15,
    color: "rgba(0,0,0,0.4)",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "auto",
    marginRight: "auto",
  },
  postInfo: {
    margin: 0,
    marginRight: 20,
    fontSize: 16,
    fontWeight: 600,
  },
  activityContainer: {
    backgroundColor: "#F1F1F6",
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 5,
  },
  acitivityTopic: {
    fontWeight: "bolder",
    fontSize: 28,
  },
  commentBox: {
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: 10,
    padding: 20,
    marginBottom: 30,
  },
  commentUserDetails: {
    display: "flex",
    flexDirection: "row",
  },
  userName: {
    marginTop: 0,
    fontSize: "16px",
    textAlign: "center",
    color: "rgba(0,0,0,0.5)",
  },
  locationPlace: {
    fontSize: "16px",
    color: "#000",
    marginLeft: 0,
  },
  followingCount: {
    fontWeight: "bold",
    color: "#000",
    fontSize: 16,
  },
  followTag: {
    marginLeft: -3,
    fontSize: 16,
  },
  settingsButton: {
    height: "40px",
    width: "100px",
    fontSize: "15px",
    borderRadius: "20px",
    backgroundColor: "#000",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.5)",
    },
  },
  followButton: {
    height: "40px",

    fontSize: "15px",
    borderRadius: "20px",
  },
  commentText: {
    fontSize: 16,
  },
}));

export default useStyles;
