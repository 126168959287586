import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import ClipboardDocumentListIcon from "@heroicons/react/24/solid/ClipboardDocumentListIcon";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import { SvgIcon } from "@mui/material";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import ADMIN_ROUTES from "../utils/adminRoutes";

type ItemList = {
  title: string;
  path: string;
  icon: JSX.Element;
  disabled?: boolean;
  external?: boolean;
  isAdminRoute?: boolean;
};

const items: ItemList[] = [
  {
    title: "Overview",
    path: "/",
    icon: (
      <SvgIcon fontSize="small">
        <ChartBarIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Users",
    path: ADMIN_ROUTES.USERS,
    isAdminRoute: true,
    icon: (
      <SvgIcon fontSize="small">
        <UsersIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Moblogs",
    path: ADMIN_ROUTES.MOBS,
    isAdminRoute: true,
    icon: (
      <SvgIcon fontSize="small">
        <FeaturedPlayListIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Posts",
    path: ADMIN_ROUTES.POSTS,
    icon: (
      <SvgIcon fontSize="small">
        <ClipboardDocumentListIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Profile",
    path: ADMIN_ROUTES.PROFILE,
    icon: (
      <SvgIcon fontSize="small">
        <UserIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Logout",
    path: "/logout",
    icon: (
      <SvgIcon fontSize="small">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
          />
        </svg>
      </SvgIcon>
    ),
  },
];

export function getItems(isAdmin?: boolean) {
  if (isAdmin) return items;
  return items.filter((item) => !item.isAdminRoute);
}
