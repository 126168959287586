import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  error: {
    color: "red !important",
    padding: "0 2px",
  },
  label: { fontSize: 14 },
}));

export default useStyles;
