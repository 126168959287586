import React, { useContext } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import {
  AiOutlinePlusSquare,
  AiOutlineSearch,
  AiOutlineSetting,
} from "react-icons/ai";
import { FaRegUserCircle } from "react-icons/fa";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { SearchContext, User } from "../../Context";

interface MobileViewProps {
  setMobileOpen: () => void;
  handleOpenSettingsMenu: (event: React.MouseEvent<HTMLElement>) => void;
  userDetails?: User | null;
}

export default function MobileView({
  setMobileOpen,
  userDetails,
  handleOpenSettingsMenu,
}: MobileViewProps): JSX.Element {
  // hooks
  const navigate = useNavigate();
  const { classes: s } = useStyles();
  const searchContext = useContext(SearchContext);

  return (
    <Box sx={{ width: "100%", display: { xs: "flex", lg: "none" } }}>
      <Grid container alignItems="center">
        <Grid
          item
          xs={2}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => setMobileOpen()}
            sx={{ color: "inherit" }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>

        <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/dashboard"
              className={s.logo}
              onClick={() => navigate("/dashboard")}
            >
              Moblog
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={7} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={{ display: { xs: "flex" } }}>
            <IconButton
              size="medium"
              color="primary"
              onClick={() =>
                searchContext?.setShowSearch(!searchContext.showSearch)
              }
              className={s.iconButton}
            >
              <AiOutlineSearch />
            </IconButton>
            <IconButton
              size="medium"
              color="primary"
              onClick={() => navigate(`/user/${userDetails?.userId}`)}
              className={s.iconButton}
            >
              <FaRegUserCircle color="primary.main" size={20} />
            </IconButton>
            <IconButton
              size="medium"
              color="primary"
              className={s.iconButton}
              onClick={() => navigate("/create-post")}
            >
              <AiOutlinePlusSquare />
            </IconButton>
            <IconButton
              size="medium"
              color="primary"
              className={s.iconButton}
              onClick={handleOpenSettingsMenu}
            >
              <AiOutlineSetting />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
