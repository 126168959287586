import React from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Grid } from "@mui/material";
// import { BsFillReplyAllFill } from "react-icons/bs";
// import { RiChatFollowUpFill } from "react-icons/ri";
// import { FaRegSadTear, FaUserFriends, FaShare } from "react-icons/fa";
import { AiOutlineComment } from "react-icons/ai";
// import { MdMessage } from "react-icons/md";
// import { BiLike } from "react-icons/bi";
import useStyles from "./styles";
import { PostAction } from "../../__generated__/graphql";
import commentTimeCalculate from "../../functions/CommentTimeCalculation";

interface Props {
  action: PostAction | null;
  isActivityAction?: boolean;
}

export default function BlogActionItem(props: Props): JSX.Element {
  const { action, isActivityAction } = props;
  const navigate = useNavigate();
  const { classes } = useStyles();

  if (!action) return <></>;

  const accountIdfk = action.profile;
  const name = `${accountIdfk?.firstName} ${accountIdfk?.lastName}`;
  const username = accountIdfk?.userName;
  // const postId = action?.postId;
  // const commentId = action?.commentId;
  const actionType = action?.type;

  let actionText = "commented";
  let routingPath = accountIdfk?.userName;
  let icon = <AiOutlineComment className={classes.iconColor} />;

  // switch (actionType) {
  //   case "FOLLOW":
  //     actionText = "followed this";
  //     routingPath = accountIdfk?.userName;
  //     break;
  //   case "UNFOLLOW":
  //     actionText = "unfollowed this";
  //     icon = <FaRegSadTear className={classes.iconColor} />;
  //     routingPath = accountIdfk?.userName;
  //     break;
  //   case "FOLLOW_BACK":
  //     actionText = "followed back";
  //     icon = <FaUserFriends className={classes.iconColor} />;
  //     routingPath = accountIdfk?.userName;
  //     break;
  //   case "COMMENT":
  //     icon = <AiOutlineComment className={classes.iconColor} />;
  //     actionText = "commented";
  //     routingPath = `post/${postId}/${commentId}`;
  //     break;
  //   case "LIKE":
  //     actionText = "liked";
  //     icon = <BiLike className={classes.iconColor} />;
  //     routingPath = `post/${postId}`;
  //     break;
  //   case "SHARE":
  //     icon = <FaShare className={classes.iconColor} />;
  //     actionText = "shared";
  //     routingPath = `post/${postId}`;
  //     break;
  //   case "REPLY":
  //     actionText = "replied on this";
  //     icon = <BsFillReplyAllFill className={classes.iconColor} />;
  //     routingPath = `post/${postId}/${commentId}`;
  //     break;
  //   default:
  //     break;
  // }

  function gotToRoute(): void {
    navigate(`/${routingPath}`);
  }

  function routeToBlog(): void {
    if (username) {
      navigate(`/${username}`);
    }
  }

  return (
    <Grid container className={classes.root} alignItems="center">
      <Grid item>
        <Avatar src={accountIdfk?.avatarImage || ""} />
      </Grid>
      <Grid item xs className={classes.right}>
        <Box className={classes.userName} onClick={() => routeToBlog()}>
          {name}
          {/* {username !== null ? username : name} */}
        </Box>
        {!isActivityAction ? (
          <Box className={classes.actionWrapper}>
            {icon}
            <span className={classes.timestamp}>
              {commentTimeCalculate(action.createdAt)}
            </span>
          </Box>
        ) : (
          <>
            <span className={classes.timestamp}>
              {commentTimeCalculate(action?.createdAt)}
            </span>
            <Box className={classes.normalText} onClick={() => gotToRoute()}>
              {actionText}
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
}

BlogActionItem.defaultProps = {
  isActivityAction: false,
};
