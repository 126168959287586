import {
  Avatar,
  Box,
  Card,
  SvgIcon,
  Tooltip,
  Typography,
  colors,
} from "@mui/material";
import { useState } from "react";
import CustomButton from "../CutomButton";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate } from "react-router-dom";
import CardMenuWrapper from "./CardMenuWrapper";
import useStyles from "./MobCardStyles";

type Props = {
  image?: string | null;
  mobName?: string | null;
  id?: number | null;
  isOwner: boolean;
  members: [] | number[];
  currentPrivacy: any;
  currentPermission: any;
  isMobCard?: boolean;
  isByBeingAdded: boolean;
  refetchMobs: () => void;
};

export default function MobCard({
  image,
  mobName,
  id,
  isOwner,
  members,
  currentPrivacy,
  currentPermission,
  isMobCard,
  isByBeingAdded,
  refetchMobs,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { classes: s } = useStyles();

  const [allModals, toggleModals] = useState({
    memberModal: false,
    deleteModal: false,
    privacyModal: false,
    permissionModal: false,
    leaveModal: false,
    genarateLinkModal: false,
    editModal: false,
  });

  const [addedMembers, setAddedMembers] = useState(members);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const count = addedMembers.length;
  const navigate = useNavigate();

  const renderNameAndCount = () => (
    <Box>
      <Tooltip title={mobName}>
        <Typography
          textOverflow={"ellipsis"}
          className={s.mobName}
          onClick={() => navigate(`/moblog/${id}`)}
        >
          {mobName}
        </Typography>
      </Tooltip>
      {count > 0 && (
        <Typography
          textOverflow={"ellipsis"}
          fontSize={12}
          color={"text.secondary"}
        >
          {count} {count > 1 ? "Members" : "Member"}
        </Typography>
      )}
    </Box>
  );

  const renderMemberBtn = () => (
    <Box>
      <Box className={s.infoBtn}>{isOwner ? "Owner" : "Member"}</Box>
    </Box>
  );

  const renderLeaveOrSettingBtn = () => {
    if (!isOwner) {
      return (
        <CustomButton
          label="Leave"
          onClick={() => toggleModals((s) => ({ ...s, leaveModal: true }))}
          sx={{
            p: 0,
            color: colors.red[400],
            border: `1px solid ${colors.red[400]} !important`,
          }}
          variant="outlined"
        />
      );
    } else {
      return (
        <Box onClick={handleClick}>
          <SvgIcon>
            <SettingsOutlinedIcon />
          </SvgIcon>
        </Box>
      );
    }
  };

  return (
    <Card sx={{ padding: { xs: 1, md: 2 }, mb: 3 }}>
      <Box className={s.root}>
        <Box
          sx={{
            width: isOwner ? { xs: "90%" } : { xs: "75%", md: "80%" },
          }}
          className={s.left}
        >
          <Avatar
            src={image || ""}
            alt="profile"
            sx={{ width: 60, height: 60 }}
          />
          <Box className={s.titleWrapper}>
            {renderNameAndCount()}
            {renderMemberBtn()}
          </Box>
        </Box>
        <Box
          className={s.right}
          sx={{
            width: isOwner ? { xs: "10%" } : { xs: "25%", md: "20%" },
          }}
        >
          {renderLeaveOrSettingBtn()}
        </Box>
      </Box>
      {id && (
        <CardMenuWrapper
          id={id}
          allModals={allModals}
          isCardMenuOpen={open}
          isMobCard={!!isMobCard}
          isByBeingAdded={isByBeingAdded}
          refetchMobs={refetchMobs}
          toggleModals={(key, value) =>
            toggleModals((s) => ({ ...s, [key]: value }))
          }
          closeCardMenu={() => handleClose()}
          anchorEl={anchorEl}
          currentPrivacy={currentPrivacy}
          currentPermission={currentPermission}
          addedMembers={addedMembers}
          updateMemberList={(list) => setAddedMembers(list)}
        />
      )}
    </Card>
  );
}
