import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import CustomInput from "../CustomInput";
import DndUpload from "../DndUpload";

import useStyles from "./styles";
import TagButton from "../TagButton";
import { POSTS_PRIVACY_LEVELS } from "../../utils/constants";
import CustomButton from "../CutomButton";
import FileUploader from "../FileUploader";
import { PostFormTypes, getPostEditDetails } from "../../utils/postHelper";
import { BlogTypes } from "../../__generated__/graphql";
import { gql } from "../../__generated__";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import PageLoader from "../PageLoader";

const initialValues = {
  title: "",
  text: "",
  allowReshare: true,
  file: null,
  privacyLevel: POSTS_PRIVACY_LEVELS.PUBLIC,
};

interface PostFormProps {
  postId?: number;
  isOnlyMe?: boolean;
  handleSubmit: (v: PostFormTypes) => void;
  loading: boolean;
  isUpdate?: boolean;
  isPersonalBlogPost: boolean;
  renderAdminButtons?: () => JSX.Element;
  renderPostDelete?: () => JSX.Element;
  handleCancel: () => void;
}

export default function PostForm({
  handleSubmit,
  loading,
  isUpdate,
  postId,
  isOnlyMe,
  isPersonalBlogPost,
  handleCancel,
  renderAdminButtons,
  renderPostDelete,
}: PostFormProps) {
  const { classes: s } = useStyles();

  // fetch post data
  const [getPostData, { data: postData, loading: isLoading }] = useLazyQuery(
    POST_VIEW,
    {
      onError: (err) => toast.error(graphqlErrorToMsg(err)),
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (postId) {
      getPostData({ variables: { postId } });
    }
  }, [getPostData, postId]);

  const postDetails = getPostEditDetails(postData?.viewPost);

  // form states
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<any>(null);

  useEffect(() => {
    if (postDetails.image) {
      setPreview(postDetails.image);
    }
  }, [postDetails.image]);

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    }
  }, [file]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required().label("title"),
    text: Yup.string().required().label("Text"),
    file: isUpdate
      ? Yup.string().nullable()
      : Yup.string().required().label("Image"),
  });

  const isLogPost = postData?.viewPost?.blog?.blogTypeInfo === BlogTypes.Logs;

  const defaultPrivacy = isLogPost
    ? POSTS_PRIVACY_LEVELS.ONLY_ME
    : POSTS_PRIVACY_LEVELS.PUBLIC;

  if (isLoading) return <PageLoader />;

  return (
    <Formik
      initialValues={
        postDetails
          ? {
              title: postDetails.title,
              text: postDetails.description,
              allowReshare: postDetails.allowReshare || false,
              file: null,
              privacyLevel: postDetails.privacyLevel || defaultPrivacy,
            }
          : initialValues
      }
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        handleSubmit: submitForm,
        setFieldValue,
        handleChange,
        handleBlur,
        touched,
      }) => {
        return (
          <Box sx={{ borderRadius: 2, p: { xs: 2, sm: 3 } }}>
            <Form className={s.form}>
              {/* input section */}
              <section className={s.section}>
                <Typography variant="h5" className={s.title}>
                  {isUpdate ? "Post Edit" : "Create a new post"}
                </Typography>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={5} md={4}>
                    {!preview && (
                      <DndUpload
                        height={300}
                        onChange={(file: File | null) => {
                          setFile(file);
                          setFieldValue("file", file);
                        }}
                      />
                    )}
                    {preview && (
                      <Box
                        className={s.imageWrapper}
                        sx={{ position: "relative" }}
                      >
                        {renderAdminButtons && renderAdminButtons()}
                        {!isUpdate && (
                          <div className={s.deleteBtn}>
                            <RiDeleteBin5Fill
                              size={25}
                              onClick={() => {
                                setFieldValue("file", null);
                                setPreview(null);
                              }}
                            />
                          </div>
                        )}
                        <img src={preview} alt="blog" className={s.image} />
                        {renderPostDelete && renderPostDelete()}
                      </Box>
                    )}
                    {preview && isUpdate && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                        }}
                      >
                        <FileUploader
                          onChange={(file: File | null) => {
                            if (file) {
                              const objectUrl = URL.createObjectURL(file);
                              setPreview(objectUrl);
                            }
                            setFieldValue("file", file);
                          }}
                          component={
                            <CustomButton
                              onClick={() => {}}
                              label="Update Image"
                            />
                          }
                        />
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={7} md={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <CustomInput
                          label={isUpdate ? "Title" : ""}
                          name="title"
                          placeholder="Title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors?.title}
                          touched={touched.title}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInput
                          label={isUpdate ? "Description" : ""}
                          name="text"
                          placeholder="Text"
                          inputType="textarea"
                          value={values.text}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors?.text}
                          touched={touched.text}
                        />
                        {/* <TextEditor /> */}
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ mt: { xs: 4, sm: 0 } }}>
                          <Typography
                            sx={{
                              mb: 1,
                              textAlign: "left",
                            }}
                            color="text.primary"
                          >
                            Select the privacy level
                          </Typography>
                          <Grid
                            container
                            spacing={{ xs: 1, sm: 2 }}
                            alignItems={"flex-start"}
                            justifyContent={"flex-start"}
                          >
                            {isLogPost || isOnlyMe ? (
                              <Grid item>
                                <TagButton
                                  selected={
                                    POSTS_PRIVACY_LEVELS.ONLY_ME ===
                                    values.privacyLevel
                                  }
                                  name={"ONLY ME"}
                                  onClick={() => {
                                    setFieldValue(
                                      "privacyLevel",
                                      POSTS_PRIVACY_LEVELS.ONLY_ME
                                    );
                                    setFieldValue("allowReshare", false);
                                  }}
                                />
                              </Grid>
                            ) : (
                              <>
                                {Object.entries(POSTS_PRIVACY_LEVELS).map(
                                  (p) => (
                                    <Grid item key={p[1]}>
                                      <TagButton
                                        selected={p[1] === values.privacyLevel}
                                        name={p[0].split("_").join(" ")}
                                        onClick={() => {
                                          if (
                                            POSTS_PRIVACY_LEVELS.PUBLIC === p[1]
                                          ) {
                                            setFieldValue("privacyLevel", p[1]);
                                            setFieldValue("allowReshare", true);
                                          } else {
                                            setFieldValue("privacyLevel", p[1]);
                                            setFieldValue(
                                              "allowReshare",
                                              false
                                            );
                                          }
                                        }}
                                      />
                                    </Grid>
                                  )
                                )}
                              </>
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                      {isPersonalBlogPost && (
                        <Grid item xs={12}>
                          <Checkbox
                            disabled={
                              values.privacyLevel !==
                              POSTS_PRIVACY_LEVELS.PUBLIC
                            }
                            checked={values.allowReshare}
                            onChange={() =>
                              setFieldValue(
                                "allowReshare",
                                !values.allowReshare
                              )
                            }
                          />
                          Allow Reshare
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* buttons sections */}
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        className={s.createBtn}
                        variant="outlined"
                        sx={{
                          // bgcolor: "text.primary",
                          mr: 2,
                          color: "text.secondary",
                        }}
                        onClick={() => {
                          handleCancel();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={s.createBtn}
                        sx={{
                          bgcolor: "text.primary",
                          color: "text.secondary",
                        }}
                        disabled={loading}
                        onClick={() => {
                          submitForm();
                        }}
                      >
                        {loading ? (
                          <CircularProgress
                            sx={{ color: "text.secondary" }}
                            size={30}
                          />
                        ) : isUpdate ? (
                          "Update"
                        ) : (
                          "Create"
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </section>
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
}

const POST_VIEW = gql(`query GetPostInfo($postId:Int!){
  viewPost(postId:$postId){
    id
    userId
    blogId
    title
    description
    privacyLevel
    hidden
    isShared
    shareText
    flagged
    highlighted{
      postId
    }
    originalPost{
      id
      userId
      blogId
      title
      description
      privacyLevel
      isShared
      media{
        id
        postId
        fileUrl
      }
    }
    allowReshare
    createdAt
    updatedAt
    sharedUserProfile{
      userName
      userId
      firstName
      lastName
      createdAt
    }
    profile{
      userName
      userId
      firstName
      lastName
      createdAt
    }
    blog{
      id
      userId
      blogType
      blogTypeInfo
    }
    media{
      id
      postId
      fileUrl
    }
  }
}`);
