import React, { useEffect } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import CustomButton from "../../components/CutomButton";
import useStyles from "./styles";
import BackgroundScreen from "../../components/BackGroundScreen";
import { gql } from "../../__generated__";
import PageLoader from "../../components/PageLoader";

export default function EmailVerifyPage() {
  const { classes } = useStyles();
  const params = useParams();
  const navigate = useNavigate();

  const [emailVerify, { loading }] = useMutation(EMAIL_VERIFICATION);

  useEffect(() => {
    if (params?.uuid) {
      emailVerify({ variables: { verificationCode: params.uuid } });
    }
  }, [params]);

  return (
    <BackgroundScreen>
      <Container
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box className={classes.root} sx={{ boxShadow: 3 }}>
          {loading ? (
            <PageLoader />
          ) : (
            <>
              <BsCheckCircle size={60} />
              <h2>Email Verified!</h2>
              <Typography sx={{ textAlign: "center" }}>
                Please login to complete your registration.
              </Typography>
              <p>Thank you.</p>
              <CustomButton onClick={() => navigate("/")} label="login" />
            </>
          )}
        </Box>
      </Container>
    </BackgroundScreen>
  );
}

const EMAIL_VERIFICATION =
  gql(`mutation EmailVerification($verificationCode:String!){
    emailVerification(verificationCode:$verificationCode){
      message
    }
  }`);
