import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  input: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.text.primary}`,
    color: theme.palette.text.primary,
    padding: "10px",
    "&:focus-visible": {
      outline: `1px solid ${theme.palette.text.primary}`,
    },
    "&::placeholder": {
      color: theme.palette.text.primary,
      opacity: 1,
    },
    background:
      theme.palette.mode === "dark"
        ? "#023047"
        : theme.palette.background.default,
  },
  error: {
    color: "red !important",
    padding: "0 2px",
  },
  label: { color: "#219EBC", fontSize: 14 },
}));

export default useStyles;
