import {
  Box,
  Button,
  Card,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { Scrollbar } from "../scrollbar";
import { Blog } from "../../__generated__/graphql";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useState } from "react";
import CardMenuWrapper from "../../components/MobsList/CardMenuWrapper";
import { BLOGS_MEMBER_JOINING_TYPE } from "../../utils/constants";
import ReactModal from "../../components/ReactModal";
import MobThemeWrapper from "../../components/MobThemeWrapper";
import { BsEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { ADMIN_PATH } from "../../utils/adminRoutes";

type Props = {
  items:
    | any[]
    | Pick<
        Blog,
        | "id"
        | "name"
        | "profile"
        | "createdAt"
        | "themeMediaInfo"
        | "membersJoiningType"
        | "privacyLevel"
      >[];

  refetchMobs: () => void;

  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (event: any) => void;
};

export const MobsTable = (props: Props) => {
  const {
    count = 0,
    items = [],
    onPageChange,
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    refetchMobs,
  } = props;

  const navigate = useNavigate();
  // states
  const [mobId, setMobId] = useState(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [allModals, toggleModals] = useState({
    editModal: false,
    memberModal: false,
    deleteModal: false,
    privacyModal: false,
    permissionModal: false,
    leaveModal: false,
    genarateLinkModal: false,
  });
  const [addedMembers, setAddedMembers] = useState<number[] | []>([]);
  const [showEditModal, setEditModal] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getPermission = () => {
    if (!mobId) return 0;
    const currentPermission = items.find(
      (x: any) => x.id === mobId
    )?.membersJoiningType;
    return currentPermission;
  };

  const checkByBeingAdded = () => {
    const currentPermission = getPermission();
    return currentPermission === BLOGS_MEMBER_JOINING_TYPE.BY_BEING_ADDED;
  };

  const getPrivacyLevel = () => {
    if (!mobId) return false;
    const currentPrivacy = items.find((x: any) => x.id === mobId)?.privacyLevel;
    return currentPrivacy;
  };

  const getUserId = () => {
    if (!mobId) return null;
    const userId = items.find((x: any) => x.id === mobId)?.profile.userId;
    return userId;
  };

  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item: any) => {
                const createdAt = moment(item.createdAt).format(
                  "YYYY/MM/DD hh:mm A"
                );

                const imageUrl = item.themeMediaInfo?.url || "";

                return (
                  <TableRow hover key={item.id}>
                    <TableCell>#{item.id}</TableCell>
                    <TableCell>
                      <Stack alignItems="center" direction="row" spacing={2}>
                        <img
                          src={imageUrl || ""}
                          style={{ height: "50px", borderRadius: "5px" }}
                          alt={item.name || ""}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{`${item.profile?.firstName} ${
                      item.profile?.lastName || ""
                    }`}</TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>{createdAt}</TableCell>

                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title={"View post list"}>
                          <Button
                            sx={{ mr: 1, minWidth: 24 }}
                            onClick={() =>
                              navigate(`${ADMIN_PATH}/mobs/posts/${item.id}`)
                            }
                            color="info"
                          >
                            <SvgIcon fontSize="small">
                              <BsEyeFill />
                            </SvgIcon>
                          </Button>
                        </Tooltip>
                        <Box
                          onClick={(e) => {
                            handleClick(e);
                            setMobId(item.id);
                          }}
                        >
                          <SvgIcon sx={{ cursor: "pointer" }}>
                            <SettingsOutlinedIcon />
                          </SvgIcon>
                        </Box>
                      </Box>
                      {/* <Box
                        sx={{ display: "flex", justifyContent: "space-around" }}
                      >
                        <Button
                          sx={{ mr: 1, minWidth: 24 }}
                          onClick={() => setEditId(item.id)}
                        >
                          <SvgIcon fontSize="small">
                            <BsPencilSquare />
                          </SvgIcon>
                        </Button>
                        <Button
                          sx={{ mr: 1, minWidth: 24 }}
                          onClick={() => setViewId(item.id)}
                          color="info"
                        >
                          <SvgIcon fontSize="small">
                            <BsEyeFill />
                          </SvgIcon>
                        </Button>
                        <Button
                          sx={{ mr: 1, minWidth: 24 }}
                          onClick={() => setDeleteId(item.id)}
                          color="error"
                        >
                          <SvgIcon fontSize="small">
                            <BsTrash />
                          </SvgIcon>
                        </Button>
                      </Box> */}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>

      <TablePagination
        component="div"
        count={count}
        onPageChange={(e, p) => onPageChange(p)}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20]}
      />

      {mobId && (
        <CardMenuWrapper
          id={mobId}
          userId={getUserId()}
          allModals={allModals}
          isCardMenuOpen={open}
          isMobCard
          isByBeingAdded={checkByBeingAdded()}
          refetchMobs={() => {
            refetchMobs();
          }}
          toggleModals={(key, value) =>
            toggleModals((s) => ({ ...s, [key]: value }))
          }
          closeCardMenu={() => handleClose()}
          anchorEl={anchorEl}
          currentPrivacy={getPrivacyLevel()}
          currentPermission={getPermission()}
          addedMembers={addedMembers}
          updateMemberList={(list) => setAddedMembers(list)}
        />
      )}
      {mobId && (
        <Box>
          <ReactModal
            title="Edit Mob"
            isOpen={showEditModal}
            handleClose={() => setEditModal(false)}
            modelWidth={{ xs: "90%", md: "60%", lg: "50%" }}
          >
            <MobThemeWrapper mobId={mobId} />
          </ReactModal>
        </Box>
      )}
    </Card>
  );
};
