import { useCallback } from "react";
import { Box, colors } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { BsImage } from "react-icons/bs";
import useStyles from "./styles";

interface DndUploadProps {
  onChange: (file: File | null) => void;
  height?: number;
  accept?: string;
  hasError?: boolean;
}

export default function DndUpload({
  onChange,
  height,
  accept,
  hasError = false,
}: DndUploadProps): JSX.Element {
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (Array.isArray(acceptedFiles)) {
        onChange(acceptedFiles[0]);
      }
    },
    [onChange]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const { classes: s } = useStyles();

  let styles: any = {};
  if (hasError) {
    styles.border = `2px dashed ${colors.red[400]} !important`;
  }

  return (
    <Box
      sx={{
        height: { xs: height || 120, md: (height || 200) * 0.65 },
        ...styles,
      }}
      className={s.root}
    >
      <Box {...getRootProps()}>
        <input {...getInputProps()} accept={accept || "*"} />
        {isDragActive ? (
          <p style={{ textAlign: "center" }}>Drop the files here ...</p>
        ) : (
          <Box className={s.wrapper}>
            <BsImage
              size={40}
              className={s.icon}
              color={hasError ? colors.red[300] : "inherit"}
            />
            <Box
              className={s.text}
              sx={hasError ? { color: colors.red[300] } : {}}
            >
              Click or Drag files to this area to upload
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
