import { Button, Grid, Typography, colors } from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useQuery } from "@apollo/client";
import ReactModal from "../ReactModal";
import CustomButton from "../CutomButton";
import ReactHooksInput from "../ReactHooksInput";
import PageLoader from "../PageLoader";
import { gql } from "../../__generated__";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import MemberCard from "./MemberCard";
import { useState } from "react";

type Props = {
  handleClose: () => void;
  onSave: (frieds: [] | number[]) => void;
  currentFriends: [] | number[];
  isUpdate?: boolean;
  mobId?: number;
  isLinkGenarate: boolean;
  userId: null | number;
};

export default function FriendSearch({
  handleClose,
  onSave,
  currentFriends,
  isUpdate,
  mobId,
  userId,
  isLinkGenarate,
}: Props) {
  const { data, loading, error, refetch } = useQuery(FRIENDS_LIST, {
    variables: {
      userId,
      searchInfo: {
        firstName: "",
        lastName: "",
        email: "",
      },
      pagination: {
        nextPage: 1,
        perPage: 50,
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [friendsList, setFriendList] = useState<number[] | []>(currentFriends);

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    mode: "all",
  });

  const schema = yup.object().shape({
    search: yup.string(),
    firstName: yup.string(),
    lastName: yup.string(),
  });

  type FormData = yup.InferType<typeof schema>;

  const searchUsers = (values: FormData) => {
    refetch({
      searchInfo: {
        email: values.search || "",
        firstName: values.firstName || "",
        lastName: values.lastName || "",
      },
    });
  };

  return (
    <div>
      <ReactModal
        isOpen={true}
        handleClose={() => handleClose()}
        modelWidth={{ xs: "95%", sm: "80%", lg: "60%" }}
      >
        {error && graphqlErrorToMsg(error)}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Memebers</Typography>
          </Grid>
          {/* search form */}
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(searchUsers)}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3}>
                  <ReactHooksInput
                    name="search"
                    placeholder="Email"
                    register={register}
                    error={errors.search?.message}
                    touched={touchedFields.search}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ReactHooksInput
                    name="firstName"
                    placeholder="First Name"
                    register={register}
                    error={errors.search?.message}
                    touched={touchedFields.search}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ReactHooksInput
                    name="lastName"
                    placeholder="Last Name"
                    register={register}
                    error={errors.search?.message}
                    touched={touchedFields.search}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Button
                    onClick={() => {}}
                    sx={{ borderRadius: 2, width: "100%" }}
                    variant="contained"
                    type="submit"
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          {loading && <PageLoader />}
          {!loading && data?.loggedUserFriendsSearch.data?.length === 0 && (
            <Grid item xs={12}>
              <Box
                sx={{
                  px: 3,
                  py: 6,
                  textAlign: "center",
                  background: colors.grey[200],
                  borderRadius: 1,
                }}
              >
                Friends not found
              </Box>
            </Grid>
          )}

          {!loading &&
            data?.loggedUserFriendsSearch.data?.map((friend) => (
              <Grid item xs={12} md={6} key={friend?.userId}>
                <MemberCard
                  isLinkGenarate={isLinkGenarate}
                  id={friend?.userId}
                  isUpdate={isUpdate}
                  mobId={mobId}
                  image={friend?.avatarImage}
                  currentFriends={friendsList}
                  username={`${friend?.firstName || ""} ${
                    friend?.lastName || ""
                  }`}
                  updateFriend={(friends) => {
                    setFriendList(friends);
                    if (mobId) {
                      onSave(friends);
                    }
                  }}
                />
              </Grid>
            ))}

          {/* <Grid
              item
              xs={12}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ display: "flex", mt: 2 }}
            >
              <CustomButton
                variant="text"
                onClick={() => {}}
                label="Load more"
                sx={{ border: "none", fontSize: 12 }}
              />
            </Grid> */}
          <Grid
            item
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ display: "flex", mt: 2 }}
            gap={4}
          >
            <CustomButton
              onClick={() => handleClose()}
              label="Cancel"
              variant="outlined"
            />
            {!isUpdate && !isLinkGenarate && (
              <CustomButton onClick={() => onSave(friendsList)} label="Save" />
            )}
          </Grid>
        </Grid>
      </ReactModal>
    </div>
  );
}

const FRIENDS_LIST = gql(`
  query LoggedUserFriendsSearch($pagination:Pagination,$searchInfo:loggedUserFriendsSearchInput!,$userId:Int){
        loggedUserFriendsSearch(pagination:$pagination,searchInfo:$searchInfo,userId:$userId){
            paginationDetails{
                currentPage
                lastPage
                totalRecords
            }
            data{
                userId
                email
                avatarImage
                firstName
                lastName
            }
        }
    }`);
