import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
    borderRadius: "12px",
    backgroundColor: "#219ebc26",
    boxShadow: "0px 1px 14px rgba(0, 0, 0, 0.1)",
  },
  wallpaper: {
    backgroundColor:
      theme.palette.mode === "dark"
        ? "#151515"
        : theme.palette.background.default,
  },
}));

export default useStyles;
