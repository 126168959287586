import { Avatar, Box, Card, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";

type Props = {
  id: number;
  imageUrl: string;
  title: string;
};

export default function MobsListViewCard({ id, imageUrl, title }: Props) {
  return (
    <Grid item xs={12} md={6} key={id}>
      <Card sx={{ padding: 1 }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "40px 1fr",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Avatar variant="rounded" src={imageUrl} alt="profile" />
          <Tooltip title={title}>
            <Typography
              textOverflow={"ellipsis"}
              sx={{ overflow: "hidden", textWrap: "noWrap" }}
            >
              {title}
            </Typography>
          </Tooltip>
        </Box>
      </Card>
    </Grid>
  );
}
