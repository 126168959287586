// import useStyles from "./styles";
// import CustomInput from "../CustomInput";
// import { Button, Modal } from "@mui/material";

interface Props {
  openGalleryCreateModel: boolean;
  handleCloseGalleryCreateModel: () => void;
  createdGalleries: [];
  setCreatedGalleries: (data: any) => void;
}

export default function GalleryCreateModel(props: Props): JSX.Element {
  // const {
  //   openGalleryCreateModel,
  //   handleCloseGalleryCreateModel,
  //   createdGalleries,
  //   setCreatedGalleries,
  // } = props;
  // const { classes } = useStyles();
  // const [albumName, setAlbumName] = useState<any>("");
  // const [albumCaption, setAlbumCaption] = useState<any>("");

  // function createAlbum(): void {
  //   // create album
  //   const data = {
  //     name: albumName,
  //     caption: albumCaption,
  //   };
  // }

  return <></>;

  // TODO
  // return (
  //   <Modal
  //     open={openGalleryCreateModel}
  //     onClose={() => {
  //       handleCloseGalleryCreateModel();
  //     }}
  //   >
  //     <Modal.Header>
  //       <Modal.Title style={{ fontSize: "20px", color: "#fff" }}>
  //         Create a New Album
  //       </Modal.Title>
  //     </Modal.Header>
  //     <Modal.Body>
  //       <h2 className={classes.subText}>Select Album Name</h2>
  //       {/* <CustomInput
  //         value={albumName}
  //         placeholder="Album Name"
  //         onChange={(value, event) => {
  //           setAlbumName(value);
  //         }}
  //       /> */}
  //       <h2 className={classes.subText}>Select Caption</h2>
  //       {/* <Input
  //         value={albumCaption}
  //         placeholder="Album Caption"
  //         onChange={(value, event) => {
  //           setAlbumCaption(value);
  //         }}
  //       /> */}
  //       <Button onClick={() => createAlbum()}>Create</Button>
  //     </Modal.Body>
  //   </Modal>
  // );
}
