import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => {
  const { mode } = theme.palette;

  return {
    links: {
      padding: "8px !important",
    },
    linkWrapper: {
      backgroundColor: mode === "dark" ? "#219ebc26" : "#219ebc66",
      borderRadius: 8,
      paddingLeft: 15,
      paddingRight: 15,
    },
  };
});

export default useStyles;
