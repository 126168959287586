import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import useStyles from "./styles";
import { Button, SvgIcon } from "@mui/material";
import { Close } from "@mui/icons-material";
// import CustomButton from "../CutomButton";

interface ReactModalProps {
  title?: string;
  isOpen: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  isLoading?: boolean;
  modelWidth?: any;
  buttonJustify?: string;
}

export default function ReactModal({
  title,
  isOpen,
  handleClose,
  children,
  modelWidth,
}: ReactModalProps) {
  const { classes: s } = useStyles();

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: "none" }}
      >
        <Box
          className={s.root}
          sx={{
            boxShadow: 3,
            width: modelWidth || { xs: "95%", sm: "80%", md: "60%" },
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ position: "absolute" }}>
              <Button
                onClick={handleClose}
                sx={{ p: 1, minWidth: "auto" }}
                className={s.closeBtn}
              >
                <SvgIcon>
                  <Close />
                </SvgIcon>
              </Button>
            </Box>
          </Box>
          {title && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {title}
              </Typography>
            </Box>
          )}
          <Box className={s.content}>{children}</Box>
          {/* <Box sx={{ py: 2, display: "flex", justifyContent: "flex-end" }}>
            <CustomButton
              label="Cancel"
              onClick={handleClose}
              variant="outlined"
            />
          </Box> */}
        </Box>
      </Modal>
    </div>
  );
}
