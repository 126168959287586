import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  formWrapper: {
    background: "rgb(255 255 255 / 70%)",
    padding: "15px 30px",
    borderRadius: "10px",
    color: "#023047",
    width: "320px",
  },
  title: {
    fontSize: "25px",
    textAlign: "center",
    fontWeight: 800,
    marginBottom: "30px",
    color: "#023047",
  },
  nextBtn: {
    textTransform: "none",
    width: "100%",
    color: "white !important",
    marginTop: "10px !important",
  },
  errorMsg: {
    color: "red",
    fontSize: "12px",
    padding: "10px 5px",
  },
  avatar: {
    height: "100px !important",
    width: "100px !important",
    borderRadius: "50%",
    backgroundSize: "cover",
    margin: "auto",
    marginBottom: 20,
  },
}));

export default useStyles;
