import React from "react";
import { Box, Grid } from "@mui/material";
import { Container } from "@mui/system";
import useStyles from "../../pages/ProfilePage/styles";
import { Account } from "../../__generated__/graphql";
import { ProfileComponentProps } from "../../types/commom";

interface WallpaperThemeProps {
  userDetails: Pick<Account, "profile">;
  blogDetails: any;
  profileComponent: (props?: ProfileComponentProps) => JSX.Element;
  mainComponent: () => JSX.Element;
  acitivityComponent: () => JSX.Element;
  tabComponent: () => JSX.Element;
}

export default function WallpaperTheme({
  blogDetails,
  profileComponent,
  mainComponent,
  acitivityComponent,
  tabComponent,
}: WallpaperThemeProps): JSX.Element {
  const { classes: s } = useStyles();
  const coverImage = blogDetails?.themeMediaInfo?.url;

  const title = blogDetails.name;

  if (!blogDetails) return <div>Blog not found</div>;

  return (
    <div className={s.root}>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={12}>
          <div
            className={s.wrapper}
            style={{
              backgroundImage: `url(${coverImage})`,
              height: "100vh",
              maxHeight: "100vh",
            }}
          >
            <Box className={coverImage ? s.overlay : ""}>
              {title && (
                <Box
                  className={s.title}
                  style={{
                    position: "relative",
                    marginTop: "25px",
                  }}
                >
                  {title}
                </Box>
              )}
              <Container maxWidth="xl">
                <Grid
                  container
                  spacing={2}
                  justifyContent={"center"}
                  mt={5}
                  sx={{ display: { sm: "flex", md: "none" } }}
                >
                  <Grid item xs={12}>
                    {profileComponent({ center: true })}
                  </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent={"center"} mt={5}>
                  <Grid item xs={10} md={7} lg={6} justifyContent={"center"}>
                    <Box>{tabComponent()}</Box>
                  </Grid>
                </Grid>
              </Container>
              <Container sx={{ pt: 5, maxWidth: "95% !important" }}>
                <Grid container spacing={{ xs: 2, sm: 4 }}>
                  <Grid
                    item
                    md={3}
                    sx={{
                      display: {
                        xs: "none",
                        md: "flex",
                      },
                    }}
                  >
                    <Box sx={{ width: "100%" }}>{profileComponent()}</Box>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    {mainComponent()}
                  </Grid>
                  <Grid
                    item
                    sm={3}
                    sx={{ display: { xs: "none", md: "flex" }, width: "100%" }}
                  >
                    {acitivityComponent()}
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
