import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { gql } from "../../__generated__";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import PageLoader from "../../components/PageLoader";
import PostView from "../../components/PostView";
import AdminBreadcrumbs from "../AdminBreadcrumbs/AdminBreadcrumbs";

type PostViewProps = {
  postViewId: number;
  goBack: () => void;
};
export default function AdminPostView({ postViewId, goBack }: PostViewProps) {
  const { data, loading, refetch } = useQuery(POST_VIEW, {
    variables: { postId: postViewId },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  if (loading) return <PageLoader />;
  if (!data?.viewPost) return <div>Post not found</div>;

  const breadcrumbs = [
    <Typography
      key="2"
      color="inherit"
      onClick={goBack}
      sx={{ cursor: "pointer" }}
    >
      Posts
    </Typography>,
    <Typography key="3" color="text.primary">
      View
    </Typography>,
  ];

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <AdminBreadcrumbs breadcrumbs={breadcrumbs} />
            </div>
            <div>
              <Typography variant="h4">Post View</Typography>
            </div>
            <div>
              <Grid container spacing={{ xs: 1, sm: 3 }}>
                <Grid xs={12}>
                  <PostView data={data} refetchPost={refetch} />
                </Grid>
              </Grid>
            </div>

            {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained">Go Back</Button>
            </Box> */}
          </Stack>
        </Container>
      </Box>
    </>
  );
}

const POST_VIEW = gql(`query ViewPost($postId:Int!){
    viewPost(postId:$postId){
      id
      userId
      blogId
      title
      description
      privacyLevel
      hidden
      isShared
      shareText
      flagged
      profile{
        userName
        userId
        firstName
        lastName
        createdAt
      }
      highlighted{
        postId
      }
      originalPost{
        id
        userId
        blogId
        title
        description
        privacyLevel
        isShared
        blog{
          id
          name
        }
        media{
          id
          postId
          fileUrl
        }
      }
      allowReshare
      createdAt
      updatedAt
      sharedUserProfile{
        userName
        userId
        firstName
        lastName
        createdAt
      }
      profile{
        userName
        userId
        firstName
        lastName
        createdAt
      }
      blog{
        id
        userId
        blogType
        blogTypeInfo
        name
        description
        title
        theme
        privacyLevel
        default
        friendsCount
        followingCount
        followersCount
        profile{
          userName
          userId
          firstName
          lastName
          createdAt
        }
        privacyLevelInfo{
          id
          name
        }
        themeInfo{
          id
          name
        }
        themeMediaInfo{
          blogId
          themeId
          url
        }
        media{
          id
          blogId
          mediaType
          fileUrl
          status
        }
        createdAt
        updatedAt
      }
      media{
        id
        postId
        fileUrl
      }
      comments{
        id
        postId
        blogId
        userId
        comment
        profile{
          userId
          firstName
          lastName
          createdAt
        }
        postInfo{
          id
          userId
          blogId
          title
          description
          privacyLevel
          isShared
        }
      }
      likes{
        postId
        blogId
        profile{
          userId
          firstName
          lastName
          createdAt
        }
      }
      shares{
        id
        blogId
        profile{
          userId
          firstName
          lastName
          createdAt
        }
      }
    }
  }`);
