import { Post } from "./../__generated__/graphql";
import { POSTS_PRIVACY_LEVELS } from "./constants";
export function getPostTitle(
  title: string,
  isShared: boolean,
  shareText?: string | null
) {
  if (isShared) return shareText;
  return title;
}

export function getPostImage(
  postInfo: Pick<Post, "originalPost" | "media" | "isShared">
): string {
  if (postInfo.isShared) {
    return postInfo.originalPost?.media?.[0]?.fileUrl || "";
  }
  return postInfo?.media?.[0]?.fileUrl || "";
}

type PostEditTypes = Pick<
  Post,
  | "title"
  | "description"
  | "allowReshare"
  | "originalPost"
  | "media"
  | "isShared"
  | "privacyLevel"
>;

export function getPostEditDetails(postData?: PostEditTypes | null) {
  if (!postData) {
    return {
      title: "",
      description: "",
      allowReshare: false,
      image: "",
      privacyLevel: POSTS_PRIVACY_LEVELS.PUBLIC,
    };
  }
  return {
    title: postData.title || "",
    description: postData.description || "",
    allowReshare: postData.allowReshare || false,
    image: getPostImage({
      originalPost: postData.originalPost,
      media: postData.media,
      isShared: postData.isShared,
    }),
    privacyLevel: postData.privacyLevel || POSTS_PRIVACY_LEVELS.PUBLIC,
  };
}

export function postEditStateToApi({
  states,
  blogId,
  postId,
}: PostEditStateToApiTypes) {
  return {
    variables: {
      info: {
        blogId,
        postId,
        title: states.title,
        description: states.text,
        allowReshare: states.allowReshare,
        hidden: false,
        privacyLevel: states.privacyLevel,
      },
      file: states.file,
    },
  };
}

export type PostFormTypes = {
  title: string;
  text: string;
  file: File | null;
  allowReshare: boolean;
  privacyLevel: number;
};
type PostEditStateToApiTypes = {
  states: PostFormTypes;
  blogId: number;
  postId: number;
};
