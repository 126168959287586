import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { BlogPrivacy } from "../../__generated__/graphql";
import { Box, Grid } from "@mui/material";
import CustomCheckBox from "../CustomCheckBox";
import ReactModal from "../ReactModal";
import CustomButton from "../CutomButton";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { BLOGS_PRIVACY_LEVEL } from "../../utils/constants";

type Props = {
  currentPrivacy: number;
  isOpen: boolean;
  handleClose: () => void;
  mobId: number;
  refetchMobs: () => void;
};

const schema = yup
  .object()
  .shape({
    privacy: yup.number().required(),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export default function PrivacyModal({
  currentPrivacy,
  isOpen,
  handleClose,
  mobId,
  refetchMobs,
}: Props) {
  const { control, handleSubmit, setValue, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      privacy: currentPrivacy,
    },
    mode: "all",
  });

  const [changePrivacy, { loading }] = useMutation(CHANGE_PRIVACY, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: () => {
      refetchMobs();
      toast.success("Privacy level updated successfully");
    },
  });

  const onSubmit = (values: FormData) => {
    let privacyLevel = BlogPrivacy.Public;
    if (values.privacy === BLOGS_PRIVACY_LEVEL.PRIVATE) {
      privacyLevel = BlogPrivacy.Private;
    }

    changePrivacy({
      variables: { blogId: mobId, privacyLevel },
    });
  };

  return (
    <ReactModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={"Privacy Settings"}
      modelWidth={{ sm: "75%", lg: "600px" }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            {/* <Typography> Privacy</Typography> */}
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
              <CustomCheckBox
                size="medium"
                control={control}
                name="privacy"
                checked={watch("privacy") === BLOGS_PRIVACY_LEVEL.PUBLIC}
                onChange={() => {
                  setValue("privacy", BLOGS_PRIVACY_LEVEL.PUBLIC);
                }}
                label={"Public"}
              />
              <CustomCheckBox
                size="medium"
                control={control}
                name="privacy"
                checked={watch("privacy") === BLOGS_PRIVACY_LEVEL.PRIVATE}
                onChange={() => {
                  setValue("privacy", BLOGS_PRIVACY_LEVEL.PRIVATE);
                }}
                label={"Private"}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
          gap={2}
        >
          <CustomButton
            label={"Cancel"}
            variant="outlined"
            onClick={() => handleClose()}
          />
          <CustomButton
            label={"Update"}
            type="submit"
            onClick={() => {}}
            loading={loading}
          />
        </Grid>
      </form>
    </ReactModal>
  );
}

const CHANGE_PRIVACY =
  gql(`mutation ChangeBlogPrivacy($blogId:Int!,$privacyLevel:BlogPrivacy!){
    changeBlogPrivacy(blogId:$blogId,privacyLevel:$privacyLevel){
      message
    }
  }`);
