import { Box } from "@mui/material";
import React from "react";

import useStyles from "./styles";

interface CustomLinkPorps {
  label: string;
  isActive?: boolean;
  onClick: () => void;
}

export default function CustomLink({
  isActive,
  label,
  onClick,
}: CustomLinkPorps): JSX.Element {
  const { classes: s } = useStyles();

  const cn = [s.root];
  if (isActive) {
    cn.push(s.active);
  }
  return (
    <Box className={cn.join(" ")} onClick={onClick}>
      {label}
    </Box>
  );
}

CustomLink.defaultProps = {
  isActive: false,
};
