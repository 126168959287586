import React, { useState } from "react";
import { Box, Button, Checkbox, CircularProgress, Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

import useStyles from "./styles";
import FileUploader from "../../../../components/FileUploader";
import CustomInput from "../../../../components/CustomInput";
import { THEMES_LIST } from "../../../../utils/constants";

type UserMoreDetails = {
  firstName: string;
  lastName: string;
  dob: Date | null;
  blogName: string;
  image: File | null;
  blogImage: null | File;
  theme: number;
};

type SubmitData = {
  blogName: string;
  theme: number;
  blogImage: null | File;
};

interface Props {
  back: ({ blogName, theme, blogImage }: SubmitData) => void;
  submitData: ({ blogName, theme, blogImage }: SubmitData) => void;
  loading: boolean;
  error: string | null;
  userMoreDetails: UserMoreDetails;
}

const validationSchema = Yup.object().shape({
  blogName: Yup.string().required().label("Blog Name"),
});

export default function FormTwo(props: Props): JSX.Element {
  const { back, submitData, loading, error, userMoreDetails } = props;
  const { classes: s } = useStyles();

  const [preview, setPreview] = useState<any>();
  const [imageFile, setImageFile] = useState<File | null>(
    userMoreDetails.blogImage || null
  );

  React.useEffect(() => {
    if (!imageFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(imageFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl);
  }, [imageFile]);

  return (
    <div className={s.root}>
      <Formik
        initialValues={{
          blogName: userMoreDetails.blogName || "",
          theme: userMoreDetails.theme,
        }}
        onSubmit={(values: any) =>
          submitData({
            blogName: values.blogName,
            blogImage: imageFile,
            theme: values.theme,
          })
        }
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          const {
            errors,
            touched,
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
          } = formikProps;

          return (
            <div>
              <p className={s.title}>Create your blog</p>

              <CustomInput
                label="Blog Name"
                name="blogName"
                placeholder="Blog Name"
                value={values.blogName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.blogName}
                touched={!!touched.blogName}
              />

              <Box sx={{ fontWeight: 500 }}>Choose Appearance</Box>

              <Grid container>
                <Grid item xs={12}>
                  <Checkbox
                    checked={values.theme === THEMES_LIST.WALLPAPER}
                    value={values.theme === THEMES_LIST.WALLPAPER}
                    onChange={() =>
                      setFieldValue("theme", THEMES_LIST.WALLPAPER)
                    }
                  />
                  Wallpaper
                </Grid>
                {/* <Grid item xs={12}>
                  <Checkbox
                    checked={values.theme === THEMES_LIST.MASONARY}
                    value={values.theme === THEMES_LIST.MASONARY}
                    onChange={() =>
                      setFieldValue("theme", THEMES_LIST.MASONARY)
                    }
                  />
                  Masonry
                </Grid> */}
                <Grid item xs={12}>
                  <Checkbox
                    checked={values.theme === THEMES_LIST.TIMELINE}
                    value={values.theme === THEMES_LIST.TIMELINE}
                    onChange={() =>
                      setFieldValue("theme", THEMES_LIST.TIMELINE)
                    }
                  />
                  Timeline
                </Grid>
              </Grid>

              {preview ? (
                <img className={s.imagePreview} src={preview} />
              ) : (
                <FileUploader
                  accept="image/png, image/jpeg"
                  component={
                    <Box className={s.uploadBox}>
                      {`Upload image for 
                      ${
                        values.theme === THEMES_LIST.WALLPAPER
                          ? "Wallpapaer"
                          : values.theme === THEMES_LIST.MASONARY
                          ? "Masonary"
                          : "Timeline"
                      }`}
                    </Box>
                  }
                  onChange={(file) => setImageFile(file)}
                />
              )}

              {error && <div className={s.errorMsg}>{error}</div>}
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={6} className={s.btnWrapper}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      back({
                        blogName: values.blogName,
                        blogImage: imageFile,
                        theme: values.theme,
                      });
                    }}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6} className={s.btnWrapper}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {loading ? (
                      <CircularProgress sx={{ color: "white" }} size={25} />
                    ) : (
                      "Finish"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}
