import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

type AdminBreadcrumbsProps = {
  breadcrumbs: React.ReactNode[];
};
export default function AdminBreadcrumbs({
  breadcrumbs,
}: AdminBreadcrumbsProps) {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
}
