import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  gridColumn: {
    paddingLeft: 15,
    backgroundClip: "padding-box",
  },
}));

export default useStyles;
