import React from "react";
import DatePicker from "react-datepicker";
import useStyles from "./styles";

import "react-datepicker/dist/react-datepicker.css";
import { Box, FormControl, FormHelperText } from "@mui/material";

interface CustomDatePickerProps {
  onChange: (date: Date) => void;
  startDate: Date | null;
  label?: string;
  error?: string | null;
  onBlur: (e: any) => void;
  touched: boolean | undefined;
  name: string;
  placeHolder?: string;
}

export default function CustomDatePicker({
  startDate,
  onChange,
  label,
  onBlur,
  touched,
  error,
  name,
  placeHolder,
}: CustomDatePickerProps): JSX.Element {
  const { classes: s } = useStyles();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", pb: 2 }}>
      {label && (
        <FormHelperText className={s.label} sx={{ mx: 0 }}>
          {label}
        </FormHelperText>
      )}
      <FormControl>
        <DatePicker
          name={name}
          placeholderText={placeHolder}
          maxDate={new Date()}
          selected={startDate || null}
          onChange={(date: Date) => onChange(date)}
          className={s.root}
          dateFormat="yyyy/MM/dd"
          showYearDropdown
          yearDropdownItemNumber={75}
          scrollableYearDropdown
          onBlur={onBlur}
        />
        {error && touched && (
          <FormHelperText className={s.error} sx={{ mx: 0 }}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
