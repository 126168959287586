import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      borderRadius: "20px",
      textTransform: "capitalize",
      border: `1px solid ${theme.palette.primary.main}`,
      "&:hover": {
        background: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.primary.main,
      },
    },
    disabled: {
      color: `${theme.palette.primary.light} !important`,
      cursor: "not-allowed",
      background: `${theme.palette.primary.main} !important`,
    },
  };
});

export default useStyles;
