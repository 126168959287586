import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: theme.palette.background.paper,
      padding: 32,
      paddingTop: 40,
      paddingBottom: 40,
      borderRadius: 8,
      maxHeight: "95%",
      overflowY: "auto",
      color: theme.palette.text.primary,
    },
    content: {
      maxHeight: "75vh",
      overflowY: "auto",
      color: theme.palette.text.primary,
    },
    closeBtn: {
      right: -32,
      top: -40,
    },
  };
});

export default useStyles;
