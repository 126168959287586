import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import Info from "../../../../components/Info";
import { gql } from "../../../../__generated__";
import { useQuery } from "@apollo/client";
import PostListCard from "../../../../components/PostList/postListCard";

interface Props {
  userId: number;
}
const PER_PAGE = 15;

export default function CommentPosts(props: Props): JSX.Element {
  const { userId } = props;
  const { classes } = useStyles();
  const [currentPage, setCurrentPage] = useState(1);

  const { data, loading, fetchMore } = useQuery(USER_COMMENTS, {
    variables: {
      userId,
      pagination: {
        perPage: PER_PAGE,
        nextPage: 1,
      },
    },
    fetchPolicy: "network-only",
  });

  const handleScroll = () => {
    const hasMore =
      (data?.commentedPostsByUserId?.paginationDetails?.lastPage || 1) >
      (data?.commentedPostsByUserId?.paginationDetails?.currentPage || 1);
    if (
      hasMore &&
      window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
    ) {
      // User has scrolled to the bottom
      fetchMore({
        variables: {
          pagination: {
            nextPage: (currentPage || 1) + 1,
            perPage: PER_PAGE,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          // const prevPaginate = prev.searchPost?.paginationDetails;

          const prevData = prev.commentedPostsByUserId?.data || [];
          const newData = fetchMoreResult.commentedPostsByUserId?.data || [];
          // update states
          setCurrentPage(currentPage + 1);
          return {
            commentedPostsByUserId: {
              ...fetchMoreResult.commentedPostsByUserId,
              data: [...prevData, ...newData],
            },
          };
        },
      });
    }
  };

  useEffect(() => {
    if (data) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const postList =
    data?.commentedPostsByUserId?.data.map((p) => p?.postInfo) || [];

  return (
    <div className={classes.mainContainer}>
      <div className="show-grid">
        {postList?.length === 0 && <Info message="No posts are available" />}

        {postList?.length !== 0 && (
          <PostListCard loading={loading} postList={postList} />
        )}
      </div>
    </div>
  );
}

const USER_COMMENTS = gql(`
query CommentedPostsByUserId($userId:Int!,$pagination:Pagination){
  commentedPostsByUserId(userId:$userId,pagination:$pagination){
    paginationDetails{
      currentPage
      lastPage
      totalRecords
    }
    data{
      postId
    postInfo {
      id
      userId
      blogId
      title
      description
      privacyLevel
      isShared
      shareText
      originalPost{
        id
        media{
          id
          fileUrl
        }
      }
      media {
        id
        postId
        fileUrl
      }
      comments {
        id
        postId
        blogId
        userId
        comment
      }
      likes {
        postId
        profile{
          userId
        }
        userId
      }
      profile {
        userId
        userName
        createdAt
      }
    }
    }
  }
}
`);
