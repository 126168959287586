import React, { useEffect, useState } from "react";
import { Box, SvgIcon, Tooltip, colors } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";

type Props = { uuid: string };

export default function CopyClipboard({ uuid }: Props) {
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [isCopied]);

  return (
    <Box
      sx={{
        display: "flex",
        px: 1,
        py: 0.75,
        fontSize: 10,
        alignItems: "center",
        width: "100%",
      }}
    >
      <CopyToClipboard text={uuid} onCopy={() => setCopied(true)}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              border: `1px solid ${colors.grey[300]}`,
              borderRadius: "3px 0 0 3px",
              flex: 1,
              alignItems: "center",
              px: 1,
            }}
          >
            {uuid}
          </Box>
          <Box
            sx={{
              background: colors.grey[200],
              cursor: "pointer",
              borderRadius: "0 3px 3px 0",
              display: "flex",
              alignItems: "center",
            }}
          >
            {isCopied ? (
              <Tooltip title={"Copied"}>
                <SvgIcon fontSize="small" sx={{ fontSize: 16 }}>
                  <ContentCopyTwoToneIcon />
                </SvgIcon>
              </Tooltip>
            ) : (
              <SvgIcon fontSize="small" sx={{ fontSize: 15 }}>
                <ContentCopyIcon />
              </SvgIcon>
            )}
          </Box>
        </Box>
      </CopyToClipboard>
    </Box>
  );
}
