import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "350px",
    flexDirection: "column",
    color: theme.palette.text.primary,
    padding: "40px 20px",
    borderRadius: 10,
    background: "white",
  },
}));

export default useStyles;
