import React from "react";
import { Post } from "../../__generated__/graphql";
import useStyles from "./styles";
import PageLoader from "../PageLoader";
import { Box } from "@mui/material";
import Info from "../Info";
import PostCard from "../PostCard";

interface PostListCardProps {
  postList: any[];
  loading: boolean;
}
export default function PostListCard({
  postList,
  loading,
}: PostListCardProps): JSX.Element {
  const { classes } = useStyles();

  if (loading) return <PageLoader />;

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {postList?.length === 0 && <Info message="No posts are available" />}

      {postList && postList?.length !== 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <div className={classes.cardWrapper}>
            {postList.map((item: Post | null) => {
              if (!item) return null;
              const imageUrl = !item.isShared
                ? item.media?.[0]?.fileUrl
                : item.originalPost?.media?.[0]?.fileUrl;
              return (
                <PostCard
                  key={item.id}
                  postData={item}
                  image={imageUrl || null}
                />
              );
            })}
          </div>
        </Box>
      )}
    </Box>
  );
}
