import { useQuery } from "@apollo/client";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import UsersCardList from "../ProfileComponents/ActivitySection/UsersCardList";
import { gql } from "../../__generated__";
import fetchMoreData, { checkHasMore, getList } from "../../utils/fetchMore";
import HighlightPosts from "../HighlightPosts";

interface Props {
  pageOwner: boolean;
  userId: number;
  blogId: number;
}

const PER_PAGE = 20;

export default function MobActivity(props: Props): JSX.Element {
  const { blogId } = props;

  const { data: membersList, fetchMore } = useQuery(MEMBERS_LIST, {
    variables: { blogId, pagination: { perPage: PER_PAGE, nextPage: 1 } },
    fetchPolicy: "network-only",
  });

  const {
    data: highlightList,
    loading: isLoading,
    fetchMore: fetchMorePost,
  } = useQuery(HIGHLIGHTS_LIST, {
    variables: { blogId, pagination: { perPage: PER_PAGE, nextPage: 1 } },
    fetchPolicy: "network-only",
  });

  const newHighlightList = highlightList?.highlightedPostsByBlogId?.data
    ?.filter((p) => p?.postId)
    .map((x) => x?.postInfo);

  const updatedMembersList = getList(
    membersList?.latestMembersOnBlog.data,
    "profile"
  );

  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Grid container justifyContent="center" maxWidth="md">
        <Grid item xs={12}>
          <HighlightPosts
            total={
              highlightList?.highlightedPostsByBlogId?.paginationDetails
                ?.totalRecords || 0
            }
            loading={isLoading}
            postList={newHighlightList || []}
            hasMore={checkHasMore(membersList, "latestMembersOnBlog")}
            fetchMore={() =>
              fetchMoreData({
                dataSet: highlightList,
                query: "highlightedPostsByBlogId",
                variables: {
                  blogId,
                },
                perPage: PER_PAGE,
                fetchMore: fetchMorePost,
              })
            }
          />
          <UsersCardList
            label={"Members"}
            list={updatedMembersList}
            message={"No members found"}
            hasMore={checkHasMore(membersList, "latestMembersOnBlog")}
            fetchMore={() =>
              fetchMoreData({
                dataSet: membersList,
                query: "latestMembersOnBlog",
                variables: {
                  blogId,
                },
                perPage: PER_PAGE,
                fetchMore,
              })
            }
            total={
              membersList?.latestMembersOnBlog.paginationDetails
                ?.totalRecords || 0
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

const MEMBERS_LIST = gql(`
query LatestMembersOnBlog($blogId:Int!,$pagination:Pagination){
	latestMembersOnBlog(blogId:$blogId,pagination:$pagination){
        paginationDetails{
          currentPage
          lastPage
          totalRecords
        }
        data{
          userId
          blogId
          roleId
          createdAt
          profile{
            userId
            firstName
            lastName
            gender
            dob
            avatarImage
            coverImage
            coverImageText
            email
            userName
            createdAt
            updatedAt
          }
        }
  }
}`);

const HIGHLIGHTS_LIST = gql(`
query HighlightedPostsByBlogId($blogId:Int!,$pagination:Pagination){
  highlightedPostsByBlogId(blogId:$blogId,pagination:$pagination){
    paginationDetails{
      currentPage
      lastPage
      totalRecords
    }
    data{
      postId
      userId
      blogId
      postInfo {
        id
        userId
        blogId
        title
        description
        privacyLevel
        media {
          id
          postId
          fileUrl
        }
        isShared
        originalPost{
          id
          userId
          blogId
          title
          description
          privacyLevel
          isShared
          media{
            id
            postId
            fileUrl
          }
        }
      }
    }
  }
}`);
