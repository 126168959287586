import {
  Box,
  Button,
  Card,
  Grid,
  SvgIcon,
  Typography,
  colors,
} from "@mui/material";
import useStyles from "./styles";
import CustomButton from "../CutomButton";
import { MdDateRange } from "react-icons/md";
import { gql } from "../../__generated__";
import { useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { MobsContext } from "../../Context";
import { BlogTypes } from "../../__generated__/graphql";
import LeaveModal from "../MobsList/LeaveModal";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import CardMenuWrapper from "../MobsList/CardMenuWrapper";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  BLOGS_MEMBER_JOINING_TYPE,
  BLOGS_PRIVACY_LEVEL,
} from "../../utils/constants";
import { Link } from "@mui/icons-material";
import CopyToClipboard from "react-copy-to-clipboard";

type Props = {
  title: string;
  createdAt: string;
  mobId: number;
  members: number[] | [];
  currentPrivacy: number;
  currentPermission: number;
};

export default function MobDetailsCard({
  title,
  createdAt,
  mobId,
  members,
  currentPrivacy,
  currentPermission,
}: Props) {
  const { classes: s } = useStyles();

  // states
  const [showLeaveModal, setLeaveModal] = useState(false);
  const [allModals, toggleModals] = useState({
    editModal: false,
    memberModal: false,
    deleteModal: false,
    privacyModal: false,
    permissionModal: false,
    leaveModal: false,
    genarateLinkModal: false,
  });
  const [addedMembers, setAddedMembers] = useState<number[] | []>(members);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isCopy, setCopy] = useState(false);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setCopy(false);
      }, 3000);
    }
  }, [isCopy]);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    isOwner,
    alreadyMember,
    blogType,
    blogPrivacyLevel,
    refetch: refetchPermission,
  } = useContext(MobsContext);

  const { data } = useQuery(MEMBER_COUNT, { variables: { blogId: mobId } });
  const [joinBlog, { loading: isLoading }] = useMutation(JOIN_MOB, {
    onCompleted: () => {
      toast.success("Join successfully");
      refetchPermission();
      window.location.reload();
    },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  const membersCount = data?.noOfMembersOnBlog?.memberCount || 0;

  const renderButton = () => {
    if (alreadyMember)
      return (
        <CustomButton
          label="Leave"
          variant="outlined"
          onClick={() => setLeaveModal(true)}
          sx={{
            border: `1px solid ${colors.red[600]} !important`,
            color: colors.red[600],
          }}
        />
      );
    if (!alreadyMember)
      return (
        <CustomButton
          label="Join Mob"
          loading={isLoading}
          onClick={() => {
            joinBlog({ variables: { blogId: mobId } });
          }}
        />
      );
    return null;
  };

  const showButton = !isOwner && blogType === BlogTypes.Mobs;
  const showCopyLink =
    (blogType === BlogTypes.Mobs || blogType === BlogTypes.Blogs) &&
    blogPrivacyLevel === BLOGS_PRIVACY_LEVEL.PUBLIC;
  return (
    <Card
      sx={{
        mb: 2,
        boxShadow: "0px 1px 14px rgba(0, 0, 0, 0.1)",
        maxWidth: { xs: "400px", md: "100%" },
        margin: "auto",
      }}
    >
      <Box className={s.root}>
        <Grid container>
          <Grid item xs={12} className={s.blogName}>
            <Box sx={{ display: "flex" }}>{title}</Box>
            {isOwner && (
              <Box onClick={handleClick}>
                <SvgIcon sx={{ cursor: "pointer" }}>
                  <SettingsOutlinedIcon />
                </SvgIcon>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            {isOwner ? "@Owner" : "@Member"}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", mt: 2 }}
            gap={1}
          >
            <MdDateRange size={20} /> Created {createdAt}
          </Grid>
          <Grid item xs={12} sx={{ my: 2, color: "text.secondary" }}>
            {membersCount > 0 && `${membersCount} Members`}
          </Grid>
          {showButton && (
            <Grid item xs={12} sx={{ mb: 1 }}>
              {renderButton()}
            </Grid>
          )}
          {showCopyLink && (
            <Grid item xs={12} md={8}>
              <CopyToClipboard
                text={window.location.href}
                onCopy={() => setCopy(true)}
              >
                <Button
                  variant="outlined"
                  sx={{ borderRadius: 5, px: 2, py: 0.5 }}
                >
                  <Link sx={{ mr: 0.5 }} />{" "}
                  <Typography fontSize={12}>
                    {isCopy ? "Copied" : "Copy Link"}
                  </Typography>
                </Button>
              </CopyToClipboard>
            </Grid>
          )}
        </Grid>
      </Box>

      <CardMenuWrapper
        id={mobId}
        allModals={allModals}
        isCardMenuOpen={open}
        isMobCard
        isByBeingAdded={
          currentPermission === BLOGS_MEMBER_JOINING_TYPE.BY_BEING_ADDED
        }
        refetchMobs={() => {}}
        toggleModals={(key, value) =>
          toggleModals((s) => ({ ...s, [key]: value }))
        }
        closeCardMenu={() => handleClose()}
        anchorEl={anchorEl}
        currentPrivacy={currentPrivacy}
        currentPermission={currentPermission}
        addedMembers={addedMembers}
        updateMemberList={(list) => setAddedMembers(list)}
      />
      <LeaveModal
        isOpen={showLeaveModal}
        handleClose={() => setLeaveModal(false)}
        mobId={mobId}
        refetchMobs={() => {
          setLeaveModal(false);
          refetchPermission();
        }}
      />
    </Card>
  );
}

const MEMBER_COUNT = gql(`query NoOfMembersOnBlog($blogId:Int!){
  noOfMembersOnBlog(blogId:$blogId){
    memberCount
  }
}`);

const JOIN_MOB = gql(`mutation JoinBlog($blogId:Int!){
  joinBlog(blogId:$blogId){
    message
  }
}`);
