import {
  Box,
  Container,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { ProfileImage } from "./profileImage";
import { ProfileDetails } from "./profileDetails";
import PageLoader from "../../components/PageLoader";
import { UserEditPageTypes } from "../../types/userTypes";
import AdminBreadcrumbs from "../AdminBreadcrumbs/AdminBreadcrumbs";

type UserEditPageProps = {
  handleClose?: () => void;
  refetchData: () => void;
  userDetails: UserEditPageTypes;
  loading: boolean;
  isProfilePage?: boolean;
};

const UserEditPage = ({
  handleClose,
  userDetails,
  refetchData,
  loading,
  isProfilePage,
}: UserEditPageProps) => {
  if (loading) return <PageLoader />;

  if (!userDetails) {
    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Typography variant="h4">
          Something went wrong with user details
        </Typography>
      </Box>
    );
  }

  const breadcrumbs = [
    <Typography
      key="2"
      color="inherit"
      onClick={handleClose}
      sx={{ cursor: "pointer" }}
    >
      Users
    </Typography>,
    <Typography key="3" color="text.primary">
      Edit
    </Typography>,
  ];

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <AdminBreadcrumbs breadcrumbs={breadcrumbs} />
            </div>
            <div>
              <Typography variant="h4">
                {isProfilePage ? `Profile Edit` : `User Edit`}
              </Typography>
            </div>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12} md={6} lg={4}>
                  <ProfileImage
                    userId={userDetails.userId}
                    image={userDetails.avatarImage}
                    name={userDetails.name}
                    refetch={refetchData}
                  />
                </Grid>
                <Grid xs={12} md={6} lg={8}>
                  <ProfileDetails
                    handleClose={handleClose}
                    userDetails={userDetails}
                    refetch={refetchData}
                    isProfilePage={isProfilePage}
                  />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default UserEditPage;
