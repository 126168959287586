import PropTypes from "prop-types";
import { Box, ButtonBase } from "@mui/material";
import { NavLink } from "react-router-dom";
import useStyles from "./styles";
import { lightTheme } from "../../utils/themes";

export const SideNavItem = (props: any) => {
  const { active = false, disabled, icon, path, title } = props;
  const { classes: s } = useStyles();

  return (
    <li>
      <NavLink
        to={path}
        style={{ textDecoration: "none" }}
        className={({ isActive }) => (isActive ? s.activeLink : s.link)}
      >
        <ButtonBase
          sx={{
            alignItems: "center",
            borderRadius: 1,
            display: "flex",
            justifyContent: "flex-start",
            color: "palette.primary.main",
            pl: "16px",
            pr: "16px",
            py: "6px",
            textAlign: "left",
            width: "100%",
            ...(active && {
              backgroundColor: "rgba(255, 255, 255, 0.04)",
            }),
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.04)",
            },
          }}
        >
          {icon && (
            <Box
              component="span"
              sx={{
                alignItems: "center",
                display: "inline-flex",
                justifyContent: "center",
                color: "neutral.400",
                mr: 2,
                ...(active && {
                  color: lightTheme.palette.secondary.main,
                }),
              }}
            >
              {icon}
            </Box>
          )}
          <Box
            component="span"
            sx={{
              color: "neutral.400",
              flexGrow: 1,
              fontFamily: (theme) => theme.typography.fontFamily,
              fontSize: 14,
              fontWeight: 600,
              lineHeight: "24px",
              whiteSpace: "nowrap",
              textDecoration: "none",
              ...(active && {
                color: "common.white",
              }),
              ...(disabled && {
                color: "neutral.500",
              }),
            }}
          >
            {title}
          </Box>
        </ButtonBase>
      </NavLink>
    </li>
  );
};

SideNavItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  icon: PropTypes.node,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};
