import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  box: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.04)",
    borderRadius: 4,
    justifyContent: "space-between",
    marginTop: 2,
    padding: "12px",
  },
  activeLink: {
    color: "primary.main",
    display: "flex",
    alignItems: "center",
  },
  link: {
    color: "#9DA4AE",
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;
