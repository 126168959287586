import React, { useState } from "react";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

import CustomModal from "../CustomModal";
import CustomInput from "../CustomInput";
import { gql } from "../../__generated__";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";

interface PostShareModalProps {
  postId: number;
  showModal: boolean;
  closeModel: () => void;
}

export default function PostShareModal({
  postId,
  showModal,
  closeModel,
}: PostShareModalProps) {
  const [text, setText] = useState("");

  const [sharePost, { loading, error }] = useMutation(SHARE_POST, {
    onCompleted: () => {
      closeModel();
      toast.success("Post shared successfully");
    },
    onError: () => {
      toast.error("Post share failed");
    },
  });

  return (
    <CustomModal
      isOpen={showModal}
      handleClose={() => closeModel()}
      title="Share to my blog"
      buttonLabel="Share"
      isLoading={loading}
      handleSubmit={() =>
        sharePost({
          variables: {
            info: {
              postId,
              shareText: text,
            },
          },
        })
      }
    >
      <CustomInput
        name="Share text"
        onChange={(e: any) => setText(e.target.value)}
        onBlur={() => {}}
        value={text}
        touched={false}
        label="Share Text"
      />
      {error && <Box sx={{ color: "red" }}>{graphqlErrorToMsg(error)}</Box>}
    </CustomModal>
  );
}

const SHARE_POST = gql(`mutation SharePost($info:PostShareInput){
  sharePost(info:$info){
    message
  }
}`);
