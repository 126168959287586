import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Avatar, Box, Button, FormHelperText, Grid } from "@mui/material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { gql } from "../../../__generated__";
import CustomButton from "../../CutomButton";
import FileUploader from "../../FileUploader";
import CustomInput from "../../CustomInput";
// import CustomSelect from "../../CustomSelect";
import CustomDatePicker from "../../CustomDatePicker";
import { Profile } from "../../../__generated__/graphql";
import useStyles from "./styles";
import "./styles.css";
import formatDate from "../../../utils/formatDate";
import { UserContext } from "../../../Context";
import graphqlErrorToMsg from "../../../utils/graphqlErrorToMsg";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().label("First name").min(4).max(255),
  lastName: Yup.string().label("Last name").min(4).max(255),
  dob: Yup.date().label("Date of Birth").nullable(),
});

interface ProfileItemsContainerProps {
  userDetails: Profile;
}

export default function ProfileItemsContainer({
  userDetails,
}: ProfileItemsContainerProps): JSX.Element {
  const { user, setUser } = useContext(UserContext);

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<any>(user?.avatarImage || null);

  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE, {
    onCompleted: (data) => {
      toast.success("Profile updated successfully");
      setUser({
        email: data.selfProfileEdit.email,
        userId: data.selfProfileEdit.userId,
        userName: data.selfProfileEdit.profile.userName || "",
        avatarImage: data.selfProfileEdit.profile.avatarImage,
        isCompleted: true,
      });
    },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  const [removeImage, { loading: isRemoving }] = useMutation(
    REMOVE_AVATAR_IMAGE,
    {
      onCompleted: (data) => {
        setImageFile(null);
        toast.success("Profile image removed successfully");
        setUser({
          email: data.removeAvatar.email,
          userId: data.removeAvatar.userId,
          userName: data.removeAvatar.profile.userName || "",
          avatarImage: null,
        });
      },
    }
  );

  React.useEffect(() => {
    if (!imageFile) {
      setPreview(null);
      return;
    }

    const objectUrl = URL.createObjectURL(imageFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl);
  }, [imageFile]);

  function changeUserData(values: any): void {
    let variables = {
      info: {
        firstName: values.firstName,
        lastName: values.lastName,
        dob: values.dob ? formatDate(values.dob) : null,
        mentionable: true,
        taggable: true,
      },
      image: imageFile,
    };
    updateProfile({ variables });
  }

  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={{ xs: 1, sm: 2 }}
        sx={{
          flexDirection: { xs: "column", md: "row", width: "100%" },
        }}
      >
        {/* profile image */}

        <Grid item xs={12}>
          <div className={classes.avatarContainer}>
            {preview ? (
              <Avatar className={classes.avatar} src={preview} />
            ) : (
              <FileUploader
                accept="image/png, image/jpeg"
                component={
                  <Avatar
                    className={classes.avatar}
                    src={String(userDetails.avatarImage)}
                  />
                }
                onChange={(file) => setImageFile(file)}
              />
            )}

            {preview && (
              <div style={{ textAlign: "center", margin: 5 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (user?.avatarImage) {
                      removeImage({ variables: { isCoverImage: false } });
                    }
                    setPreview(null);
                  }}
                >
                  {isRemoving ? "Removing..." : "Remove Image"}
                </Button>
              </div>
            )}
            <h4 className={classes.name}>{`${userDetails?.firstName || ""} ${
              userDetails?.lastName || ""
            }`}</h4>
          </div>
        </Grid>

        {/* profile inputs */}
        <Grid item xs={12}>
          <Formik
            initialValues={{
              firstName: userDetails?.firstName
                ? (userDetails?.firstName as string)
                : "",
              lastName: userDetails?.lastName
                ? (userDetails?.lastName as string)
                : "",
              dob: userDetails?.dob ? new Date(userDetails?.dob) : "",
            }}
            onSubmit={(values) => {
              changeUserData(values);
            }}
            validationSchema={validationSchema}
          >
            {(formikProps) => {
              const {
                errors,
                touched,
                values,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
              } = formikProps;
              return (
                <Form>
                  <Grid container spacing={{ xs: 0, sm: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <CustomInput
                        label="First Name"
                        name="firstName"
                        placeholder="First Name"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors?.firstName}
                        touched={touched.firstName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomInput
                        label="Last Name"
                        name="lastName"
                        placeholder="Last Name"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors?.lastName}
                        touched={touched.lastName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomInput
                        label="Username"
                        name="userName"
                        placeholder="Username"
                        value={user?.userName || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.lastName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomInput
                        label="Email"
                        name="email"
                        placeholder="Email"
                        value={user?.email || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={null}
                        touched={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormHelperText className={classes.label} sx={{ mx: 0 }}>
                        Date of Birth
                      </FormHelperText>
                      <CustomDatePicker
                        name="dob"
                        startDate={values.dob ? new Date(values.dob) : null}
                        onChange={(date) => {
                          setFieldValue("dob", date);
                        }}
                        onBlur={handleBlur}
                        error={errors?.dob}
                        touched={touched.dob}
                      />
                    </Grid>
                  </Grid>

                  <Box className={classes.btnWrapper}>
                    <CustomButton
                      label={loading ? "Updating..." : "Update Profile"}
                      onClick={() => handleSubmit()}
                    />
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
}

const UPDATE_PROFILE =
  gql(`mutation SelfProfileEdit($info: SelfProfileEditInput!,$image:Upload) {
  selfProfileEdit(info: $info,image:$image) {
    email
    userId
    profile {
      userName
      avatarImage
      createdAt
      dob
      firstName
      lastName
      updatedAt
      userId
    }
  }
}`);

const REMOVE_AVATAR_IMAGE = gql(`
mutation RemoveAvatar($isCoverImage:Boolean!){
  removeAvatar(isCoverImage:$isCoverImage){
    userId
    email
    profile{
      userName
      avatarImage
    }
  }
}`);
