import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    fontSize: "16px",
    cursor: "pointer",
    textTransform: "capitalize",
    "&:hover": {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.text.secondary
          : theme.palette.secondary.main,
    },
  },
  active: {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.text.secondary
        : theme.palette.secondary.main,
  },
}));

export default useStyles;
