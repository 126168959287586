import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserContext } from "../Context";
import ROUTES from "./routes";

export default function ProtectedRoute() {
  const { user } = useContext(UserContext);
  const location = useLocation();

  const token = localStorage.getItem("accessToken");
  if (token && user?.isCompleted) return <Outlet />;
  if (token)
    return (
      <Navigate
        to={{
          pathname: ROUTES.COMPLETE_SIGN_IN,
        }}
      />
    );
  localStorage.clear();
  return (
    <Navigate
      to={{
        pathname: "/",
        search: `?from=${encodeURIComponent(location.pathname)}`,
      }}
    />
  );
}
