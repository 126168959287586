import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 5,
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    minHeight: "97vh",
    paddingBottom: 20,
  },
  addLink: {
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  image: {
    maxWidth: "100%",
    borderRadius: 10,
  },
}));

export default useStyles;
