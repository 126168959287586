import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../Context";

export default function ProtectedAdminRoutes() {
  const token = localStorage.getItem("accessToken");

  const { user } = useContext(UserContext);
  if (token && user?.isAdmin) return <Outlet />;
  if (user) return <Navigate to="/admin/dashboard" />;

  localStorage.clear();
  return <Navigate to="/" />;
}

export function ProtectedModeratorRoutes() {
  const token = localStorage.getItem("accessToken");

  const { user } = useContext(UserContext);
  if ((token && user?.isModerator) || user?.isAdmin) return <Outlet />;
  return <Navigate to="/" />;
}
