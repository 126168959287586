import { useContext } from "react";
import { Box, Card, Grid } from "@mui/material";
import { MdDateRange } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CustomButton from "../CutomButton";

import useStyles from "./ProfileDetailsStyles";
import { Blog, Profile } from "../../__generated__/graphql";
import { gql } from "../../__generated__";
import { useMutation, useQuery } from "@apollo/client";
import { UserContext } from "../../Context";

interface ProfileDetailsProps {
  blogDetails: Pick<Blog, "name" | "about" | "id">;
  joinedDate: string;
  userDetails: Profile;
  isCenter?: boolean;
}

export default function ProfileDetails({
  blogDetails,
  joinedDate,
  userDetails,
  isCenter,
}: ProfileDetailsProps): JSX.Element {
  const { classes: s } = useStyles();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const pageOwner = userContext?.user?.userId === userDetails.userId;

  const { data, refetch } = useQuery(CHECK_FRIEND_OR_FOLLOW, {
    variables: { userId: userDetails.userId },
  });
  const { data: userData, refetch: refetchUserData } = useQuery(USER_PROFILE, {
    variables: { userId: userDetails.userId },
  });

  const [followBlog, { loading }] = useMutation(FOLLOW_BLOG, {
    onCompleted: () => {
      refetch();
      refetchUserData();
    },
  });

  const justify = isCenter ? "flex-start" : "center";

  const isFriend = data?.checkFriendOrFollower.isFriend;
  const isFollower = data?.checkFriendOrFollower.isFollower;

  const blog = userData?.profileByUserId.blogs?.[0];

  return (
    <Card sx={{ mb: 2, boxShadow: "0px 1px 14px rgba(0, 0, 0, 0.1)" }}>
      <Box className={s.root} sx={{ alignItems: justify }}>
        <Grid
          container
          justifyContent={justify}
          spacing={1}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            className={s.blogName}
            sx={{ textAlign: isCenter ? "left" : "center" }}
          >
            <div
              onClick={() => navigate(`/user/${userDetails.userId}`)}
              style={{ cursor: "pointer" }}
            >
              {blogDetails.name}
            </div>
          </Grid>
          {blogDetails.about && (
            <Grid
              item
              xs={12}
              className={s.userName}
              sx={{ textAlign: isCenter ? "left" : "center" }}
            >
              {blogDetails.about}
            </Grid>
          )}
          {userDetails.userName && (
            <Grid
              item
              xs={12}
              className={s.userName}
              sx={{ textAlign: isCenter ? "left" : "center" }}
            >{`@${userDetails.userName}`}</Grid>
          )}
        </Grid>
        {/* <Grid
          container
          justifyContent={justify}
          spacing={1}
          alignItems="center"
        >
          <Grid item>
            <MdLocationPin size={20} />
          </Grid>
          <Grid item>
            <div className={s.locationPlace}>Global</div>
          </Grid>
        </Grid> */}
        <Grid
          container
          justifyContent={justify}
          spacing={1}
          alignItems="center"
        >
          <Grid item>
            <MdDateRange size={20} />
          </Grid>
          <Grid item sx={{ my: 1 }}>
            <div className={s.locationPlace}>{joinedDate}</div>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={s.activity}>
          <Grid item className={s.counts} xs={isCenter ? 12 : 4}>
            {`${blog?.followingCount || 0} Following`}
          </Grid>
          <Grid item className={s.counts} xs={isCenter ? 12 : 4}>
            {`${blog?.friendsCount || 0} Friends`}
          </Grid>
          <Grid item className={s.counts} xs={isCenter ? 12 : 4}>
            {`${blog?.followersCount || 0} Followers`}
          </Grid>
        </Grid>
        <Box>
          {pageOwner && (
            <CustomButton
              label="Settings"
              className={s.btn}
              onClick={() => navigate("/settings")}
            />
          )}

          {!pageOwner && (
            <CustomButton
              className={s.btn}
              onClick={() => {
                if (blogDetails.id) {
                  followBlog({ variables: { blogId: blogDetails.id } });
                }
              }}
              disabled={isFollower || isFriend}
              label={
                loading
                  ? "loading..."
                  : isFriend
                  ? "Friend"
                  : isFollower
                  ? "Following"
                  : "Follow"
              }
            />
          )}
        </Box>

        {/* {data?.checkFriendOrFollower.isFriend && (
        <div>
          <p style={{ color: "green", fontWeight: "bold" }}>
            You both are following both of your pages !!!
          </p>
        </div>
      )}

      {data?.checkFriendOrFollower.isFollower && (
        <div>
          <p style={{ color: "green", fontWeight: "bold" }}>
            You are following this blog !!!
          </p>
        </div>
      )} */}
        {/* {buttonDetails === "followback" && (
        <div>
          <p
            style={{ color: "green", fontWeight: "bold" }}
          >{` ${userDetails.userName} follows you !!!`}</p>
        </div>
      )} */}
      </Box>
    </Card>
  );
}

const FOLLOW_BLOG = gql(`mutation FollowBlog($blogId:Int,$userId:Int){
  followBlog(blogId:$blogId,userId:$userId){
    message
  }
}`);

const CHECK_FRIEND_OR_FOLLOW = gql(`query CheckFriendOrFollower($userId:Int!){
  checkFriendOrFollower(userId:$userId){
    isFollower
    isFriend
  }
}`);

const USER_PROFILE = gql(`
  query ProfileDetailsByUserId($userId:Int!){
      profileByUserId(userId:$userId){
        userId
        email
        userName
        privacyLevel
        taggable
        mentionable
        createdAt
        profile{
          userId
          firstName
          lastName
          userName
          createdAt
          avatarImage
        }
        siteSettings{
          type
          value
        } 
        blogs{
          id
          followingCount
          friendsCount
          followersCount 
          createdAt
        } 
      }
    }
`);
