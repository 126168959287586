import { Control, Controller } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { FormHelperText } from "@mui/material";

import useStyles from "./styles";
import { Box } from "@mui/system";
import dayjs from "dayjs";

type DatePickerProps = {
  control: Control<any, any>;
  label: string;
  name: string;
};

export default function DatePickerInput({
  control,
  label,
  name,
}: DatePickerProps) {
  const { classes: s } = useStyles();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }} //optional
      render={({
        field: { onChange, value },
        fieldState: { invalid, isDirty }, //optional
        formState: { errors, touchedFields }, //optional, but necessary if you want to show an error message
      }) => (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  pb: 2,
                  width: "100%",
                }}
              >
                <DatePicker
                  label={label}
                  sx={{ width: "100%" }}
                  value={value ? dayjs(value) : null}
                  onChange={(newValue: any) => {
                    onChange(newValue);
                  }}
                  format={"YYYY/MM/DD"}
                  disableFuture
                />
                {errors[name]?.message && touchedFields[name] && (
                  <FormHelperText className={s.error} sx={{ mx: 0 }}>
                    {String(errors[name]?.message)}
                  </FormHelperText>
                )}
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </>
      )}
    />
  );
}
