import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import { gql } from "../../__generated__";
import PostListCard from "../PostList/postListCard";
import ReactModal from "../ReactModal";
import CreatePostPage from "../../pages/CreatePostPage";

type Props = {
  activeTabId: number;
  mobId: number;
  showModal: boolean;
  setModal: (isOpen: boolean) => void;
};

export default function MobPostList({
  activeTabId,
  mobId,
  showModal,
  setModal,
}: Props) {
  const [getMobsList, { data, loading, refetch }] = useLazyQuery(MOB_POSTS, {
    fetchPolicy: "network-only",
  });
  const [getMobsLikedList, { data: likedPosts, loading: isLikePostLoading }] =
    useLazyQuery(MOB_LIKES_POSTS);
  const [
    getMobsCommentedList,
    { data: commentedPosts, loading: isCommetedPostLoading },
  ] = useLazyQuery(MOB_COMMENTED_POSTS);

  useEffect(() => {
    if (activeTabId === 0) {
      getMobsList({
        variables: { blogId: mobId, pagination: { nextPage: 1, perPage: 20 } },
      });
    } else if (activeTabId === 1) {
      getMobsLikedList({
        variables: {
          blogId: mobId,
          typeId: 1,
          pagination: { nextPage: 1, perPage: 20 },
        },
      });
    } else if (activeTabId === 2) {
      getMobsCommentedList({
        variables: {
          blogId: mobId,
          pagination: { nextPage: 1, perPage: 20 },
        },
      });
    }
  }, [activeTabId, getMobsList, mobId, getMobsLikedList, getMobsCommentedList]);

  return (
    <Grid container>
      {activeTabId === 0 && (
        <PostListCard
          postList={data?.mobPostsByBlogId?.data || []}
          loading={loading}
        />
      )}
      {activeTabId === 1 && (
        <PostListCard
          postList={likedPosts?.mobPostsByUserActionAndBlogId?.data || []}
          loading={isLikePostLoading}
        />
      )}
      {activeTabId === 2 && (
        <PostListCard
          postList={
            commentedPosts?.commentedPostsByBlogId?.data.map(
              (p) => p?.postInfo
            ) || []
          }
          loading={isCommetedPostLoading}
        />
      )}
      {showModal && (
        <ReactModal
          handleClose={() => setModal(false)}
          isOpen={showModal}
          modelWidth={{ xs: "90%", md: "80%", lg: "60%" }}
        >
          <CreatePostPage
            blogId={mobId}
            onComplete={() => {
              setModal(false);
              refetch();
            }}
          />
        </ReactModal>
      )}
    </Grid>
  );
}

const MOB_POSTS =
  gql(`query MobPostsByBlogId($blogId:Int!,$pagination:Pagination){
    mobPostsByBlogId(blogId:$blogId,pagination:$pagination){
       paginationDetails{
        currentPage
        lastPage
        totalRecords
      }
      data{
        id
        userId
        blogId
        title
        description
        privacyLevel
        hidden
        allowReshare
        isShared
        shareText
        originalPost{
          id
          media{
            id
            fileUrl
          }
        }
        media{
          id
          postId
          fileUrl
        }
        likes{
          postId
          profile{
            userId
          }
          userId
        }
        shares{
          id
        }
        comments{
          id
          postId
          blogId
          userId
          comment
          postInfo{
            id
            userId
            blogId
            title
            description
            privacyLevel
          }
        }
        profile{
          userId
          firstName
          lastName
          avatarImage
          userName
          email
          createdAt
        }
        createdAt
        updatedAt
      }
      
    }
  }`);

const MOB_LIKES_POSTS =
  gql(`query MobPostsByUserActionAndBlogId($blogId:Int!,$typeId:Int!,$pagination:Pagination){
    mobPostsByUserActionAndBlogId(blogId:$blogId,typeId:$typeId,pagination:$pagination){
       paginationDetails{
        currentPage
        lastPage
        totalRecords
      }
      data{
        id
        userId
        blogId
        title
        description
        privacyLevel
        hidden
        allowReshare
        isShared
        shareText
        originalPost{
          id
          media{
            id
            fileUrl
          }
        }
        media{
          id
          postId
          fileUrl
        }
        likes{
          postId
          profile{
            userId
          }
          userId
        }
        shares{
          id
        }
        comments{
          id
          postId
          blogId
          userId
          comment
          postInfo{
            id
            userId
            blogId
            title
            description
            privacyLevel
          }
        }
        profile{
          userId
          firstName
          lastName
          avatarImage
          userName
          email
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }`);

const MOB_COMMENTED_POSTS =
  gql(`query CommentedPostsByBlogId($blogId:Int!,$pagination:Pagination){
    commentedPostsByBlogId(blogId:$blogId,pagination:$pagination){
        paginationDetails {
            currentPage
            lastPage
            totalRecords
          }
          data {
            postId
            postInfo {
              id
              userId
              blogId
              title
              description
              privacyLevel
              hidden
              allowReshare
              isShared
              shareText
              originalPost {
                id
                media {
                  id
                  fileUrl
                }
              }
              media {
                id
                postId
                fileUrl
              }
              likes {
                postId
                profile {
                  userId
                }
                userId
              }
              shares {
                id
              }
              comments {
                id
                postId
                blogId
                userId
                comment
                postInfo {
                  id
                  userId
                  blogId
                  title
                  description
                  privacyLevel
                }
              }
              profile {
                userId
                firstName
                lastName
                avatarImage
                userName
                email
                createdAt
              }
              createdAt
              updatedAt
            }
          }
    }
  }`);
