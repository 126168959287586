import React, { useState } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import useStyles from "./styles";
import CustomButton from "../../CutomButton";
import CustomInput from "../../CustomInput";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { gql } from "../../../__generated__";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { passwordValidation } from "../../../utils/validations";

const validationSchema = Yup.object().shape({
  currentPassword: passwordValidation("Current Password"),
  newPassword: passwordValidation("New Password"),
});

interface Props {
  userDetails: any;
}

export default function AccountItemsContainer(props: Props): JSX.Element {
  const { userDetails } = props;
  const { classes } = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const [changePassword, { loading }] = useMutation(CHNAGE_PASSWORD, {
    onCompleted: () => toast.success("Password chnaged successfully"),
  });

  return (
    <div>
      {userDetails?.type !== "SOCIAL" && (
        <>
          <div>
            <p className={classes.subHeading}>Change Password</p>
            <Formik
              initialValues={{
                currentPassword: "",
                newPassword: "",
              }}
              onSubmit={(values, { resetForm }) => {
                changePassword({ variables: { password: values.newPassword } });
                resetForm({
                  values: { currentPassword: "", newPassword: "" },
                });
              }}
              validationSchema={validationSchema}
            >
              {(formikProps) => {
                const {
                  errors,
                  touched,
                  values,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                } = formikProps;
                return (
                  <Form autoComplete="new-password">
                    <CustomInput
                      name="currentPassword"
                      inputType={showPassword ? "text" : "password"}
                      placeholder="Current Password"
                      value={values.currentPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors?.currentPassword}
                      touched={touched.currentPassword}
                    />
                    <CustomInput
                      name="newPassword"
                      inputType={showPassword ? "text" : "password"}
                      placeholder="New Password"
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors?.newPassword}
                      touched={touched.newPassword}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={showPassword}
                          onChange={() => {
                            setShowPassword((s) => !s);
                          }}
                        />
                      }
                      label="Show Passwords"
                    />
                    <Box sx={{ my: 2 }}>
                      <CustomButton
                        onClick={() => {
                          if (!loading) {
                            handleSubmit();
                          }
                        }}
                        label="Change Password"
                      />
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </>
      )}
      {/* <Box sx={{ mt: 6, pt: 4, py: 8 }}>
        <p className={classes.subHeading}>Delete Account</p>
        <p className={classes.normalText}>
          If you delete your account you cannot revert it back.
        </p>
        <CustomButton
          className={classes.warningBtn}
          label="Delete Account"
          onClick={() => console.log("delete account")}
        />
      </Box> */}
    </div>
  );
}

const CHNAGE_PASSWORD = gql(`
mutation ChangePassword($password:String!){
  changePassword(password:$password){
    userId
  }
}
`);
