import { Box, Grid } from "@mui/material";
import React from "react";
import CoverImage from "../../components/CoverImage";
import { Container } from "@mui/system";
import { Blog } from "../../__generated__/graphql";

type Props = {
  coverImage: string;
  mobData: Pick<Blog, "theme" | "userId" | "id" | "name">;
  renderMobCard: () => JSX.Element;
  renderMobList: () => JSX.Element;
  tabComponent: () => JSX.Element;
  renderMobActivity: () => JSX.Element;
  renderCreateBtn: () => JSX.Element;
};

export default function TimelineTheme({
  coverImage,
  mobData,
  renderMobCard,
  renderMobList,
  tabComponent,
  renderMobActivity,
  renderCreateBtn,
}: Props) {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CoverImage
            imageUrl={coverImage || null}
            themeId={mobData.theme}
            blogId={mobData.id}
            userId={mobData.userId}
            title={mobData.name || ""}
          />
        </Grid>
      </Grid>

      {/* cover theme area  end*/}

      <Container maxWidth="lg" sx={{ pt: 5 }}>
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12}>
            {tabComponent()}
          </Grid>
          {/* post list section */}
          <Grid item xs={12} sm={8}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center", mb: 3 }}
              >
                {renderCreateBtn()}
              </Grid>
              <Grid item xs={12}>
                {renderMobList()}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              flexDirection: "column",
            }}
          >
            <Box sx={{ mb: 5 }}>{renderMobCard()}</Box>
            <Box>{renderMobActivity()}</Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
