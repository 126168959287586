import React, { useState } from "react";
import IMAGE_1 from "../../Assets/Images/HomePage/1.jpg";
import IMAGE_2 from "../../Assets/Images/HomePage/2.jpg";
import IMAGE_3 from "../../Assets/Images/HomePage/3.jpg";
import IMAGE_4 from "../../Assets/Images/HomePage/4.jpg";
import IMAGE_5 from "../../Assets/Images/HomePage/5.jpg";
import IMAGE_6 from "../../Assets/Images/HomePage/6.jpg";
import IMAGE_7 from "../../Assets/Images/HomePage/7.jpg";
import IMAGE_8 from "../../Assets/Images/HomePage/8.jpg";
import { Box } from "@mui/material";

interface Props {
  // eslint-disable-next-line react/require-default-props
  children?: JSX.Element | JSX.Element[];
}

export default function BackgroundScreen(props: Props): JSX.Element {
  // Update this array when adding new Images
  const images = [
    IMAGE_1,
    IMAGE_2,
    IMAGE_3,
    IMAGE_4,
    IMAGE_5,
    IMAGE_6,
    IMAGE_7,
    IMAGE_8,
  ];
  const [imageIndex, setImageIndex] = useState(0);

  React.useEffect(() => {
    const image = Math.random() * (images.length - 0) + 0;
    setImageIndex(Math.floor(image));
  }, [images.length]);

  // eslint-disable-next-line react/destructuring-assignment
  return (
    <Box
      sx={{
        backgroundImage: `url(${images[imageIndex]})`,
        backgroundBlendMode: "overlay",
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
      }}
    >
      {props.children}
    </Box>
  );
}
