import React from "react";
import ReactModal from "../ReactModal";
import { Box, Button, Typography, colors } from "@mui/material";
import { WarningAmberOutlined } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { gql } from "../../__generated__";

type PostDeleteModalProps = {
  isOpen: boolean;
  id: number;
  handleClose: () => void;
  handleComplete: () => void;
  modalContent?: string;
  isMob?: boolean;
};

export default function PostDeleteModal({
  isOpen,
  id,
  modalContent,
  handleClose,
  handleComplete,
  isMob,
}: PostDeleteModalProps) {
  const [deletePost, { loading: isDeleting }] = useMutation(DELETE_POST, {
    onCompleted: () => {
      toast.success("Post deleted successfully");
      handleComplete();
    },
    onError: (error) => toast.error(graphqlErrorToMsg(error)),
  });

  const [deleteMob, { loading }] = useMutation(DELETE_MOB, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: () => {
      handleComplete();
      toast.success("Mob deleted successfully");
    },
  });

  return (
    <ReactModal isOpen={isOpen} handleClose={handleClose} modelWidth={400}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <SvgIcon fontSize="large"> */}
        <WarningAmberOutlined
          style={{ fontSize: 78, color: colors.red[400] }}
        />
        {/* </SvgIcon> */}
        <Typography textAlign={"center"} sx={{ my: 4 }} color={"primary"}>
          {modalContent ||
            `Your post will be deleted and cannot be retrieved again. Are you sure
          you want to delete your post?`}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "80%",
          }}
        >
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="outlined"
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (isMob) {
                deleteMob({ variables: { blogId: id } });
              } else {
                deletePost({ variables: { postId: id } });
              }
            }}
          >
            {isDeleting || loading ? "Deleting" : "Yes"}
          </Button>
        </Box>
      </Box>
    </ReactModal>
  );
}

const DELETE_POST = gql(`
  mutation deletePost($postId: Int!) {
    deletePost(postId: $postId) {
      id
    }
  }`);

const DELETE_MOB = gql(`mutation DeleteBlog($blogId:Int!){
    deleteBlog(blogId:$blogId){
      message
    }
  }`);
