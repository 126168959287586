import { useQuery } from "@apollo/client";
import { gql } from "../__generated__";
import { Me } from "../__generated__/graphql";
import UserEditPage from "../adminComponents/Users/account";

export default function AdminProfilePage() {
  const { data, loading, refetch } = useQuery(USER_PROFILE, {
    fetchPolicy: "network-only",
  });

  const getUserDetails = (
    user: Pick<Me, "userId" | "profile" | "email" | "roles">
  ) => {
    return {
      userId: user.userId,
      firstName: user.profile.firstName || "",
      lastName: user.profile.lastName || "",
      email: user.email,
      userName: user.profile.userName || "",
      dob: user.profile.dob || null,
      roles: user.roles,
      name: `${user.profile.firstName} ${user.profile.lastName || ""}`,
      avatarImage: user.profile.avatarImage || "",
    };
  };

  return (
    <UserEditPage
      refetchData={refetch}
      loading={loading}
      userDetails={data?.me ? getUserDetails(data.me) : null}
      isProfilePage
    />
  );
}

const USER_PROFILE = gql(`
  query Me{
      me{
        userId
        email
        roles
        profile{
          userName
          avatarImage
          createdAt
          dob
          firstName
          lastName
          updatedAt
          userId
        }
      }
    }
`);
