import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ChangeBlogMemberJoinType } from "../../__generated__/graphql";
import { Box, Grid } from "@mui/material";
import CustomCheckBox from "../CustomCheckBox";
import ReactModal from "../ReactModal";
import CustomButton from "../CutomButton";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { BLOGS_MEMBER_JOINING_TYPE } from "../../utils/constants";

type Props = {
  currentPermission: number;
  isOpen: boolean;
  handleClose: () => void;
  mobId: number;
  refetchMobs: () => void;
};

const schema = yup
  .object()
  .shape({
    permissions: yup.number().required(),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export default function PermissionModal({
  currentPermission,
  isOpen,
  handleClose,
  mobId,
  refetchMobs,
}: Props) {
  const { control, handleSubmit, setValue, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      permissions: currentPermission,
    },
    mode: "all",
  });

  const [changePermission, { loading }] = useMutation(CHANGE_PERMISSION, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: () => {
      refetchMobs();
      toast.success("Permission updated successfully");
    },
  });

  const onSubmit = (values: FormData) => {
    let joiningType = ChangeBlogMemberJoinType.Any;

    if (values.permissions === BLOGS_MEMBER_JOINING_TYPE.BY_BEING_ADDED) {
      joiningType = ChangeBlogMemberJoinType.ByBeingAdded;
    }

    changePermission({
      variables: {
        blogId: mobId,
        joiningType,
      },
    });
  };

  return (
    <ReactModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={"Permissions Settings"}
      modelWidth={{ sm: "75%", lg: "600px" }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            {/* <Typography> Member Permissions</Typography> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <CustomCheckBox
                control={control}
                size="medium"
                name="permissions"
                checked={
                  watch("permissions") ===
                  BLOGS_MEMBER_JOINING_TYPE.BY_BEING_ADDED
                }
                onChange={() =>
                  setValue(
                    "permissions",
                    BLOGS_MEMBER_JOINING_TYPE.BY_BEING_ADDED
                  )
                }
                label={"By being added"}
              />
              <CustomCheckBox
                control={control}
                size="medium"
                name="permissions"
                checked={watch("permissions") === BLOGS_MEMBER_JOINING_TYPE.ANY}
                onChange={() =>
                  setValue("permissions", BLOGS_MEMBER_JOINING_TYPE.ANY)
                }
                label={"Anyone"}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
            gap={2}
          >
            <CustomButton
              label={"Cancel"}
              variant="outlined"
              onClick={() => handleClose()}
            />
            <CustomButton
              label={"Update"}
              type="submit"
              onClick={() => {}}
              loading={loading}
            />
          </Grid>
        </Grid>
      </form>
    </ReactModal>
  );
}

const CHANGE_PERMISSION =
  gql(`mutation changeBlogMemberJoiningType($blogId:Int!,$joiningType:ChangeBlogMemberJoinType!){
    changeBlogMemberJoiningType(blogId:$blogId,joiningType:$joiningType){
      message
    }
  }`);
