import React, { useContext, useEffect, useState } from "react";
import useStyles from "./styles";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { UserContext } from "../../Context";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { USERACTIONS } from "../../utils/constants";

interface LikePostProps {
  likesCount: number;
  postAllLikes: any[];
  postId?: number;
  blogId?: number;
}
export default function LikePost({
  likesCount,
  postAllLikes,
  postId,
  blogId,
}: LikePostProps) {
  const [postLiked, setPostLiked] = useState(false);
  const [count, setCount] = useState(likesCount);
  const { classes: s } = useStyles();
  const { user } = useContext(UserContext);

  const [addPostUserAction] = useMutation(POST_LIKE, {
    onError: () => setPostLiked((s) => !s),
    onCompleted: () => {
      const newCount = postLiked ? count - 1 : count + 1;
      setCount(newCount);
      setPostLiked((s) => !s);
    },
  });

  useEffect(() => {
    const isAlreadyLiked = postAllLikes.some(
      (l) => l?.profile && l.profile.userId === user?.userId
    );
    if (isAlreadyLiked) {
      setPostLiked(true);
    }
  }, [postAllLikes, user]);

  const handleLikes = () => {
    if (blogId && postId) {
      addPostUserAction({
        variables: {
          info: {
            blogId,
            type: USERACTIONS.LIKED,
            postId,
          },
        },
      });
    }
  };

  return (
    <div className={s.root}>
      <span>{count || 0}</span>
      {!postLiked ? (
        <AiOutlineHeart
          size="18px"
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => handleLikes()}
        />
      ) : (
        <AiFillHeart
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => handleLikes()}
        />
      )}
    </div>
  );
}

const POST_LIKE = gql(`
    mutation AddPostUserAction($info:PostActionInput){
      addPostUserAction(info:$info){
        message
      }
    }
`);
