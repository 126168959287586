import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";

import useStyles from "./CommentsStyles";
import Comments from "../Comments";
import { gql } from "../../__generated__";
import CommentForm from "../Comments/CommentForm";

interface CommentsWarpperProps {
  postId: number;
  blogId?: number;
}

export default function CommentsWrapper({
  postId,
  blogId,
}: CommentsWarpperProps): JSX.Element {
  const { classes: s } = useStyles();

  const [addComment] = useMutation(ADD_COMMENT, {
    onCompleted: () => refetch(),
  });

  const { data, loading, refetch } = useQuery(POST_COMMENTS, {
    variables: {
      postId,
    },
  });

  function handleSubmit(value: string) {
    if (blogId) {
      addComment({
        variables: {
          info: {
            blogId: blogId,
            postId: postId,
            comment: value,
          },
        },
      });
    }
  }

  if (loading) return <CircularProgress />;

  return (
    <div className={s.root}>
      <h4 className={s.title}>Comments</h4>

      {blogId && <CommentForm submitComment={(value) => handleSubmit(value)} />}

      {data?.commentsByPostId?.map((comment) => (
        <Comments
          comment={comment}
          key={comment?.id}
          refetchComments={() => refetch()}
        />
      ))}
    </div>
  );
}

const POST_COMMENTS = gql(`
query CommentsByPostId($postId:Int!){
  commentsByPostId(postId:$postId){
    id
    postId
    blogId
    comment
    createdAt
    updatedAt
    profile{
      userId
      firstName
      lastName
      email
      userName
      avatarImage
      createdAt
    }
  }
}`);

const ADD_COMMENT = gql(`mutation AddPostComment($info:AddCommentInput!){
  addPostComment(info:$info){
    id
    postId
    blogId
    userId
    comment
  }
}`);
