import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    color: theme.palette.text.primary,
    backgroundColor: `${theme.palette.background.default}`,
    overflowY: "auto",
    minHeight: "100vh",
  },
  wrapper: {
    width: "100%",
    position: "relative",
    backgroundSize: "cover",
  },
  coverImage: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    backgroundSize: "cover",
  },
  title: {
    fontWeight: "bold",
    fontSize: 24,
    textAlign: "center",
    padding: "20px 0",
    color: theme.palette.text.primary,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(21, 21, 21, 0.5)"
        : "rgba(255, 255, 255, 0.5)",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    // textTransform: "capitalize",
  },
  overlay: {
    width: "100%",
    height: "100vh",
    backgroundColor: theme.palette.mode === "dark" ? "#00000066" : "#00000026",
    overflow: "auto",
  },
}));

export default useStyles;
