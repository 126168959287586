import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  userName: {
    fontWeight: 500,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    textTransform: "capitalize",
  },
  replyBox: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    fontSize: "12px",
    paddingTop: 4,
  },
  replyButton: {
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  removeButton: {
    color: "red",
    cursor: "pointer",
  },
  subCommentWrapper: {
    paddingLeft: 25,
  },
}));

export default useStyles;
