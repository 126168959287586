import React from "react";
import { Routes, Route } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { Box, Theme } from "@mui/material";

// pages
import LogInPage from "./pages/Login";
import SignUpPage from "./pages/SignUpPage";
import SignUpMoreDetails from "./pages/SignUpMoreDetails";
import CreatePostPage from "./pages/CreatePostPage";
import ErrorPage from "./pages/ErrorPage";
import ProtectedRoute from "./utils/protectedRoute";
import Dashboard from "./pages/Dashboard";
import SettingsPage from "./pages/SettingsPage";
import SinglePostPage from "./pages/SinglePostPage";
import ProfilePage from "./pages/ProfilePage";
import EmailVerifyPage from "./pages/EmailVerifyPage";
import ContactUsPage from "./pages/ContactUsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfServices from "./pages/TermsOfServices";
import ROUTES from "./utils/routes";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import MobsPage from "./pages/MobsPage";
import MobsJoiningPage from "./pages/MobsJoiningPage";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));
export default function AppRoutes() {
  const { classes } = useStyles();
  return (
    <Box className={classes.root}>
      <Routes>
        <Route path="/" element={<LogInPage />} />
        <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
        <Route path={ROUTES.ACCOUNT_VERIFY} element={<EmailVerifyPage />} />

        <Route path={ROUTES.COMPLETE_SIGN_IN} element={<SignUpMoreDetails />} />
        {/* authenticated routes */}
        <Route element={<ProtectedRoute />}>
          <Route path={ROUTES.CREATE_POST} element={<CreatePostPage />} />
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTES.SETTINGS} element={<SettingsPage />} />
          <Route path={ROUTES.POST_VIEW} element={<SinglePostPage />} />
          <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
          <Route path={ROUTES.MOBS} element={<MobsPage />} />
          <Route
            path={ROUTES.MOBS_JOINGING_PAGE}
            element={<MobsJoiningPage />}
          />
        </Route>
        <Route path={ROUTES.CONTACT_US} element={<ContactUsPage />} />
        <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
        <Route path={ROUTES.TERMS_OF_SERVICES} element={<TermsOfServices />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />

        {/* other routes redirect to 404 page */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Box>
  );
}
