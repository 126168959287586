import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { MobsTable } from "../adminComponents/Mobs/MobsTable";
import { gql } from "../__generated__";
import { useQuery } from "@apollo/client";
import { BlogTypes } from "../__generated__/graphql";
import PageLoader from "../components/PageLoader";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../utils/graphqlErrorToMsg";
import { MobsSearch } from "../adminComponents/Mobs/MobsSearch";

export default function AdminMobsPage() {
  // states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // feth data
  const { data, loading, refetch } = useQuery(MOBS_LIST, {
    variables: {
      searchInfo: {
        blogType: BlogTypes.Mobs,
      },
      pagination: {
        perPage: 20,
        nextPage: 1,
      },
    },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  // handle functions
  const handlePageChange = (value: number) => {
    setPage(value + 1);
    refetch({
      pagination: { nextPage: value + 1, perPage: rowsPerPage },
    });
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(event.target.value);
    refetch({
      pagination: { nextPage: 1, perPage: event.target.value },
    });
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">Moblogs List</Typography>
            </Stack>
          </Stack>
          <MobsSearch
            handleSearch={(search) =>
              refetch({
                searchInfo: {
                  blogType: search.blogType,
                  blogName: search.title,
                },
                pagination: {
                  nextPage: 1,
                  perPage: rowsPerPage,
                },
              })
            }
          />
          {loading && <PageLoader />}
          {data?.adminBlogsSearch?.data && (
            <MobsTable
              items={data?.adminBlogsSearch?.data.filter((x) => !!x) || []}
              // actions
              refetchMobs={() => refetch()}
              // pagination details
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPage={rowsPerPage}
              page={page}
              count={data.adminBlogsSearch.paginationDetails?.totalRecords || 0}
            />
          )}
        </Stack>
      </Container>
    </Box>
  );
}

const MOBS_LIST =
  gql(`query AdminBlogsSearch($searchInfo:AdminBlogsSearchInput!,$pagination:Pagination){
    adminBlogsSearch(searchInfo:$searchInfo,pagination:$pagination){
      paginationDetails {
        currentPage
        lastPage
        totalRecords
      }
      data {
        id
        userId
        blogType
        name
        privacyLevel
        membersJoiningType
        theme
        createdAt
        updatedAt
        themeMediaInfo {
          blogId
          themeId
          url
        }
        profile {
          userId
          firstName
          lastName
          createdAt
        }
        membersJoiningType
        privacyLevel
      }  
    }
  }`);
