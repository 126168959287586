import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    padding: "40px 10px",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.mode === "dark" ? "#151515" : "#fff",
    borderRadius: 5,
  },
  message: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: 600,
  },
}));

export default useStyles;
