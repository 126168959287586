import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    fontSize: "4rem",
    fontWeight: 800,
    marginBottom: "30px",
    color: "#023047",
  },
  link: {
    fontWeight: 600,
    paddingLeft: 5,
    cursor: "pointer",
    "&:hover": {
      color: "#219EBC",
      textDecoration: "underline",
    },
  },
  resetBtn: {
    textTransform: "none",
    width: "100%",
  },
  formContainer: {
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    overflow: "auto",
  },
  formWrapper: {
    background: "rgb(255 255 255 / 70%)",
    padding: "15px 30px",
    borderRadius: "10px",
    color: "#023047",
    width: "320px",
  },
  termsContainer: {
    color: "#023047",
    fontSize: "12px",
  },
  errorMsg: {
    color: "red",
    fontSize: "12px",
    padding: "10px 5px",
  },

  signupContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    alignItems: "center",
    color: "#575757",
    fontSize: "14px",
  },
}));

export default useStyles;
