import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import FileUploader from "../../components/FileUploader";
import { useEffect, useState } from "react";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";

type ProfileImageProps = {
  image?: string | null;
  name: string;
  userId: number;
  refetch: () => void;
};
export const ProfileImage = ({
  image,
  name,
  userId,
  refetch,
}: ProfileImageProps) => {
  const [preview, setPreview] = useState<any>(image);

  const [uploadImage, { loading: isUploading }] = useMutation(
    UPLOAD_AVATAR_IMAGE,
    {
      onError: (err) => toast.error(graphqlErrorToMsg(err)),
      onCompleted: (data) => {
        setPreview(data.uploadAvatarImageAdmin.s3Url);
        refetch();
        toast.success("Image uploaded successfully");
      },
    }
  );

  const [removeImage, { loading: isRemoving }] = useMutation(
    REMOVE_AVATAR_IMAGE,
    {
      onError: (err) => toast.error(graphqlErrorToMsg(err)),
      onCompleted: () => {
        setPreview(null);
        refetch();
        toast.success("Image removed successfully");
      },
    }
  );

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            src={preview}
            sx={{
              height: 100,
              mb: 2,
              width: 100,
            }}
          />
          <Typography
            gutterBottom
            variant="h6"
            sx={{ textTransform: "capitalize" }}
          >
            {name}
          </Typography>
          {/* <Typography color="text.secondary" variant="body2">
            {user.city} {user.country}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {user.timezone}
          </Typography> */}
        </Box>
      </CardContent>
      <Divider />
      <CardActions
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {isUploading || isRemoving ? (
          <CircularProgress />
        ) : (
          <>
            <FileUploader
              accept="image/png, image/jpeg"
              component={
                <Button fullWidth variant="text">
                  Upload picture
                </Button>
              }
              onChange={(file) =>
                uploadImage({
                  variables: { image: file, isCoverImage: false, userId },
                })
              }
            />

            {image && (
              <Button
                fullWidth
                variant="text"
                color="error"
                onClick={() =>
                  removeImage({
                    variables: { isCoverImage: false, userId },
                  })
                }
                sx={{ mt: 2 }}
              >
                Remove picture
              </Button>
            )}
          </>
        )}
      </CardActions>
    </Card>
  );
};

const UPLOAD_AVATAR_IMAGE =
  gql(`mutation UploadAvatarImageAdmin($image:Upload!,$isCoverImage:Boolean!,$userId:Int!){
  uploadAvatarImageAdmin(image:$image,isCoverImage:$isCoverImage,userId:$userId){
    s3Url
  }
}`);

const REMOVE_AVATAR_IMAGE = gql(`
mutation RemoveAvatarByAdmin($isCoverImage:Boolean!,$userId:Int){
  removeAvatar(isCoverImage:$isCoverImage,userId:$userId){
    userId
    email
    profile{
      userName
      avatarImage
    }
  }
}`);
