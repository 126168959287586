import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  navWrapper: {
    backgroundColor: "rgba(142, 202, 230, 0.15)",
  },
  divider: {
    width: 500,
  },
  heading: {
    fontSize: 30,
  },
  mainContainer: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  nameContainer: {
    // display: 'flex',
    // flexDirection: 'row',
    paddingLeft: 15,
  },
  name: {
    marginLeft: 5,
    fontSize: 23,
    marginTop: 5,
  },
  sectionContainerBox: {
    paddingLeft: 20,
  },

  settings: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.background.default,
  },
  settingSetionBox: {
    width: "100%",
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    padding: 5,
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
    fontWeight: 600,
    fontSize: "18px",
  },
  settingsSectionName: {
    fontWeight: "bold",
    margin: "5px 0",
    marginTop: 20,
  },
  settingsSectionItem: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

export default useStyles;
