import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  SvgIcon,
  useMediaQuery,
  Theme,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { alpha } from "@mui/material/styles";

import { UserContext } from "../../Context";
import ADMIN_ROUTES from "../../utils/adminRoutes";
import { LOCAL_KEY } from "../../utils/constants";

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

export const TopNav = (props: any) => {
  const { user } = useContext(UserContext);
  const { onNavOpen } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenSettingsMenu = (
    event: React.MouseEvent<HTMLElement>
  ): void => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
          position: "sticky",
          left: {
            lg: `${SIDE_NAV_WIDTH}px`,
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
          },
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Avatar
              sx={{
                cursor: "pointer",
                height: 40,
                width: 40,
              }}
              src={user?.avatarImage || ""}
              onClick={handleOpenSettingsMenu}
            />
            <PopOver
              anchorElUser={anchorElUser}
              handleCloseUserMenu={handleCloseUserMenu}
            />
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

TopNav.propTypes = {
  onNavOpen: PropTypes.func,
};

const PopOver = ({ anchorElUser, handleCloseUserMenu }: any) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          onClick={() => {
            navigate(ADMIN_ROUTES.PROFILE);
            handleCloseUserMenu();
          }}
        >
          <Typography textAlign="center">Profile</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            localStorage.setItem(LOCAL_KEY.ADMIN_DASHBOARD, "false");
            window.location.replace("/dashboard");
          }}
        >
          <Typography textAlign="center">Client App</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/logout");
            handleCloseUserMenu();
          }}
        >
          <Typography textAlign="center">Log out</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
