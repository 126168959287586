import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mobName: {
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxHeight: 48,
    "&:hover": { textDecoration: "underline" },
  },
  left: {
    display: "grid",
    alignItems: "center",
    gap: 8,
    gridTemplateColumns: "60px 1fr",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: 8,
    flexBasis: 1,
    maxWidth: "100%",
    overflow: "hidden",
    alignItems: "flex-start",
  },
  right: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    flexDirection: "row-reverse",
    cursor: "pointer",
  },
  infoBtn: {
    border: `1px solid ${theme.palette.text.primary}`,
    padding: "2px 10px",
    fontSize: 12,
    borderRadius: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
  },
}));

export default useStyles;
