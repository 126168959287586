import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  avatarContainer: {
    paddingLeft: 30,
    paddingTop: 10,
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: 100,
    backgroundSize: "cover",
    backgroundImage: 'url("https://www.w3schools.com/howto/img_avatar2.png")',
  },
  divider: {
    width: 650,
    margin: 0,
  },
  heading: {
    fontSize: 25,
    margin: 0,
  },
  input: {
    backgroundColor: theme.palette.background.paper,
    width: 400,
  },
  inputItem: {
    width: 400,
  },
  topContainer: {
    marginLeft: 10,
  },
  normalText: {
    fontSize: 16,
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: 600,
    margin: 0,
    marginBottom: 25,
  },
  warningBtn: {
    backgroundColor: "#FC4242",
    color: "white",
    border: "1px solid #FC4242",
  },
}));

export default useStyles;
