import React, { FocusEvent } from "react";
import { Box, FormControl, FormHelperText } from "@mui/material";
import useStyles from "./styles";

interface CustomInputProps {
  error?: any;
  placeholder?: string;
  value: string;
  onChange: any;
  inputType?: string;
  disabled?: boolean;
  label?: string;
  name: string;
  onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  touched: boolean | undefined;
}
export default function CustomInput({
  inputType,
  error,
  placeholder,
  onChange,
  value,
  disabled,
  label,
  name,
  touched,
  onBlur,
}: CustomInputProps): JSX.Element {
  const { classes: s } = useStyles();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", pb: 2 }}>
      {label && (
        <FormHelperText className={s.label} sx={{ mx: 0 }}>
          {label}
        </FormHelperText>
      )}
      <FormControl>
        {inputType === "textarea" ? (
          <textarea
            name={name}
            value={value}
            className={s.input}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            rows={6}
          />
        ) : (
          <input
            name={name}
            disabled={disabled}
            type={inputType || "text"}
            value={value}
            className={s.input}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
        {error && touched && (
          <FormHelperText className={s.error} sx={{ mx: 0 }}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}

CustomInput.defaultProps = {
  error: null,
  placeholder: "",
  inputType: "text",
  disabled: false,
  label: "",
};
