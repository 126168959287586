type Params = {
  dataSet?: any;
  query: string;
  variables: any;
  perPage: number;
  fetchMore: (params: any) => void;
};
export default function fetchMoreData({
  dataSet,
  query,
  fetchMore,
  variables,
  perPage,
}: Params) {
  const currentPage = dataSet?.[query]?.paginationDetails?.currentPage || 1;
  const lastPage = dataSet?.[query]?.paginationDetails?.lastPage || 1;
  const hasMore = lastPage > currentPage;

  if (hasMore) {
    fetchMore({
      variables: {
        ...variables,
        pagination: { perPage, nextPage: currentPage + 1 },
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        const prevData = prev[query]?.data || [];
        const newData = fetchMoreResult[query]?.data || [];
        // update states
        return {
          [query]: {
            ...fetchMoreResult[query],
            data: [...prevData, ...newData],
          },
        };
      },
    });
  }
}

export function checkHasMore(dataSet: any, query: string) {
  const currentPage = dataSet?.[query]?.paginationDetails?.currentPage || 1;
  const lastPage = dataSet?.[query]?.paginationDetails?.lastPage || 1;
  const hasMore = lastPage > currentPage;

  return hasMore;
}

export function getList(dataSet: any, obName: string) {
  const list =
    dataSet
      ?.map((x: any) => ({
        avatarImage: x?.[obName]?.avatarImage || "",
        firstName: x?.[obName]?.firstName || "",
        lastName: x?.[obName]?.lastName || "",
        userId: x?.[obName]?.userId || 0,
      }))
      .filter((x: any) => !!x) || [];

  return list;
}
