import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  mainContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "20px",
    paddingTop: "20px",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  loader: {
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
