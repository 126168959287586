import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { gql } from "../../__generated__";
import PageLoader from "../../components/PageLoader";
import { THEMES_LIST } from "../../utils/constants";
import ThemeWrapper from "../../containers/MobContainer/ThemeWrapper";
import { Box, Container } from "@mui/material";
import BlogPermissionContainer from "../../containers/BlogPermissionContainer";

export default function MobsPage() {
  const params = useParams();
  const mobId = Number(params.id) || 0;

  const { data: mobData, loading: isFetching } = useQuery(BLOG_INFO, {
    variables: { blogId: mobId },
    skip: !mobId,
    fetchPolicy: "network-only",
  });

  if (isFetching) return <PageLoader />;

  if (!mobData)
    return (
      <Container>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          Mob is not found
        </Box>
      </Container>
    );

  return (
    <div>
      <BlogPermissionContainer mobId={mobId}>
        <ThemeWrapper
          isWallpaperTheme={
            mobData.blogInfoById.theme === THEMES_LIST.WALLPAPER
          }
          isTimelineTheme={mobData.blogInfoById.theme === THEMES_LIST.TIMELINE}
          mobData={mobData.blogInfoById}
        />
      </BlogPermissionContainer>
    </div>
  );
}

const BLOG_INFO = gql(`
query BlogInfoByIdMobPage($blogId:Int!){
  blogInfoById(blogId:$blogId){
    id
    userId
    name
    description
    about
    privacyLevel
    membersJoiningType
    theme
    themeInfo{
      id
      name
    }
    privacyLevelInfo{
      id
      name
    }
    themeMediaInfo{
      blogId
      themeId
      url
    }
    members{
      userId
      blogId
      roleId
    }
    createdAt
  }
}
`);
