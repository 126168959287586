import React from "react";
import useStyles from "./styles";

interface Props {
  id: string;
  name: string;
  selectedValue: string;
  setSelectedValue: (value: string) => void;
}

export default function SettingsSectionItem(props: Props): JSX.Element {
  const { name, selectedValue, setSelectedValue, id } = props;
  const { classes } = useStyles();

  function itemClicked(): void {
    setSelectedValue(id);
  }

  return (
    <div
      className={
        selectedValue === id ? classes.itemSelected : classes.itemNotSelected
      }
      onClick={() => itemClicked()}
      onKeyDown={() => itemClicked()}
      role="button"
    >
      <span>{name}</span>
    </div>
  );
}
