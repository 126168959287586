import React from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

interface MobileDrawerProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  pages: string[];
}

export default function MobileDrawer({
  mobileOpen,
  handleDrawerToggle,
  pages,
}: MobileDrawerProps): JSX.Element {
  const drawerWidth = 240;
  const container =
    window !== undefined ? () => window.document.body : undefined;

  const navigate = useNavigate();
  const { search } = useLocation();

  function getQuery(): any {
    const params = new URLSearchParams(search);
    return params;
  }

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {pages.map((text: string) => {
          const q = getQuery();
          const isActive = q.get("query") === text.toLowerCase();

          return (
            <ListItem
              key={text}
              disablePadding
              onClick={() => {
                navigate(`/dashboard?query=${text.toLowerCase()}`);
                handleDrawerToggle();
              }}
            >
              <ListItemButton
                sx={{
                  "&:hover": { color: "secondary.main" },
                  color: isActive ? "secondary.main" : "initial",
                }}
              >
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  return (
    <div>
      <Toolbar />
      {/* mobile drawer  */}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
}
