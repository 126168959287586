import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ReactModal from "../ReactModal";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../utils/routes";
import CustomButton from "../CutomButton";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";

type Props = {
  members: {
    userId?: number | null;
    firstName?: string | null;
    lastName?: string | null;
    avatarImage?: string | null;
  }[];
  total: number;
  label: string;
  hasMore: boolean;
  isFriends?: boolean;
  isFollowing?: boolean;
  isFollowers?: boolean;
  blogId?: number;
  fetchMore: (isRefetch?: boolean) => void;
};

export default function MembersList({
  members,
  total,
  label,
  hasMore,
  fetchMore,
  isFriends,
  isFollowing,
  isFollowers,
}: Props) {
  const [isOpen, setModal] = useState(false);
  const navigate = useNavigate();

  const [followBlog, { loading }] = useMutation(FOLLOW_BLOG, {
    onCompleted: () => {
      fetchMore(true);
    },
  });

  if (members.length === 0) return null;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        px: 1,
      }}
    >
      <AvatarGroup total={total} spacing={"medium"}>
        {members.map((li) => (
          <Tooltip
            key={li.userId}
            title={`${li.firstName} ${li.lastName || ""}`}
          >
            <Avatar
              src={li.avatarImage || ""}
              alt={li.firstName || ""}
              sx={{ border: "2px solid #219EBC !important" }}
              onClick={() => navigate(`${ROUTES.PROFILE_PAGE}/${li.userId}`)}
            />
          </Tooltip>
        ))}
      </AvatarGroup>
      {total > 0 && (
        <Box sx={{ color: "text.secondary", fontSize: 12 }}>
          <span onClick={() => setModal(true)} style={{ cursor: "pointer" }}>
            View all
          </span>
        </Box>
      )}

      {isOpen && (
        <ReactModal isOpen={isOpen} handleClose={() => setModal(false)}>
          <Grid container spacing={2} sx={{ pb: 1 }}>
            <Grid item xs={12}>
              <Typography variant="h6">{label}</Typography>
            </Grid>
            {/* {loading && <PageLoader />} */}
            {members.map((m) => (
              <Grid item xs={12} lg={6} key={m.userId}>
                <Card sx={{ padding: { xs: 1, md: 2 } }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      gap: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Avatar src={m.avatarImage || ""} alt="profile" />
                      <Typography
                        textOverflow={"ellipsis"}
                      >{`${m.firstName} ${m.lastName}`}</Typography>
                    </Box>
                    {isFriends && (
                      <CustomButton
                        label="Friend"
                        onClick={() => {}}
                        sx={{
                          p: 0,
                          px: 2,
                          fontWeight: 400,
                        }}
                        disabled
                      />
                    )}
                    {isFollowing && (
                      <CustomButton
                        label="Following"
                        onClick={() => {}}
                        sx={{
                          p: 0,
                          px: 2,
                          fontWeight: 400,
                        }}
                        disabled
                      />
                    )}
                    {isFollowers && (
                      <CustomButton
                        label="Follow"
                        onClick={() => {
                          followBlog({ variables: { userId: m.userId } });
                        }}
                        sx={{
                          p: 0,
                          px: 2,
                          fontWeight: 400,
                          color: loading ? "white" : "text.secondary",
                        }}
                        loading={loading}
                      />
                    )}
                  </Box>
                </Card>
              </Grid>
            ))}
            {hasMore && (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center", mt: 2 }}
              >
                <Button
                  onClick={() => fetchMore()}
                  sx={{
                    borderRadius: 5,
                    fontSize: 12,
                    color: "text.secondary",
                  }}
                >
                  Load more...
                </Button>
              </Grid>
            )}
          </Grid>
        </ReactModal>
      )}
    </Box>
  );
}

const FOLLOW_BLOG = gql(`mutation FollowBlog($blogId:Int,$userId:Int){
  followBlog(blogId:$blogId,userId:$userId){
    message
  }
}`);
