import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
// import moment from "moment";
import { BsPencilSquare } from "react-icons/bs";
import { USER_ROLES } from "../../utils/constants";
import { Scrollbar } from "../scrollbar";

export const CustomersTable = (props: any) => {
  const {
    count = 0,
    items = [],
    // onDeselectAll,
    // onDeselectOne,
    onPageChange = () => {},
    onRowsPerPageChange,
    // onSelectAll,
    // onSelectOne,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    setEditUser,
  } = props;

  // const selectedSome = selected.length > 0 && selected.length < items.length;
  // const selectedAll = items.length > 0 && selected.length === items.length;

  const getUserRoles = (userRoles: any[]) => {
    const filteredRoles = userRoles.map((role) => {
      let color:
        | "default"
        | "info"
        | "primary"
        | "warning"
        | "error"
        | "secondary"
        | "success" = "info";
      if (role.roleId === USER_ROLES.MODERATOR) {
        color = "primary";
      } else if (role.roleId === USER_ROLES.ADMIN) {
        color = "warning";
      }
      // else if (role.roleId === USER_ROLES.SUPER_ADMIN) {
      //   color = "error";
      // }

      return (
        <Chip
          key={role.roleId}
          label={role.displayName}
          color={color}
          size="small"
        />
      );
    });
    return filteredRoles;
  };

  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedAll}
                  indeterminate={selectedSome}
                  onChange={(event) => {
                    if (event.target.checked) {
                      onSelectAll?.();
                    } else {
                      onDeselectAll?.();
                    }
                  }}
                />
              </TableCell> */}
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((user: any) => {
                const isSelected = selected.includes(user.userId);
                // const createdAt = moment(user.createdAt).format(
                //   "YYYY/MM/DD hh:mm A"
                // );

                return (
                  <TableRow hover key={user.userId} selected={isSelected}>
                    {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected}
                      onChange={(event) => {
                        if (event.target.checked) {
                          onSelectOne?.(user.userId);
                        } else {
                          onDeselectOne?.(user.userId);
                        }
                      }}
                    />
                  </TableCell> */}
                    <TableCell>#{user.userId}</TableCell>
                    <TableCell>
                      <Stack alignItems="center" direction="row" spacing={2}>
                        <Avatar src={user.profile.avatarImage}>
                          {user.firstName}
                        </Avatar>
                        <Typography variant="subtitle2">
                          {`${user.firstName} ${user.lastName || ""}`}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.profile.userName}</TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                          maxWidth: "100px",
                        }}
                      >
                        {getUserRoles(user.roles)}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        sx={{ mr: 1 }}
                        onClick={() => setEditUser(user.userId)}
                      >
                        <SvgIcon fontSize="small">
                          <BsPencilSquare />
                        </SvgIcon>
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20]}
      />
    </Card>
  );
};

CustomersTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array,
  setEditUser: PropTypes.func,
};
