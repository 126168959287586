import { Box, CircularProgress } from "@mui/material";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { BsFillCameraFill } from "react-icons/bs";
import { gql } from "../../__generated__";
import FileUploader from "../FileUploader";
// import CustomButton from "../CutomButton";
import useWindowSize, { Size } from "../../utils/Hooks/useWindowSize";

import useStyles from "./styles";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { UserContext } from "../../Context";

interface CoverImageProps {
  imageUrl: string | null;
  blogId: number;
  themeId: number;
  userId?: number;
  title?: string;
}
export default function CoverImage({
  imageUrl,
  blogId,
  themeId,
  userId,
  title,
}: CoverImageProps): JSX.Element {
  const { classes: s } = useStyles();

  const [preview, setPreview] = useState(imageUrl);
  const { user } = useContext(UserContext);

  const { loading, refetch } = useQuery(GET_IMAGE, {
    variables: { blogId, themeId },
    onCompleted: (apiRes) => setPreview(apiRes.themeMediaInfo?.url || null),
  });

  const [changeImage, { loading: isUploading }] = useMutation(
    UPLOAD_THEME_IMAGES,
    {
      onCompleted: (data) => {
        toast.success("Image successfully uploaded");
        refetch();
      },
      onError: (err) => toast.error(graphqlErrorToMsg(err)),
    }
  );

  const cn = [s.root];
  if (!loading && preview) {
    cn.push(s.editCover);
  }

  const size: Size = useWindowSize();
  const isProfileOwner = userId === user?.userId;

  return (
    <div
      className={cn.join(" ")}
      style={{
        backgroundImage: `url(${preview})`,
        height: size?.width ? size.width / 3 : "45vh",
        minHeight: 150,
      }}
    >
      {isUploading && (
        <Box className={s.loader}>
          <CircularProgress />
        </Box>
      )}
      {isProfileOwner && !isUploading && !preview && (
        <FileUploader
          component={
            <Box className={s.uploadWrapper}>
              <Box className={s.uploader}>
                <BsFillCameraFill size={50} />
              </Box>
              <div>Upload Cover Image</div>
              <div>(1800 x 600)</div>
            </Box>
          }
          onChange={(file) => {
            if (file) {
              changeImage({
                variables: {
                  info: {
                    blogId: blogId,
                    themeId: themeId,
                    inputFile: file,
                  },
                },
              });
            }
          }}
        />
      )}
      {/* {isProfileOwner && !isUploading && preview && (
        <FileUploader
          component={
            <Box className={s.uploadBtnWrapper}>
              <CustomButton
                onClick={() => {
                  console.log();
                }}
                label="Update cover image"
              />
            </Box>
          }
          onChange={(file) => {
            if (file) {
              changeImage({
                variables: {
                  info: {
                    blogId: blogId,
                    themeId: themeId,
                    inputFile: file,
                  },
                },
              });
            }
          }}
        />
      )} */}
      {title && <Box className={s.title}>{title} </Box>}
    </div>
  );
}

const UPLOAD_THEME_IMAGES =
  gql(`mutation uploadBlogThemeMedia($info:UploadBlogThemeMediaInput!){
  uploadBlogThemeMedia(info:$info){
    id
    blogType
    membersJoiningType
    privacyLevel
    themeMediaInfo{
      blogId
      themeId
      url
    }
    name
  }
}`);

const GET_IMAGE = gql(`query themeMediaInfo($blogId:Int!,$themeId:Int!){
  themeMediaInfo(blogId:$blogId,themeId:$themeId){
    blogId
    themeId
    url
  }
}`);
