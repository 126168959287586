import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  mainContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 30,
  },
  imageComponent: {
    marginRight: 10,
    marginTop: 10,
  },
  clickableImage: {
    cursor: "pointer",
    borderRadius: 10,
    width: 250,
  },
  imageEach: {
    borderRadius: 10,
  },
  gallery: {
    backgroundColor: "#F7F7F7",
    paddingLeft: 10,
    paddingBottom: 10,
    paddingTop: 5,
    borderRadius: 10,
  },
  galleryGrid: {
    backgroundColor: "#D1D1D1",
    cursor: "pointer",
    borderRadius: 10,
    width: 295,
    height: 200,
    marginLeft: -10,
  },
  albumName: {
    fontSize: 18,
  },
  addAlbum: {
    fontSize: 80,
    textAlign: "center",
  },
  messageBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 100,
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 18,
  },
}));

export default useStyles;
