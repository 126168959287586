import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  mainContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
  },
  formWrapper: {
    background: "rgb(255 255 255 / 70%)",
    padding: "15px 30px",
    borderRadius: "10px",
    color: "#023047",
    width: "320px",
  },
}));

export default useStyles;
