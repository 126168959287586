import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => {
  const { mode } = theme.palette;

  return {
    root: {
      paddingTop: 0,
    },
    postButton: {
      padding: "5px 50px",
      fontSize: 18,
      borderRadius: 30,
    },
    links: {
      padding: "8px !important",
    },
    linkWrapper: {
      backgroundColor: mode === "dark" ? "#219ebc26" : "#219ebc66",
      borderRadius: 8,
    },
    wallpaper: {
      backgroundColor:
        theme.palette.mode === "dark" ? "#151515 !important" : "#fff",
    },
  };
});

export default useStyles;
