import React from "react";
import { Button } from "@mui/material";
import useStyles from "./styles";

interface TagButtonProps {
  name: string;
  onClick: () => void;
  className?: string;
  selected?: boolean;
}

export default function TagButton({
  name,
  onClick,
  className,
  selected,
}: TagButtonProps): JSX.Element {
  const { classes: s } = useStyles();
  const cn = [s.root];
  if (className) {
    cn.push(className);
  }
  if (selected) {
    cn.push(s.selected);
  }
  return (
    <Button className={cn.join(" ")} onClick={onClick}>
      {" "}
      {name}{" "}
    </Button>
  );
}
TagButton.defaultProps = {
  className: "",
  selected: false,
};
