import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Divider } from "@mui/material";

import useStyles from "./styles";
import CustomInput from "../../components/CustomInput";
import BackgroundScreen from "../../components/BackGroundScreen";
import { Box } from "@mui/system";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  password: Yup.string().required().min(8).max(15).label("Password"),
  confirmPassword: Yup.string().required().label("confirmPassword"),
});
export default function ResetPasswordPage(): JSX.Element {
  const { classes: s } = useStyles();
  const history = useNavigate();
  const params = useParams();

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      toast.success("You have successfully reset your password");
      history("/");
    },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleSubmit(data: {
    password: string;
    confirmPassword: string;
  }): void {
    resetPassword({
      variables: {
        token: params.token || "",
        password: data.password,
      },
    });
  }

  return (
    <BackgroundScreen>
      <div className={s.formContainer}>
        <div className={s.formWrapper}>
          <h1 className={s.title}>Moblog</h1>
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            {(formikProps) => {
              const {
                errors,
                handleSubmit,
                handleChange,
                touched,
                values,
                handleBlur,
              } = formikProps;
              return (
                <Form>
                  <CustomInput
                    name="password"
                    inputType={"password"}
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.password}
                    error={errors?.password}
                  />
                  <CustomInput
                    name="confirmPassword"
                    inputType={"password"}
                    placeholder="Confirm Password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.confirmPassword}
                    error={errors?.confirmPassword}
                  />

                  <Button
                    variant="contained"
                    className={s.resetBtn}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {loading ? "Loading" : "Change Password"}
                  </Button>
                </Form>
              );
            }}
          </Formik>

          <Box sx={{ my: 2 }}>
            <Divider>Or</Divider>
          </Box>
          <Button
            className={s.link}
            onClick={() => {
              history("/");
            }}
          >
            Log in
          </Button>
          {/* <Button
            onClick={() => {
              history("/signup");
            }}
          >
            Sign up
          </Button> */}
        </div>
      </div>
    </BackgroundScreen>
  );
}

const RESET_PASSWORD = gql(`
mutation resetPassWordByToken($token:String!,$password:String!){
  resetPassWordByToken(token:$token,password:$password){
    message
  }
}
`);
