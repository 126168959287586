import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px dashed ${theme.palette.text.primary}`,
    padding: "20px 50px",
    borderRadius: "15px",
    cursor: "pointer",
    background:
      theme.palette.mode === "dark"
        ? "#023047"
        : theme.palette.background.default,
  },
  boxWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  icon: {
    marginBottom: 6,
  },
  text: {
    fontWeight: 600,
    textAlign: "center",
  },
}));

export default useStyles;
