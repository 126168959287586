import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
  },
  avatarContainer: {
    // paddingLeft: 30,
    paddingTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  avatar: {
    width: 100,
    height: 100,
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      width: 150,
      height: 150,
    },
  },
  divider: {
    width: "100%",
    margin: 0,
  },
  heading: {
    fontSize: 25,
  },
  name: {
    marginTop: 5,
    textAlign: "center",
  },
  inputItem: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: 8,
    border: `1px solid ${theme.palette.text.primary}`,
    borderColor: theme.palette.text.primary,
  },
  inputTextArea: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  topContainer: {
    marginLeft: 10,
  },
  subHeading: {
    color: "#219EBC",
    margin: 0,
  },
  btnWrapper: {
    margin: "15px 0",
    display: "flex",
    justifyContent: "flex-end",
  },
  label: { color: "#219EBC", fontSize: 14 },
}));

export default useStyles;
