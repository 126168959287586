import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  mainContainer: {
    paddingLeft: "5%",
    paddingRight: "5%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#E8EAE6",
  },
  topPhotos: {
    cursor: "pointer",
  },
  blogHead: {
    paddingTop: "80px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "350px",
    color: "black",
    overflowY: "hidden",
    backgroundSize: "cover",
  },
  imageChangingButton: {
    position: "absolute",
    top: 10,
    left: 10,
    padding: 0,
  },
  coverImageGrid: {
    // border: '1px solid rgba(0,0,0,0.1)',
    paddingLeft: "20px",
    paddingRight: "20px",
    // backgroundColor: '#ffff',
    // marginRight: 10,
  },
  leftGridContainer: {
    border: "1px solid rgba(0,0,0,0.1)",
    backgroundColor: "#ffff",
    width: 300,
    paddingLeft: "20px",
    paddingRight: 10,
    marginRight: 10,
    paddingBottom: 30,
  },
  descriptionContainer: {
    backgroundColor: "#ffff",
    width: "100%",
    fontWeight: "bold",
    marginTop: 10,
    marginLeft: 5,
    paddingLeft: "20px",
    paddingRight: 10,
    marginRight: 10,
    paddingBottom: 10,
    borderRadius: 5,
  },
  settingsContainer: {
    border: "1px solid rgba(0,0,0,0.1)",
    backgroundColor: "#ffff",
    width: 300,
    paddingLeft: 20,
    paddingRight: 10,
    paddingTop: 20,
    marginRight: 10,
    paddingBottom: 30,
  },
  blogNav: {
    width: "100vw",
    // paddingLeft: '16%',
    // paddingRight: '16%',
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#F4F9F9",
  },
  settingsText: {
    color: "#000",
    fontSize: 20,
  },
  gridContainer: {
    border: "1px solid rgba(0,0,0,0.1)",
    backgroundColor: "#ffff",
  },
  coverImage: {
    width: "100vw",
    height: "250px",
  },

  blogName: {
    fontSize: "45px",
    fontWeight: "bold",
    color: "#000",
  },
  userName: {
    marginTop: 0,
    fontSize: "16px",
    color: "rgba(0,0,0,0.5)",
  },
  blogQuote: {
    fontSize: "18px",
    color: "#000",
  },
  friendsMessage: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  locationPlace: {
    fontSize: "16px",
    color: "#000",
    marginLeft: 0,
  },
  followingCount: {
    fontWeight: "bold",
    color: "#000",
    fontSize: 16,
  },
  followTag: {
    marginLeft: -3,
    fontSize: 16,
  },
  rightColContainer: {
    width: "100%",
  },
  rightInput: {
    backgroundColor: "rgba(0,0,0,0.1)",
    height: "40px",
    fontSize: "15px",
  },
  activitySummaryContainer: {
    padding: "10px",
    borderRadius: "5px",
  },
  topPhotosContainer: {
    // backgroundColor: 'rgba(0,0,0,0.1)',
    // marginLeft: 5,
    // paddingLeft: 15,
    paddingBottom: 20,
    paddingTop: 10,
    borderRadius: "5px",
    width: 240,
  },
  subtitle: {
    fontSize: "22px",
    fontWeight: "bold",
    textAlign: "left",
    marginLeft: 10,
  },

  followButton: {
    height: "40px",

    fontSize: "15px",
    borderRadius: "20px",
  },
  settingsButton: {
    height: "40px",
    width: "100px",
    fontSize: "15px",
    borderRadius: "20px",
    backgroundColor: "#000",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.5)",
    },
  },
  postBox: {
    width: 540,
    height: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    color: "#fff",
  },
  postImage: {
    width: 540,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    color: "#fff",
  },
  commentBox: {
    width: 340,
    display: "flex",
    padding: 10,
    border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 5,
  },
  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "20px",
    paddingTop: "20px",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  loader: {
    color: theme.palette.text.primary,
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  info: {
    padding: 10,
    textAlign: "center",
    fontSize: 16,
    fontWeight: 600,
    width: "100%",
  },
}));

export default useStyles;
