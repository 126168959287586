import { useContext, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
// import moment from 'moment';
import useStyles from "./styles";
import "./styles.css";
import { Post } from "../../__generated__/graphql";
import { UserContext } from "../../Context";

import AdminCardActions from "./adminActions";

interface Props {
  post: Post | null;
  highlighted: boolean;
  flagged: boolean;
  refetch: () => void;
  isShared?: boolean;
}

export default function PostCardContainer(props: Props): JSX.Element {
  const { post, highlighted, flagged, refetch, isShared } = props;

  const { classes } = useStyles();
  const navigate = useNavigate();

  const [imageLoaded, setImageLoaded] = useState(false);
  // const [hasSeeMore, setHasSeeMore] = useState(false);
  // const [postHided, setPostHided] = useState(post?.hidden);

  const [showOverlay, setOverlay] = useState(false);

  const [showBotttomPart, setShowBottomPart] = useState(false);

  // context
  const { user } = useContext(UserContext);

  const id = post?.id;
  const tabIndex = 0;

  const renderImage = () => {
    const url = isShared
      ? post?.originalPost?.media?.[0]?.fileUrl
      : post?.media?.[0]?.fileUrl;

    return (
      <img
        // eslint-disable-next-line react/destructuring-assignment
        src={url || ""}
        alt={post?.title || ""}
        onError={() => setImageLoaded(false)}
        onLoad={() => setImageLoaded(true)}
        className={!imageLoaded ? classes.loadingImage : classes.image}
      />
    );
  };

  function routeToIndividualPost(): void {
    navigate(`/post/${id}`);
  }

  function handleClick(): void {
    setShowBottomPart(!showBotttomPart);
  }

  // function hideThePost(): void {}

  // function unHideThePost(): void {}

  const isAdmin = user?.isAdmin;
  const isModerator = user?.isModerator;

  const showPostEdit = isAdmin || isModerator;

  const postTitle = post?.isShared ? post.shareText : post?.title;

  return (
    <Box
      className={
        highlighted ? classes.highlightContainer : classes.postCardContainer
      }
    >
      <Box
        className={classes.postCardImageContainer}
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={tabIndex}
      >
        {/* {imageLoaded ? ( */}
        <div
          className={classes.imageWrapper}
          onMouseEnter={() => setOverlay(true)}
          onMouseLeave={() => setOverlay(false)}
          onClick={() => routeToIndividualPost()}
          role="button"
          onKeyDown={routeToIndividualPost}
          tabIndex={tabIndex}
        >
          {renderImage()}
          {showOverlay && (
            <Box>
              <motion.div
                style={{
                  justifyContent: "flex-end",
                }}
                className={classes.overlay}
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.3,
                }}
              >
                <Typography variant="h6" className={classes.postTitle}>
                  {postTitle}
                </Typography>
              </motion.div>
            </Box>
          )}
        </div>
        {/* ) : (
          <Stack spacing={1} sx={{ height: 300 }}>
            <Box sx={{ height: 300 }} />
          </Stack>
        )} */}

        {/* admin buttons */}
        {post && showPostEdit && (
          <AdminCardActions
            postId={post.id}
            blogId={post.blogId}
            refetch={() => refetch()}
            flagged={flagged}
            highlighted={highlighted}
          />
        )}
      </Box>
    </Box>
  );
}
