import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    background: "rgb(255 255 255 / 70%)",
    padding: "15px 30px",
    borderRadius: "10px",
    color: "#023047",
    width: "320px",
  },
  avatarContainer: {
    paddingLeft: 30,
    paddingTop: 10,
  },
  avatar: {
    height: "100px !important",
    width: "100px !important",
    borderRadius: "50%",
    backgroundSize: "cover",
    margin: "auto",
  },
  divider: {
    width: 650,
    margin: 0,
  },
  heading: {
    fontSize: 30,
  },
  name: {
    fontSize: 30,
    marginTop: 5,
    textAlign: "center",
  },
  inputItem: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  inputTextArea: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  topContainer: {
    marginLeft: 10,
  },
  subHeading: {
    fontSize: 18,
    fontWeight: "bold",
    margin: 0,
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: "25px",
    textAlign: "center",
    fontWeight: 800,
    marginBottom: "30px",
    color: "#023047",
  },
  errorMsg: {
    color: "red",
    fontSize: "12px",
    padding: "10px 5px",
  },
  uploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 10,
    width: "100%",
    padding: 15,
    borderStyle: "dashed",
    textAlign: "center",
    borderRadius: 10,
    height: 250,
  },
  imagePreview: {
    maxWidth: "100%",
  },
}));

export default useStyles;
