import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  logo: {
    cursor: "pointer",
    fontWeight: 700,
    letterSpacing: ".1rem",
    textDecoration: "none",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  link: {
    textDecoration: "none",
    matgin: 2,
    cursor: "pointer",
    padding: "0 0.5rem",
    display: "block",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "1.1rem",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  activeLink: {
    matgin: 2,
    cursor: "pointer",
    padding: "0 0.5rem",
    display: "block",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "1.1rem",
    color: theme.palette.text.secondary,
  },

  iconButton: {
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: "initial",
    },
  },
}));

export default useStyles;
