import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { Button, Card, Grid, SvgIcon } from "@mui/material";
import { useForm } from "react-hook-form";
import HooksInput from "../Input/Input";
import * as yup from "yup";

type CustomersSearchProps = {
  handleSearch: (fields: searchFields) => void;
};

const schema = yup
  .object()
  .shape({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string(),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

type searchFields = {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
};

export const CustomersSearch = ({ handleSearch }: CustomersSearchProps) => {
  const { register, handleSubmit } = useForm<FormData>();
  return (
    <Card sx={{ p: 2 }}>
      <form onSubmit={handleSubmit(handleSearch)}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <HooksInput
              register={register}
              error={null}
              touched={false}
              label="Email"
              name="email"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <HooksInput
              register={register}
              error={null}
              touched={false}
              label="First Name"
              name="firstName"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <HooksInput
              register={register}
              error={null}
              touched={false}
              label="Last Name"
              name="lastName"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                display: "flex",
                height: "54px",
                mb: 2,
                width: "100%",
              }}
              startIcon={
                <SvgIcon>
                  <MagnifyingGlassIcon />
                </SvgIcon>
              }
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};
