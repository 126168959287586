import React, { useState } from "react";
import ImageListItem from "@mui/material/ImageListItem";
import { Box, Button, Container, Grid } from "@mui/material";
import useStyles from "./styles";
import GalleryCreateModel from "../../../../components/GalleryCreateModel";
import GalleryImageUploadModel from "../../../../components/GalleryImageUploadModel";

const tabIndex = 0;

interface Props {
  blogId: number;
  pageOwner: boolean;
}

export default function Gallery(props: Props): JSX.Element {
  const { blogId, pageOwner } = props;
  const { classes } = useStyles();
  const [openedGalleryPhotos, setOpenGalleryPhotos] = useState<any>([]);
  const [selectedAlbum, setSelectedAlbum] = useState<any>();
  const [openAGallery, setOpenAGallery] = useState(false);
  const [openGalleryCreateModel, setOpenGalleryCreateModel] = useState(false);
  const [createdGalleries, setCreatedGalleries] = useState<any>([]);
  const [openGalleryImageUpload, setOpenGalleryImageUpload] = useState(false);

  // React.useEffect(() => {
  //   getGalleries(blogId).then((res) => {
  //     if (res?.data?.statusCode === 200) {
  //       setCreatedGalleries(res?.data?.data);
  //     }
  //   });
  // }, []);

  function handleCloseGalleryCreateModel(): void {
    setOpenGalleryCreateModel(false);
  }

  function handleClick(item: any): void {
    // Open the phoo of the gallery view
    setOpenAGallery(true);
    setSelectedAlbum(item);
    // getAlbumPhotos(item?.id).then((res) => {
    //   if (res?.data?.statusCode === 200) {
    //     setOpenGalleryPhotos(res?.data?.data);
    //   }
    // });
  }

  if (!pageOwner) {
    return (
      <div className={classes.mainContainer}>
        <Container>
          <Box className={classes.messageBox}>No collections to show</Box>
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.mainContainer}>
      <Container>
        <GalleryImageUploadModel
          openModel={openGalleryImageUpload}
          handleClodeModel={() => setOpenGalleryImageUpload(false)}
          album={selectedAlbum}
          images={openedGalleryPhotos}
          setImage={(data: any) => setOpenGalleryPhotos(data)}
        />
        <GalleryCreateModel
          openGalleryCreateModel={openGalleryCreateModel}
          handleCloseGalleryCreateModel={() => handleCloseGalleryCreateModel()}
          createdGalleries={createdGalleries}
          setCreatedGalleries={(data: any) => {
            setCreatedGalleries(data);
            setSelectedAlbum(data[data.length - 1]);
            setOpenAGallery(true);
          }}
        />
        <div
          className={classes.gallery}
          style={{
            textAlign: "center",
            minHeight: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Grid container>
            <Grid item>
              {!openAGallery && pageOwner && (
                <Box>
                  <Button
                    onClick={() => {
                      setOpenGalleryCreateModel(true);
                    }}
                  >
                    <div className={classes.galleryGrid}>
                      <div style={{ paddingTop: 80 }}>
                        <p className={classes.addAlbum}>+</p>
                      </div>
                    </div>
                    <p className={classes.albumName}>Create Album</p>
                  </Button>
                </Box>
              )}

              {!openAGallery &&
                createdGalleries.map((item: any) => (
                  <Box>
                    <ImageListItem
                      key={item.img}
                      className={classes.imageComponent}
                      onClick={() => handleClick(item)}
                      onKeyDown={() => handleClick(item)}
                      role="button"
                      tabIndex={tabIndex}
                    >
                      {item?.thumbnail ? (
                        <img
                          src={`${item?.thumbnail}?w=300&h=200&fit=crop&auto=format`}
                          srcSet={`${item?.thumbnail}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt={item?.name}
                          className={classes.clickableImage}
                          style={{ width: 300, height: 200 }}
                        />
                      ) : (
                        <div
                          style={{
                            width: 300,
                            height: 200,
                            backgroundColor: "#f1f2f4",
                            borderRadius: 10,
                          }}
                        />
                      )}
                    </ImageListItem>
                    <p className={classes.albumName}>{item?.name}</p>
                    <p>{item?.caption}</p>
                  </Box>
                ))}
            </Grid>
          </Grid>

          {openAGallery && (
            <div>
              <Button onClick={() => setOpenAGallery(false)}>
                Back to the Albums
              </Button>
              {pageOwner && (
                <Button
                  onClick={() => {
                    setOpenGalleryImageUpload(true);
                  }}
                >
                  <div className={classes.galleryGrid}>
                    <div style={{ paddingTop: 80 }}>
                      <p className={classes.addAlbum}>+</p>
                    </div>
                  </div>
                  <p className={classes.albumName}>Add Images</p>
                </Button>
              )}
            </div>
          )}

          <Grid container>
            <Grid item>
              {openAGallery &&
                openedGalleryPhotos.map((item: any) => (
                  <Box>
                    <ImageListItem
                      key={item?.id}
                      className={classes.imageComponent}
                    >
                      <img
                        src={`${item?.url}?w=300&h=200&fit=crop&auto=format`}
                        srcSet={`${item?.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={item}
                        className={classes.imageEach}
                        style={{ width: 300 }}
                      />
                    </ImageListItem>
                  </Box>
                ))}
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
