import { useState } from "react";
import { Box, Button, CircularProgress, Grid, SvgIcon } from "@mui/material";
import {
  FlagOutlined,
  FlagRounded,
  StarBorderRounded,
  StarRounded,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { useMutation } from "@apollo/client";
import { gql } from "../../__generated__";
import useStyles from "./styles";

type AdminCardActionsProps = {
  refetch: () => void;
  postId: number;
  blogId: number;
  highlighted: boolean;
  flagged: boolean;
};

export default function AdminCardActions({
  refetch,
  postId,
  blogId,
  highlighted,
  flagged,
}: AdminCardActionsProps) {
  // states
  const [postHighlighted, setPostHighlighted] = useState(highlighted);
  const [postFlagged, setPostFlagged] = useState(flagged);

  // mutations
  const [highlightThePost, { loading }] = useMutation(HIGHLIGHT_POST, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: () => {
      setPostHighlighted(true);
      refetch();
    },
  });
  const [removeHighlightedPost, { loading: isRemoving }] = useMutation(
    REMOVE_HIGHLIGHT_POST,
    {
      onError: (err) => toast.error(graphqlErrorToMsg(err)),
      onCompleted: () => {
        setPostHighlighted(false);
        refetch();
      },
    }
  );

  const [flagPost, { loading: isFlagging }] = useMutation(FLAG_POST, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: () => {
      setPostFlagged(true);
      refetch();
    },
  });
  const [removeFlaggedPost, { loading: isRemovingFlag }] = useMutation(
    REMOVE_FLAG_POST,
    {
      onError: (err) => toast.error(graphqlErrorToMsg(err)),
      onCompleted: () => {
        setPostFlagged(false);
        refetch();
      },
    }
  );

  //   functions
  function highlightPost(): void {
    highlightThePost({
      variables: {
        info: { blogId, postId },
      },
    });
  }

  function removeHighLightOfThePost(): void {
    removeHighlightedPost({ variables: { postId } });
  }

  function flagThePost(): void {
    flagPost({ variables: { postId } });
  }

  function unFlagThePost(): void {
    removeFlaggedPost({ variables: { postId } });
  }

  const { classes: s } = useStyles();

  return (
    <Box
      sx={{
        position: "absolute",
        top: 5,
        left: 5,
        display: "flex",
        gap: 1,
        px: 1,
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Grid style={{ paddingTop: 10 }}>
        {postHighlighted ? (
          <Button
            onClick={() => removeHighLightOfThePost()}
            variant="contained"
            className={s.actionActiveButtons}
            color="warning"
          >
            {isRemoving ? (
              <CircularProgress size={24} />
            ) : (
              <SvgIcon>
                <StarRounded />
              </SvgIcon>
            )}
          </Button>
        ) : (
          <Button
            onClick={() => highlightPost()}
            aria-label="Highlight"
            variant="contained"
            className={s.actionButtons}
            color="warning"
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <SvgIcon>
                <StarBorderRounded />
              </SvgIcon>
            )}
          </Button>
        )}
      </Grid>
      <Grid style={{ paddingTop: 10 }}>
        {postFlagged ? (
          <Button
            onClick={() => unFlagThePost()}
            variant="contained"
            className={s.actionActiveButtons}
            color="info"
          >
            {isRemovingFlag ? <CircularProgress size={24} /> : <FlagRounded />}
          </Button>
        ) : (
          <Button
            onClick={() => flagThePost()}
            variant="contained"
            className={s.actionButtons}
            color="info"
          >
            {isFlagging ? (
              <CircularProgress size={24} />
            ) : (
              <SvgIcon>
                <FlagOutlined />
              </SvgIcon>
            )}
          </Button>
        )}
      </Grid>
      {/* <Grid style={{ paddingTop: 10 }}>
              {isAdmin &&
                (postHided ? (
                  <Button onClick={() => unHideThePost()}>Unhide</Button>
                ) : (
                  <Button onClick={() => hideThePost()}>Hide</Button>
                ))}
            </Grid> */}
    </Box>
  );
}

const FLAG_POST = gql(`mutation FlagPost($postId:Int!){
    flagPost(postId:$postId){
      message
    }
  }`);

const REMOVE_FLAG_POST = gql(`mutation RemoveFlaggedPost($postId:Int!){
    removeFlaggedPost(postId:$postId){
      message
    }
  }`);

const HIGHLIGHT_POST = gql(`mutation HighlightPost($info:PostHighlightInput!){
    highlightPost(info:$info){
      message
    }
  }`);

const REMOVE_HIGHLIGHT_POST = gql(`mutation RemoveHighlight($postId:Int!){
    removeHighlight(postId:$postId){
      message
    }
  }`);
