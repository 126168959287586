import ReactModal from "../ReactModal";
import { Box } from "@mui/system";
import { WarningAmberOutlined } from "@mui/icons-material";
import { Typography, colors } from "@mui/material";
import CustomButton from "../CutomButton";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { toast } from "react-toastify";

type Props = {
  mobId: number;
  isOpen: boolean;
  handleClose: () => void;
  refetchMobs: () => void;
};

export default function LeaveModal({
  isOpen,
  handleClose,
  refetchMobs,
  mobId,
}: Props) {
  const [leaveMob, { loading }] = useMutation(LEAVE_MOB, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: () => {
      refetchMobs();
      toast.success("Leaved successfully");
    },
  });
  return (
    <ReactModal
      isOpen={isOpen}
      handleClose={handleClose}
      modelWidth={{ md: "400px", sm: "60%" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <SvgIcon fontSize="large"> */}
        <WarningAmberOutlined
          style={{ fontSize: 78, color: colors.red[400] }}
        />
        {/* </SvgIcon> */}
        <Typography textAlign={"center"} sx={{ my: 4 }} color={"primary"}>
          Are you sure want to leave
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "80%",
          }}
        >
          <CustomButton
            onClick={() => {
              handleClose();
            }}
            variant="outlined"
            label="No"
          />

          <CustomButton
            variant="contained"
            label="Yes"
            onClick={() => {
              leaveMob({ variables: { blogId: mobId } });
            }}
            loading={loading}
          />
        </Box>
      </Box>
    </ReactModal>
  );
}

const LEAVE_MOB = gql(`mutation LeaveBlog($blogId:Int!){
    leaveBlog(blogId:$blogId){
      message
    }
  }`);
