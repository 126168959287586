import React, { useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import useStyles from "./styles";
import FileUploader from "../../FileUploader";
import { Box } from "@mui/system";
import { BsFillCameraFill } from "react-icons/bs";
import CustomButton from "../../CutomButton";
import { gql } from "../../../__generated__";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../../utils/graphqlErrorToMsg";

interface ThemeImageUploaderProps {
  title: string;
  size: string;
  isWallpaperImage?: boolean;
  blogType: number;
  blogId: number;
}

const ThemeImageUploader = ({
  title,
  size,
  isWallpaperImage,
  blogType,
  blogId,
}: ThemeImageUploaderProps) => {
  const { classes } = useStyles();

  const [preview, setPreview] = useState<String | null>(null);

  // API

  const { loading, refetch } = useQuery(GET_IMAGE, {
    variables: { blogId, themeId: blogType },
    onCompleted: (apiRes) => setPreview(apiRes.themeMediaInfo?.url || null),
  });
  const [updateImage, { loading: isUploading }] = useMutation(
    UPLOAD_THEME_IMAGES,
    {
      onCompleted: (data) => {
        toast.success("Image uploaded successfully");
        refetch();
      },
      onError: (err) => toast.error(graphqlErrorToMsg(err)),
    }
  );

  const changeImage = (image: File | null) => {
    // update image
    updateImage({
      variables: {
        info: {
          blogId: blogId,
          themeId: blogType,
          inputFile: image,
        },
      },
    });
  };

  if (isUploading || loading) {
    return (
      <Grid item xs={12}>
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      </Grid>
    );
  }

  if (preview) {
    return (
      <Grid item xs={12} mb={2}>
        <Box
          style={{
            backgroundImage: `url(${preview})`,
            height: isWallpaperImage ? "90vh" : "45vh",
            minHeight: 150,
            backgroundSize: "cover",
            position: "relative",
            width: "100%",
          }}
        >
          <FileUploader
            component={
              <CustomButton
                className={classes.uplodBtn}
                onClick={() => {
                  console.log();
                }}
                label="Update Image"
              />
            }
            onChange={(file) => {
              if (file) {
                changeImage(file);
              }
            }}
          />
        </Box>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Box className={classes.imageWrapper}>
        <FileUploader
          component={
            <Box className={classes.uploadWrapper}>
              <Box className={classes.uploader}>
                <BsFillCameraFill size={50} />
              </Box>
              <div>{title}</div>
              <div>({size})</div>
            </Box>
          }
          onChange={(file) => {
            if (file) {
              changeImage(file);
            }
          }}
        />
      </Box>
    </Grid>
  );
};

export default ThemeImageUploader;

const UPLOAD_THEME_IMAGES =
  gql(`mutation uploadBlogThemeMedia($info:UploadBlogThemeMediaInput!){
    uploadBlogThemeMedia(info:$info){
      id
      blogType
      membersJoiningType
      privacyLevel
      themeMediaInfo{
        blogId
        themeId
        url
      }
      name
  }
}`);

const GET_IMAGE = gql(`query themeMediaInfo($blogId:Int!,$themeId:Int!){
  themeMediaInfo(blogId:$blogId,themeId:$themeId){
    blogId
    themeId
    url
  }
}`);
