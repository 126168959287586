import { useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useLazyQuery } from "@apollo/client";

import PostContainer from "../../containers/PostContainer";
// import NewsComponent from '../../Components/NewsComponent';
import useStlyes from "./styles";
import "./styles.css";
import { NAVBAR_QUERY } from "../../utils/constants";
import { gql } from "../../__generated__";
import { PostSearchPageEnum } from "../../__generated__/graphql";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import PageLoader from "../../components/PageLoader";

export default function Dashboard(): JSX.Element {
  const PER_PAGE = 15;
  const { classes } = useStlyes();

  const [getPosts, { loading, data, refetch, fetchMore }] = useLazyQuery(
    POST_LIST,
    {
      fetchPolicy: "network-only",
      onError: (err) => toast.error(graphqlErrorToMsg(err)),
    }
  );

  const handleScroll = () => {
    const paginationDetails = data?.searchPost?.paginationDetails;
    const lastPage = paginationDetails?.lastPage;
    const currentPage = paginationDetails?.currentPage;
    const isLastPage = lastPage === currentPage;

    const isScrollToBottom =
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 2;
    if (data && !isLastPage && isScrollToBottom) {
      fetchMore({
        variables: {
          pagination: {
            nextPage: (currentPage || 1) + 1,
            perPage: PER_PAGE,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const prevData = prev.searchPost?.data || [];
          const newData = fetchMoreResult.searchPost?.data || [];

          // update states
          return {
            searchPost: {
              ...fetchMoreResult.searchPost,
              data: [...prevData, ...newData],
            },
          };
        },
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const value = query.get("query") || NAVBAR_QUERY.LATEST;
    let page = PostSearchPageEnum.Following;

    if (NAVBAR_QUERY.FOLLOWING === value) {
      page = PostSearchPageEnum.Following;
    } else if (NAVBAR_QUERY.FRIENDS === value) {
      page = PostSearchPageEnum.Friends;
    } else if (NAVBAR_QUERY.HIGHTLIGHTS === value) {
      page = PostSearchPageEnum.Highlights;
    } else if (NAVBAR_QUERY.DISCUSSIONS === value) {
      page = PostSearchPageEnum.Discussions;
    } else if (NAVBAR_QUERY.LATEST === value) {
      page = PostSearchPageEnum.Latest;
    }
    getPosts({
      variables: {
        searchInfo: { page },
        pagination: { perPage: PER_PAGE, nextPage: 1 },
      },
    });
  }, [search, getPosts]);

  if (loading) return <PageLoader />;
  if (!loading && !data)
    return (
      <Box className={classes.info}>
        Page did not load properly.
        <br /> Please refresh the page.
      </Box>
    );

  if (data) {
    return (
      <Container maxWidth="xl" className={classes.root}>
        <div className={classes.mainDashboardContainer}>
          <div className={classes.dahsboard}>
            <Box>
              <Box sx={{ flexGrow: 1 }}>
                {data.searchPost?.data?.length !== 0 && (
                  <PostContainer
                    postState={data.searchPost?.data}
                    refetch={() => refetch()}
                  />
                )}

                {data?.searchPost?.data?.length === 0 && (
                  <div
                    style={{
                      textAlign: "center",
                      padding: 100,
                      minHeight: "100vh",
                    }}
                    className={classes.emptyContainer}
                  >
                    {/* <img
                        src="./images/nopost.svg"
                        alt=""
                        style={{ height: 250 }}
                      /> */}
                    <h2>No posts to show </h2>
                  </div>
                )}
              </Box>
            </Box>
          </div>
        </div>
      </Container>
    );
  }
  return <></>;
}

const POST_LIST = gql(`
  query SearchPost($searchInfo: PostsSearchInput!, $pagination:Pagination) {
    searchPost(searchInfo: $searchInfo,pagination:$pagination) {
      paginationDetails{
        currentPage
        lastPage
        totalRecords
      }
      data{
        allowReshare
        blogId
        flagged
        isShared
        shareText
        originalPost{
          id
          media{
            id
            fileUrl
          }
        }
        highlighted{
          postId
        }
        createdAt
        description
        hidden
        id
        media {
          id
          fileUrl
        }
        privacyLevel
        title
        userId
      }
    }
  }`);
