import { CircularProgress, Container } from "@mui/material";
import React from "react";

export default function PageLoader() {
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        minHeight: "20em",
      }}
    >
      <CircularProgress />
    </Container>
  );
}
