import { Box } from "@mui/material";
import React from "react";

import useStyles from "./styles";

interface FileUploaderProps {
  onChange: (file: File) => void;
  accept?: string;
  component: JSX.Element;
  className?: string;
}

export default function FileUploader({
  onChange,
  accept,
  component,
  className,
}: FileUploaderProps): JSX.Element {
  const ref = React.useRef<HTMLInputElement | null>(null);
  const { classes: s } = useStyles();

  const handleClick = (): void => {
    ref.current?.click();
  };

  const handleChange = (event: any): void => {
    const file = event.target.files[0];
    onChange(file);
  };

  const cn = [s.root];
  if (className) {
    cn.push(className);
  }

  return (
    <div className={s.root}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            alignItems: "center",
          }}
          onClick={handleClick}
        >
          {component}
        </Box>
      </Box>
      <input
        type="file"
        accept={accept}
        ref={ref}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </div>
  );
}

FileUploader.defaultProps = {
  accept: "image/*",
  className: "",
};
