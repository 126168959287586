import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => {
  const { secondary, primary, mode, text } = theme.palette;
  const bgColor = mode === "light" ? secondary.main : primary.main;
  const color = mode === "light" ? primary.main : text.secondary;

  return {
    root: {
      padding: "6px 18px",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "20px",
      textTransform: "capitalize",
      "&:hover": {
        background: bgColor,
        border: `1px solid ${bgColor}`,
        color,
      },
    },
    selected: {
      backgroundColor: text.primary,
      color: text.secondary,
      "&:hover": {
        background: text.primary,
        color: text.secondary,
        border: `1px solid ${text.primary}`,
      },
    },
  };
});

export default useStyles;
