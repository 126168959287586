import moment from "moment";

export default function commentTimeCalculate(created: string): string {
  const createdAt = created;
  const ms = moment(
    moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
    "DD/MM/YYYY HH:mm:ss"
  ).diff(
    moment(
      moment(createdAt).format("DD/MM/YYYY HH:mm:ss"),
      "DD/MM/YYYY HH:mm:ss"
    )
  );
  const d = moment.duration(ms);
  const hours = d.asHours();

  let time = "";
  if (hours > 48) {
    time = `${Math.floor(d.asDays())} days`;
  } else if (hours > 24) {
    time = `${Math.floor(d.asDays())} day`;
  } else if (hours > 1) {
    time = `${Math.floor(hours)} h`;
  } else if (hours < 1) {
    if (d.asMinutes() >= 1) {
      time = `${Math.floor(d.asMinutes())} min`;
    } else if (Math.floor(d.asSeconds()) < 0) {
      time = "0 sec";
    } else {
      time = `${Math.floor(d.asSeconds())} sec`;
    }
  }
  return time;
}
