import { useState } from "react";
import { toast } from "react-toastify";
import { Avatar, Box, Card, Tooltip, Typography, colors } from "@mui/material";
import { useMutation } from "@apollo/client";
import CustomButton from "../CutomButton";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { gql } from "../../__generated__";
import CopyClipboard from "./CopyClipboard";
import ROUTES from "../../utils/routes";

type MemberCardProps = {
  image?: string | null;
  username?: string | null;
  updateFriend: (friends: [] | number[]) => void;
  currentFriends: [] | number[];
  isUpdate?: boolean;
  mobId?: number;
  id?: number | null;
  isLinkGenarate?: boolean;
};

export default function MemberCard({
  image,
  username,
  updateFriend,
  currentFriends,
  isUpdate,
  mobId,
  id,
  isLinkGenarate,
}: MemberCardProps) {
  const [newLink, setLink] = useState<string | null>(null);

  const [addMember, { loading: isAdding }] = useMutation(ADD_FRIEND, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: (data) => {
      updateFriend([...currentFriends, data.addMembersToblog.userId]);
    },
  });

  const [removeMember, { loading: isRemoving }] = useMutation(REMOVE_FRIEND, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: (data) => {
      const { userId } = data.removeMembersFromblog;
      const newList = currentFriends.filter((f) => f !== userId);
      updateFriend(newList);
    },
  });

  const [genarateLink, { loading: isGenarating }] = useMutation(GENARATE_LINK, {
    onCompleted: (data) => {
      const host = window.location.host;
      const url = `${host}${ROUTES.MOBS_JOINGING}/${data.generateInviteLink.uuid}`;
      setLink(url);
    },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  const addFriend = (id: number) => {
    if (isUpdate && mobId) {
      addMember({ variables: { blogId: mobId, userId: id } });
    } else {
      updateFriend([...currentFriends, id]);
    }
  };

  const removeFriend = (id: number) => {
    const newList = currentFriends.filter((f) => f !== id);
    if (isUpdate && mobId) {
      removeMember({
        variables: {
          blogId: mobId,
          userId: id,
        },
      });
    } else {
      updateFriend(newList);
    }
  };

  const isAdded = !!currentFriends.find((x: number) => x === id);

  const renderButtons = () => {
    /* add and remove buttons */
    if (!isLinkGenarate) {
      return (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {isAdded ? (
            <CustomButton
              label="Remove"
              onClick={() => {
                id && removeFriend(id);
              }}
              variant="outlined"
              loading={isRemoving}
              sx={{
                padding: "2px",
                fontSize: 12,
                background: colors.grey[400],
              }}
            />
          ) : (
            <CustomButton
              label="+ Add"
              loading={isAdding}
              onClick={() => {
                id && addFriend(id);
              }}
              variant="outlined"
              sx={{ padding: "2px", fontSize: 12 }}
            />
          )}
        </Box>
      );
    }

    /* genarate link button */
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <CustomButton
          label="Create"
          onClick={() => {
            if (mobId && id) {
              genarateLink({
                variables: { blogId: mobId, userId: id },
              });
            }
          }}
          variant="outlined"
          loading={isGenarating}
          sx={{
            padding: "2px",
            px: 0.5,
            fontSize: 12,
          }}
        />
      </Box>
    );
  };

  return (
    <Card sx={{ padding: { xs: 1, md: 2 } }}>
      {newLink && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {newLink && <CopyClipboard uuid={newLink} />}
        </Box>
      )}

      {!newLink && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "40px 1fr 80px",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Avatar src={image || ""} alt="profile" />
          <Box sx={{ width: "100%", overflow: "hidden" }}>
            <Tooltip title={username}>
              <Typography
                textOverflow={"ellipsis"}
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {username}
              </Typography>
            </Tooltip>
          </Box>
          {renderButtons()}
        </Box>
      )}
    </Card>
  );
}

const ADD_FRIEND = gql(`mutation AddMembersToblog($blogId:Int!,$userId:Int!){
    addMembersToblog(blogId:$blogId,userId:$userId){
      userId
    }
  }`);

const REMOVE_FRIEND =
  gql(`mutation RemoveMembersFromblog($blogId:Int!,$userId:Int!){
    removeMembersFromblog(blogId:$blogId,userId:$userId){
      userId
    }
  }`);

const GENARATE_LINK =
  gql(`mutation GenerateInviteLink($blogId:Int!,$userId:Int!){
    generateInviteLink(blogId:$blogId,userId:$userId){
      uuid
    }
  }`);
