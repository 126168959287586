import React from "react";
import { Grid } from "@mui/material";
import CommentPosts from "../../../containers/BlogThemes/WhiteBlog/Comment";
// import Gallery from "../../../containers/BlogThemes/WhiteBlog/Gallery";
import LikePosts from "../../../containers/BlogThemes/WhiteBlog/Like";
import SharePosts from "../../../containers/BlogThemes/WhiteBlog/Share";
import useStyles from "./styles";
import { THEMES } from "../../../utils/constants";
import PostList from "../../PostList";
// import CustomButton from "../../CutomButton";
import { Account } from "../../../__generated__/graphql";
import Gallery from "../../../containers/BlogThemes/WhiteBlog/Gallery";

interface MainSectionProps {
  blogId: number;
  blogDetails: any;
  userDetails: Pick<Account, "profile">;
  activeTabId: number;
}
export default function MainSection({
  blogId,
  blogDetails,
  userDetails,
  activeTabId,
}: MainSectionProps): JSX.Element {
  const { classes: s } = useStyles();

  const isWallpaperTheme = blogDetails?.activeTheme === THEMES.WALLPAPER;

  const cn = [s.linkWrapper];
  if (isWallpaperTheme) {
    cn.push(s.wallpaper);
  }

  return (
    <div className={s.root}>
      <Grid container spacing={2}>
        {/* <Grid
          item
          xs={12}
          sx={{ display: "flex", mt: 1 }}
          justifyContent="center"
        >
          <CustomButton
            className={s.postButton}
            label="Create Post"
            onClick={() => navigate("/create-post")}
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          sx={{ display: "flex", mt: 0 }}
          justifyContent="center"
        >
          {activeTabId === 0 && <PostList blogId={blogId} />}
          {activeTabId === 1 && (
            <LikePosts userId={userDetails.profile.userId} />
          )}
          {activeTabId === 2 && (
            <SharePosts userId={userDetails.profile.userId} />
          )}
          {activeTabId === 3 && (
            <CommentPosts userId={userDetails.profile.userId} />
          )}
          {activeTabId === 4 && <Gallery blogId={blogId} pageOwner={false} />}
        </Grid>
      </Grid>
    </div>
  );
}
