import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

const CheckBoxLabel = ({ label }: { label: string }) => (
  <Typography fontSize={14}>{label}</Typography>
);

type Props = {
  checked: boolean;
  name: string;
  onChange: () => void;
  label: string;
  size?: "small" | "medium";
  disabled?: boolean;
  control: any;
};

export default function CustomCheckBox({
  checked,
  onChange,
  label,
  name,
  size,
  disabled,
  control,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              checked={checked}
              disabled={!!disabled}
              onChange={() => {
                onChange();
              }}
              size={size || "small"}
            />
          }
          label={<CheckBoxLabel label={label} />}
        />
      )}
    />
  );
}
