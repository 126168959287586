import { useState } from "react";
import { Divider, Grid } from "@mui/material";
import { toast } from "react-toastify";
import useStyles from "./styles";
import { useMutation } from "@apollo/client";

import { THEMES_LIST } from "../../../utils/constants";
import { gql } from "../../../__generated__";
import graphqlErrorToMsg from "../../../utils/graphqlErrorToMsg";
import { Blog } from "../../../__generated__/graphql";
import ThemeImageUploader from "./ThemeImageUploader";
import BlogForm from "./form";

export default function BlogThemeItems({
  blogDetails,
  isMob,
}: {
  blogDetails: Pick<Blog, "id" | "theme" | "name" | "about"> | null;
  isMob?: boolean;
}): JSX.Element {
  const { classes, cx } = useStyles();
  const [activeTheme, setActiveTheme] = useState(
    blogDetails?.theme || THEMES_LIST.WALLPAPER
  );
  const [selectedTheme, setTheme] = useState(
    blogDetails?.theme || THEMES_LIST.WALLPAPER
  );

  const [changeTheme] = useMutation(CHANGE_THEME, {
    onCompleted: (data) => {
      toast.success("Theme changed successfully");
    },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  if (!blogDetails)
    return <div>{isMob ? "Mob" : "Moblog"} details not found</div>;

  return (
    <div>
      {!isMob && (
        <>
          <p className={classes.subHeading}>Moblog</p>
          <Divider className={classes.divider} />
        </>
      )}

      <BlogForm
        isMob={isMob}
        blogId={blogDetails.id}
        blogName={blogDetails.name || ""}
        about={blogDetails.about || ""}
      />

      <p className={classes.subHeading}>Theme</p>
      <Divider className={classes.divider} />
      <p className={classes.normalText}>Select the theme</p>

      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={6} md={4}>
          <div
            className={cx(
              classes.themeBox,
              activeTheme === THEMES.CEMENT ? classes.selected : ""
            )}
            onClick={() => setActiveTheme(THEMES.CEMENT)}
          >
            <div>Masonry Theme</div>
          </div>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <div
            className={cx(
              classes.themeBox,
              activeTheme === THEMES_LIST.WALLPAPER ? classes.selected : ""
            )}
            onClick={() => {
              changeTheme({
                variables: {
                  blogId: blogDetails.id,
                  themeId: THEMES_LIST.WALLPAPER,
                },
              });
              setTheme(THEMES_LIST.WALLPAPER);
              setActiveTheme(THEMES_LIST.WALLPAPER);
            }}
          >
            <div>Wallpaper Theme</div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div
            className={cx(
              classes.themeBox,
              activeTheme === THEMES_LIST.TIMELINE ? classes.selected : ""
            )}
            onClick={() => {
              changeTheme({
                variables: {
                  blogId: blogDetails.id,
                  themeId: THEMES_LIST.TIMELINE,
                },
              });
              setTheme(THEMES_LIST.TIMELINE);
              setActiveTheme(THEMES_LIST.TIMELINE);
            }}
          >
            <div>Timeline Theme</div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 3 }}>
        {/* <Grid item xs={12} style={{ fontWeight: "bold", marginBottom: 15 }}>
          Image section
        </Grid>
        <Grid item xs={12}>
          <CustomSelect
            label="Select Theme"
            onChange={(value) => setTheme(value)}
            options={[
              { label: "Wallpaper", value: THEMES_LIST.WALLPAPER },
              { label: "Timeline", value: THEMES_LIST.TIMELINE },
            ]}
            value={selectedTheme}
            key={"theme"}
          />
        </Grid> */}
        {selectedTheme === THEMES_LIST.WALLPAPER && (
          <ThemeImageUploader
            blogId={blogDetails.id}
            title="Upload Wallpaper Image"
            size="1600 x 2400"
            blogType={THEMES_LIST.WALLPAPER}
            isWallpaperImage
          />
        )}
        {selectedTheme === THEMES_LIST.TIMELINE && (
          <ThemeImageUploader
            blogId={blogDetails.id}
            title="Upload Timeline Image"
            size="1800 x 600"
            blogType={THEMES_LIST.TIMELINE}
          />
        )}
      </Grid>
    </div>
  );
}

const CHANGE_THEME = gql(`mutation changeBlogTheme($blogId:Int!,$themeId:Int!){
  changeBlogTheme(blogId:$blogId,themeId:$themeId){
    id
    name
    userId
    blogType
    theme
    privacyLevel
    privacyLevelInfo{
      id
      name
    }
    themeInfo{
      id
      name
    }
    themeMediaInfo{
      blogId
      themeId
      url
    }
    followingCount
    friendsCount
    followersCount 
    createdAt
  }
}`);
