import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import { gql } from "../../__generated__";
import PostListCard from "./postListCard";

interface Props {
  blogId: number;
}

const PER_PAGE = 15;

export default function PostList({ blogId }: Props): JSX.Element {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: postList,
    loading,
    fetchMore,
  } = useQuery(POST_LIST_BY_BLOG_ID, {
    variables: {
      blogId,
      pagination: {
        perPage: PER_PAGE,
        nextPage: 1,
      },
    },
    fetchPolicy: "network-only",
  });

  const handleScroll = () => {
    if (
      postList &&
      window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
    ) {
      // User has scrolled to the bottom
      fetchMore({
        variables: {
          pagination: {
            nextPage: (currentPage || 1) + 1,
            perPage: PER_PAGE,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          // const prevPaginate = prev.searchPost?.paginationDetails;
          const hasMore =
            (postList.postsByBlogId?.paginationDetails?.lastPage || 1) >
            (postList?.postsByBlogId?.paginationDetails?.currentPage || 1);

          if (hasMore) {
            const prevData = prev.postsByBlogId?.data || [];
            const newData = fetchMoreResult.postsByBlogId?.data || [];
            // update states
            setCurrentPage(currentPage + 1);
            return {
              postsByBlogId: {
                ...fetchMoreResult.postsByBlogId,
                data: [...prevData, ...newData],
              },
            };
          }
          return prev;
        },
      });
    }
  };

  useEffect(() => {
    if (postList) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postList]);

  return (
    <PostListCard
      loading={loading}
      postList={postList?.postsByBlogId?.data || []}
    />
  );
}

const POST_LIST_BY_BLOG_ID = gql(`
query PostsByBlogId($blogId:Int!,$pagination:Pagination){
  postsByBlogId(blogId:$blogId,pagination:$pagination){
    paginationDetails{
      currentPage
      lastPage
      totalRecords
    }
    data{
      id
      userId
      blogId
      title
      description
      privacyLevel
      hidden
      allowReshare
      isShared
      shareText
      originalPost{
        id
        media{
          id
          fileUrl
        }
      }
      media{
        id
        postId
        fileUrl
      }
      likes{
        postId
        profile{
          userId
        }
        userId
      }
      shares{
        id
      }
      comments{
        id
        postId
        blogId
        userId
        comment
        postInfo{
          id
          userId
          blogId
          title
          description
          privacyLevel
        }
      }
      profile{
        userId
        firstName
        lastName
        avatarImage
        userName
        email
        createdAt
      }
      createdAt
      updatedAt
    }
  }
}`);
