import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

export default function PrivacyPolicyPage() {
  return (
    <Container>
      <Typography fontSize={20} my={4}>
        Privacy Policy Page
      </Typography>
    </Container>
  );
}
