export const NAVBAR_QUERY = {
  LATEST: "latest",
  FOLLOWING: "following",
  FRIENDS: "friends",
  HIGHTLIGHTS: "highlights",
  DISCUSSIONS: "discussions",
};

export const mode = {
  DARK: "dark",
  LIGHT: "light",
};

export const PRIVACY_LEVELS = [
  { label: "Public", value: "PUBLIC" },
  { label: "Followers", value: "FOLLOWER" },
  { label: "Friends", value: "FRIENDS" },
  { label: "Only me", value: "ONLYME" },
];

export const THEMES = {
  TIMELINE: "WHITE",
  WALLPAPER: "BLACK",
  COVER: "CEMENT",
};
export const THEMES_LIST = {
  MASONARY: 1,
  WALLPAPER: 2,
  TIMELINE: 3,
};
export const PRIVACY_LEVEL = {
  PUBLIC: 1,
  PRIVATE: 2,
  ONLY_ME: 3,
  CUSTOM: 4,
};
export enum BlogPrivacy {
  "PUBLIC",
  "PRIVATE",
}

export const USERACTIONS = {
  LIKED: 1,
};

export const POST_SHARE_TYPES = {
  PUBLIC: 1,
};

export const POSTS_PRIVACY_LEVELS = {
  ONLY_ME: 1,
  PUBLIC: 2,
  FRIENDS_ONLY: 3,
};

export const LOCAL_KEY = {
  ACCESS_TOKEN: "accessToken",
  ADMIN_DASHBOARD: "admin-dashboard",
};

export const USER_ROLES = {
  USER: 1,
  ADMIN: 2,
  MODERATOR: 3,
  // SUPER_ADMIN: 4,
};

export const USER_PRIVACY_LEVELS = {
  PUBLIC: 1,
  PRIVATE: 2,
  ONLY_ME: 3,
  CUSTOM: 4,
};

export const USER_ROLES_LIST = Object.entries(USER_ROLES).map((r) => ({
  name: r[0].toLowerCase(),
  label: r[0].split("_").join(" ").toLowerCase(),
  id: r[1],
}));

export const BLOGS_PRIVACY_LEVEL = {
  PUBLIC: 1,
  PRIVATE: 2,
};

export const BLOG_MEMEBR_ROLES = {
  OWNER: 1,
  MEMBER: 2,
};

export const BLOGS_MEMBER_JOINING_TYPE = {
  NO_ONE: 1,
  ANY: 2,
  BY_BEING_ADDED: 3,
};

export const BLOGS__SEARCH_TYPES = {
  personal: "PERSONAL",
  mob: "MOBS",
  blog: "BLOGS",
  log: "LOGS",
};
export const POST_USER_ACTION_TYPES = {
  like: 1,
};

export const MODAL_LIST = {
  edit: "editModal",
  member: "memberModal",
  delete: "deleteModal",
  privacy: "privacyModal",
  permission: "permissionModal",
  leave: "leaveModal",
  genarate: "genarateLinkModal",
};
