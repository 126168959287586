import React from "react";
import BlogThemeItems from "../Settings/BlogThemeItems";
import { gql } from "../../__generated__";
import { useQuery } from "@apollo/client";
import PageLoader from "../PageLoader";

type Props = { mobId: number };

export default function MobThemeWrapper({ mobId }: Props) {
  const { data: mobData, loading: isFetching } = useQuery(BLOG_INFO, {
    variables: { blogId: mobId || 0 },
    skip: !mobId,
    fetchPolicy: "network-only",
  });

  const getBlogData = () => {
    if (mobData?.blogInfoById) {
      const mobValues = mobData.blogInfoById;
      return {
        id: mobValues.id,
        name: mobValues.name,
        about: mobValues.about,
        theme: mobValues.theme,
      };
    }
    return null;
  };

  if (isFetching) return <PageLoader />;
  return (
    <div>
      <BlogThemeItems blogDetails={getBlogData()} isMob />
    </div>
  );
}

const BLOG_INFO = gql(`
query BlogInfoById($blogId:Int!){
  blogInfoById(blogId:$blogId){
    id
    userId
    name
    description
    about
    privacyLevel
    membersJoiningType
    theme
    themeInfo{
      id
      name
    }
    privacyLevelInfo{
      id
      name
    }
    themeMediaInfo{
      blogId
      themeId
      url
    }
  }
}
`);
