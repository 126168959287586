import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import useStyles from "./styles";
import PostView from "../../components/PostView";
import { gql } from "../../__generated__";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import PageLoader from "../../components/PageLoader";

export default function SinglePostPage(): JSX.Element {
  const { classes: s } = useStyles();
  const params = useParams();

  // api data
  const { data, loading, refetch } = useQuery(POST_VIEW, {
    variables: { postId: Number(params.postID) },
    skip: !params?.postID,
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  if (loading) return <PageLoader />;
  if (!data?.viewPost) return <div>Post not found</div>;

  return (
    <div className={s.root}>
      <PostView data={data} refetchPost={refetch} />
    </div>
  );
}

const POST_VIEW = gql(`query ViewPost($postId:Int!){
  viewPost(postId:$postId){
    id
    userId
    blogId
    title
    description
    privacyLevel
    hidden
    isShared
    shareText
    flagged
    profile{
      userName
      userId
      firstName
      lastName
      createdAt
    }
    highlighted{
      postId
    }
    originalPost{
      id
      userId
      blogId
      title
      description
      privacyLevel
      isShared
      blog{
        id
        name
      }
      media{
        id
        postId
        fileUrl
      }
    }
    allowReshare
    createdAt
    updatedAt
    sharedUserProfile{
      userName
      userId
      firstName
      lastName
      createdAt
    }
    profile{
      userName
      userId
      firstName
      lastName
      createdAt
    }
    blog{
      id
      userId
      blogType
      blogTypeInfo
      name
      description
      title
      theme
      privacyLevel
      default
      friendsCount
      followingCount
      followersCount
      profile{
        userName
        userId
        firstName
        lastName
        createdAt
      }
      privacyLevelInfo{
        id
        name
      }
      themeInfo{
        id
        name
      }
      themeMediaInfo{
        blogId
        themeId
        url
      }
      media{
        id
        blogId
        mediaType
        fileUrl
        status
      }
      createdAt
      updatedAt
    }
    media{
      id
      postId
      fileUrl
    }
    comments{
      id
      postId
      blogId
      userId
      comment
      profile{
        userId
        firstName
        lastName
        createdAt
      }
      postInfo{
        id
        userId
        blogId
        title
        description
        privacyLevel
        isShared
      }
    }
    likes{
      postId
      blogId
      profile{
        userId
        firstName
        lastName
        createdAt
      }
    }
    shares{
      id
      blogId
      profile{
        userId
        firstName
        lastName
        createdAt
      }
    }
  }
}`);
