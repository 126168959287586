import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { Avatar, Grid } from "@mui/material";
import { AiFillRightCircle } from "react-icons/ai";
import { Box } from "@mui/system";
import * as Yup from "yup";

import CustomInput from "../CustomInput";
import { UserContext } from "../../Context";

interface CommentFormProps {
  isEdit?: boolean;
  text?: string;
  submitComment: (value: string) => void;
  commentId?: number;
}

const validationSchema = Yup.object().shape({
  comment: Yup.string().required().label("Comment"),
});

export default function CommentForm({
  text,
  isEdit,
  submitComment,
}: CommentFormProps) {
  const userContext = useContext(UserContext);

  return (
    <Grid container alignItems={"center"} sx={{ px: isEdit ? 0 : 2 }}>
      {!isEdit && (
        <Grid item>
          <Avatar
            sx={{ mb: "16px", width: 40, height: 40 }}
            src={userContext?.user?.avatarImage || ""}
          />
        </Grid>
      )}
      <Grid item xs sx={{ pl: isEdit ? 0 : 1 }}>
        <Formik
          initialValues={{ comment: text || "" }}
          onSubmit={(values, { resetForm }) => {
            resetForm();
            submitComment(values.comment);
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleSubmit, handleChange, handleBlur }) => (
            <Form>
              <Box sx={{ position: "relative", width: "100%" }}>
                <CustomInput
                  name="comment"
                  placeholder="Write a comment"
                  value={values.comment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors?.comment}
                  touched={false}
                />
                <Box
                  onClick={() => handleSubmit()}
                  sx={{
                    position: "absolute",
                    top: 3,
                    right: 1,
                    cursor: "pointer",
                  }}
                >
                  <AiFillRightCircle size="30px" color="#393E46" />
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
