import { Box, Tab, Tabs } from "@mui/material";
import React from "react";

type Props = {
  tabValue: string;
  setTabValue: (tab: string) => void;
};

export default function FilterTabs({ tabValue, setTabValue }: Props) {
  console.log(tabValue);

  return (
    <Box sx={{ my: 1 }}>
      <Tabs
        value={tabValue}
        onChange={(event: React.SyntheticEvent, newValue: string) => {
          setTabValue(newValue);
        }}
      >
        <Tab value={"own"} label="Own" />
        <Tab value={"all"} label="Member" />
      </Tabs>
    </Box>
  );
}
