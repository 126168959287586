import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      padding: "15px 30px",
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgb(15 15 15 / 85%)"
          : "rgb(255 255 255 / 85%) !important",
      borderRadius: 10,
      gap: 8,
      color:
        theme.palette.mode === "dark"
          ? theme.palette.text.secondary
          : theme.palette.text.primary,
      fontSize: "14px",
    },
    blogHead: {
      paddingTop: "80px",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      height: "350px",
      overflowY: "hidden",
      backgroundSize: "cover",
    },
    blogName: {
      fontSize: "20px",
      fontWeight: "bold",
      // textAlign: "center",
      // textTransform: "capitalize",
    },
    userName: {},
    locationPlace: {},
    followingCount: {
      fontWeight: "bold",
    },
    followTag: {
      color: theme.palette.text.secondary,
    },
    btn: {
      marginTop: 10,
    },
    commentText: {},
    activity: {
      marginTop: 1,
      color: "#219EBC",
    },
    counts: {
      display: "flex",
      paddingTop: "5px !important",
      gap: 2,
    },
    icon: {
      color: theme.palette.text.primary,
    },
  };
});

export default useStyles;
