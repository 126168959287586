import React from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import CoverImage from "../../components/CoverImage";
import useStyles from "../../pages/ProfilePage/styles";
import { Account } from "../../__generated__/graphql";
import { THEMES_LIST } from "../../utils/constants";

interface MassonaryThemeProps {
  userDetails: Pick<Account, "profile">;
  blogDetails: any;
  profileComponent: () => JSX.Element;
  mainComponent: () => JSX.Element;
  acitivityComponent: () => JSX.Element;
}

export default function MassonaryTheme({
  userDetails,
  acitivityComponent,
  mainComponent,
  profileComponent,
}: MassonaryThemeProps): JSX.Element {
  const { classes: s } = useStyles();

  return (
    <div className={s.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CoverImage
            imageUrl={null}
            blogId={1}
            themeId={THEMES_LIST.MASONARY}
          />
        </Grid>
      </Grid>
      <Container sx={{ pt: 5, maxWidth: "95% !important" }}>
        <Grid container spacing={2}>
          <Grid item md={3} sx={{ display: { xs: "none", md: "flex" } }}>
            {profileComponent()}
          </Grid>

          <Grid item md={6} xs={12}>
            {mainComponent()}
          </Grid>
          <Grid item sm={3} sx={{ display: { xs: "none", md: "flex" } }}>
            {acitivityComponent()}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
