import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  avatarContainer: {
    paddingLeft: 30,
    paddingTop: 10,
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: 100,
    backgroundSize: "cover",
    backgroundImage: 'url("https://www.w3schools.com/howto/img_avatar2.png")',
  },
  divider: {
    width: "100%",
    margin: 0,
  },
  heading: {
    fontSize: 25,
    margin: 0,
  },
  themeBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 100,
    padding: 10,
    border: "1px solid #c2c1be",
    // backgroundColor: '#f7f7fa',
    borderRadius: 5,
    cursor: "pointer",
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
    fontWeight: 600,
  },
  buttonBox: {
    height: 40,
    paddingTop: 7,
    paddingLeft: 10,
  },
  inputItem: {
    width: 400,
  },
  topContainer: {
    marginLeft: 10,
  },
  normalText: {
    fontSize: 16,
    marginTop: 25,
  },
  subHeading: {
    fontSize: 20,

    margin: 0,
  },
  radio: {
    display: "none",
  },
  uploadWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  uploader: {
    padding: 15,
    borderRadius: "50%",
    border: `1px solid ${theme.palette.text.primary}`,
    width: 80,
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginBottom: 5,
    "&:hover": {
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  imageWrapper: {
    padding: "2rem",
    border: "1px dashed",
    borderRadius: "1rem",
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 250,
    backgroundColor: "rgb(176 216 229 / 70%)",
  },
  uplodBtn: {
    position: "absolute",
    top: "10%",
  },
  errorMsg: {
    color: "red",
    fontSize: "12px",
    padding: "10px 5px",
  },
  btn: {
    textTransform: "none",
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "15px 0",
    marginBottom: "50px",
  },
}));

export default useStyles;
