import Masonry from "react-masonry-css";

import PostCardContainer from "../PostCardContainer";
import useStyles from "./styles";

interface Props {
  postState?: any[] | null;
  refetch: () => void;
}

export default function PostContainer(props: Props): JSX.Element {
  const { classes: s } = useStyles();
  const { postState, refetch } = props;

  const breakpointColumnsObj = {
    default: 4,
    1200: 3,
    900: 2,
    500: 1,
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName={`my-masonry-grid_column ${s.gridColumn}`}
    >
      {postState?.map((item) => (
        <PostCardContainer
          key={item?.id}
          post={item}
          highlighted={!!item.highlighted?.postId}
          flagged={!!item?.flagged}
          isShared={item.isShared}
          refetch={refetch}
        />
      ))}
    </Masonry>
  );
}
