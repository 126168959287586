import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Divider } from "@mui/material";

import useStyles from "./styles";
import CustomInput from "../../components/CustomInput";
import BackgroundScreen from "../../components/BackGroundScreen";
import { Box } from "@mui/system";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";

interface ResetPasswordInterface {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});
export default function ForgotPassword(): JSX.Element {
  const { classes: s } = useStyles();
  const history = useNavigate();

  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      toast.success("Please check your email to reset the password");
    },
    onError: (error) => toast.error(graphqlErrorToMsg(error)),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function resetPassword(values: ResetPasswordInterface): void {
    forgotPassword({ variables: { email: values.email } });
  }

  return (
    <BackgroundScreen>
      <div className={s.formContainer}>
        <div className={s.formWrapper}>
          <h1 className={s.title}>Moblog</h1>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={(values) => {
              resetPassword(values);
            }}
            validationSchema={validationSchema}
          >
            {(formikProps) => {
              const {
                errors,
                handleSubmit,
                handleChange,
                touched,
                values,
                handleBlur,
              } = formikProps;

              console.log({ errors });

              return (
                <Form>
                  <CustomInput
                    name="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.email}
                    error={errors?.email}
                  />

                  <Button
                    variant="contained"
                    className={s.resetBtn}
                    onClick={() => {
                      if (!loading) {
                        handleSubmit();
                      }
                    }}
                  >
                    {loading ? "loading..." : "Reset Password"}
                  </Button>
                </Form>
              );
            }}
          </Formik>

          <Box sx={{ my: 2 }}>
            <Divider>Or</Divider>
          </Box>
          <Button
            className={s.link}
            onClick={() => {
              history("/");
            }}
          >
            Log in
          </Button>
          {/* <Button
            onClick={() => {
              history("/signup");
            }}
          >
            Sign up
          </Button> */}
        </div>
      </div>
    </BackgroundScreen>
  );
}

const FORGOT_PASSWORD = gql(`
mutation requestPassWordReset($email:String!){
  requestPassWordReset(email:$email){
    message
  }
}
`);
