import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => {
  const { text, background } = theme.palette;

  return {
    form: {
      width: "100%",
      display: "flex",
      alighItems: "center",
      justifyContent: "center",
      paddingBottom: 12,
    },
    root: {
      display: "flex",
      alighItems: "center",
      padding: "0 20px",
      borderRadius: "50px",
      border: "2px solid #023047",
      maxWidth: "500px",
      width: "98%",
    },
    input: {
      border: "none",
      outline: "none",
      fontSize: "16px",
      width: "100%",
      color: text.primary,
      "&::placeholder": {
        color: text.primary,
        opacity: 1,
      },
      background: background.default,
      margin: 1,
    },
    iconButton: {
      cursor: "auto",
      paddingLeft: 0,
      "&:hover": {
        backgroundColor: "initial",
      },
    },
  };
});

export default useStyles;
