import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { Button, Card, Grid, SvgIcon, Tab, Tabs } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import HooksInput from "../Input/Input";
import * as yup from "yup";
import { BlogTypes } from "../../__generated__/graphql";

type MobsSearchProps = {
  handleSearch: (fields: searchFields) => void;
};

const schema = yup
  .object()
  .shape({
    title: yup.string(),
    blogType: yup.string(),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

type searchFields = {
  title: string | null;
  blogType: BlogTypes;
};

export const MobsSearch = ({ handleSearch }: MobsSearchProps) => {
  const { register, handleSubmit, control, getValues } = useForm<FormData>({
    defaultValues: {
      title: "",
      blogType: BlogTypes.Mobs,
    },
  });

  //   const getOptions = () => {
  //     const options = Object.entries(POSTS_PRIVACY_LEVELS).map((ob) => ({
  //       label: ob[0].split("_").join(" "),
  //       value: ob[1],
  //     }));

  //     return options;
  //   };

  return (
    <Card sx={{ p: 2 }}>
      <form onSubmit={handleSubmit(handleSearch)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"blogType"}
              render={({ field: { onChange, value } }) => (
                <>
                  <Tabs
                    value={value}
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: BlogTypes
                    ) => {
                      onChange(newValue);
                      handleSearch({
                        title: getValues("title"),
                        blogType: newValue,
                      });
                    }}
                  >
                    <Tab value={BlogTypes.Mobs} label="Mobs" />
                    <Tab value={BlogTypes.Blogs} label="Blogs" />
                    <Tab value={BlogTypes.Logs} label="Logs " />
                    {/* <Tab value="three" label="Highlighted" /> */}
                  </Tabs>
                </>
              )}
            />
          </Grid>
          <Grid item xs={8} sm={9}>
            <HooksInput
              register={register}
              error={null}
              touched={false}
              label="Blog Name"
              name="title"
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                display: "flex",
                height: "54px",
                mb: 2,
                width: "100%",
              }}
              startIcon={
                <SvgIcon>
                  <MagnifyingGlassIcon />
                </SvgIcon>
              }
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};
