import React, { useState } from "react";
import { Box, Button, Grid, Typography, colors } from "@mui/material";
import * as yup from "yup";
import DndUpload from "../DndUpload";
import CustomButton from "../CutomButton";
import ReactHooksInput from "../ReactHooksInput";
import CustomCheckBox from "../CustomCheckBox";
import { THEMES_LIST } from "../../utils/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BlogMemberJoinType, BlogPrivacy } from "../../__generated__/graphql";
import FileUploader from "../FileUploader";
import FriendSearch from "../FriendSearch";

const schema = yup
  .object()
  .shape({
    file: yup.mixed().required(),
    title: yup.string().required().label("Title"),
    description: yup.string().required().label("Description"),
    theme: yup.number().required(),
    privacy: yup.string().required(),
    permissions: yup.string().required(),
    friends: yup.array().of(yup.number()).required(),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

type Props = {
  goBack: () => void;
  defaultValues?: any;
  onSubmit: (values: FormData) => void;
  isSubmitting?: boolean;
  isUpdate?: boolean;
  blogImage?: string | null;
};

export default function MobForm({
  defaultValues,
  goBack,
  onSubmit,
  isSubmitting,
  isUpdate,
  blogImage,
}: Props) {
  const [preview, setPreview] = useState<any>(blogImage || null);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {
      file: null,
      title: "",
      description: "",
      theme: THEMES_LIST.WALLPAPER,
      privacy: BlogPrivacy.Public,
      permissions: BlogMemberJoinType.Any,
      friends: [],
    },
    mode: "all",
  });

  const [memberModal, setMemberModal] = useState(false);
  const [selectedFriends, setSelectedFriends] = useState<[] | number[]>([]);

  const isNoOne = getValues("permissions") === BlogMemberJoinType.NoOne;

  const isPublic = getValues("privacy") === BlogPrivacy.Public;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ py: 5 }}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          {!preview && (
            <DndUpload
              hasError={!!errors?.file?.message}
              accept="image/*"
              height={250}
              onChange={(file: File | null) => {
                if (file) {
                  const objectUrl = URL.createObjectURL(file);
                  setPreview(objectUrl);
                } else {
                  setPreview(null);
                }
                setValue("file", file);
              }}
            />
          )}

          {preview && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mt: 2,
                gap: 2,
              }}
            >
              <img
                src={preview}
                alt="blog"
                style={{ maxWidth: "100%", borderRadius: 10 }}
              />
              <FileUploader
                onChange={(file: File | null) => {
                  if (file) {
                    const objectUrl = URL.createObjectURL(file);
                    setPreview(objectUrl);
                  } else {
                    setPreview(null);
                  }
                  // setFieldValue("file", file);
                }}
                component={
                  <CustomButton onClick={() => {}} label="Update Image" />
                }
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <ReactHooksInput
            label="Title"
            name="title"
            required
            placeholder="Title"
            register={register}
            error={errors.title?.message}
            touched={touchedFields.title}
          />
        </Grid>
        <Grid item xs={12}>
          <ReactHooksInput
            label="Description"
            name="description"
            inputType="textarea"
            required
            placeholder="Description"
            register={register}
            error={errors.description?.message}
            touched={touchedFields.description}
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography> Theme</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
            <CustomCheckBox
              control={control}
              name="theme"
              checked={watch("theme") === THEMES_LIST.WALLPAPER}
              onChange={() => {
                setValue("theme", THEMES_LIST.WALLPAPER);
              }}
              label={"Wallpaper Theme"}
            />
            <CustomCheckBox
              control={control}
              name="theme"
              checked={watch("theme") === THEMES_LIST.TIMELINE}
              onChange={() => setValue("theme", THEMES_LIST.TIMELINE)}
              label={"Timeline Theme"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography> Privacy</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
            <CustomCheckBox
              control={control}
              name="privacy"
              checked={watch("privacy") === BlogPrivacy.Public}
              onChange={() => {
                setValue("privacy", BlogPrivacy.Public);
                setValue("permissions", BlogMemberJoinType.Any);
              }}
              label={"Public"}
            />
            <CustomCheckBox
              control={control}
              name="privacy"
              checked={watch("privacy") === BlogPrivacy.Private}
              onChange={() => {
                setValue("privacy", BlogPrivacy.Private);
                setValue("permissions", BlogMemberJoinType.NoOne);
              }}
              label={"Private"}
            />
          </Box>
        </Grid>
        {!isUpdate && (
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography> Member Permissions</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <CustomCheckBox
                control={control}
                name="permissions"
                checked={watch("permissions") === BlogMemberJoinType.NoOne}
                onChange={() =>
                  setValue("permissions", BlogMemberJoinType.NoOne)
                }
                label={"No one"}
              />
              <CustomCheckBox
                control={control}
                name="permissions"
                disabled={isPublic}
                checked={
                  watch("permissions") === BlogMemberJoinType.ByBeingAdded
                }
                onChange={() =>
                  setValue("permissions", BlogMemberJoinType.ByBeingAdded)
                }
                label={"By being added"}
              />
              <CustomCheckBox
                control={control}
                name="permissions"
                disabled={!isPublic}
                checked={watch("permissions") === BlogMemberJoinType.Any}
                onChange={() => setValue("permissions", BlogMemberJoinType.Any)}
                label={"Anyone"}
              />
            </Box>
          </Grid>
        )}

        {!isNoOne && !isUpdate && (
          <Grid item xs={12}>
            <Button variant="outlined" onClick={() => setMemberModal(true)}>
              Add Members
            </Button>
            <Typography
              color={colors.grey[500]}
              fontSize={12}
              sx={{ pl: 1, pt: 1 }}
            >
              {selectedFriends.length > 1 &&
                `${selectedFriends.length} members added`}
              {selectedFriends.length === 1 &&
                `${selectedFriends.length} member added`}
            </Typography>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
          gap={4}
        >
          <CustomButton
            label="Cancel"
            onClick={() => goBack()}
            variant="outlined"
          />
          {isSubmitting ? (
            <CustomButton label="Loading..." onClick={() => {}} />
          ) : (
            <CustomButton
              label={isUpdate ? "Update" : "Create"}
              type="submit"
              onClick={() => {}}
            />
          )}
        </Grid>
      </Grid>
      {memberModal && (
        <FriendSearch
          userId={null}
          isLinkGenarate={false}
          currentFriends={selectedFriends}
          handleClose={() => setMemberModal(false)}
          onSave={(friends) => {
            setValue("friends", friends);
            setSelectedFriends(friends);
            setMemberModal(false);
          }}
        />
      )}
    </form>
  );
}
