import MembersList from "../../MemeberList";
import useStyles from "./styles";

type ProfileType = {
  avatarImage: string;
  firstName: string;
  lastName: string;
  userId: number;
};

type Props = {
  label: string;
  list: ProfileType[] | [];
  message?: string;
  total: number;
  hasMore: boolean;
  isFriends?: boolean;
  isFollowing?: boolean;
  isFollowers?: boolean;
  fetchMore: (isRefetch?: boolean) => void;
};

export default function UsersCardList({
  list,
  label,
  message,
  total,
  hasMore,
  fetchMore,
  isFriends,
  isFollowing,
  isFollowers,
}: Props) {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <p className={classes.subtitle}>{label}</p>
      <div className={classes.descriptionContainer}>
        <div>
          {list.length === 0 && (
            <p className={classes.info}>{message || `No ${label} yet`}</p>
          )}
          <MembersList
            isFollowers={isFollowers}
            isFollowing={isFollowing}
            isFriends={isFriends}
            members={list}
            total={total}
            label={label}
            hasMore={hasMore}
            fetchMore={fetchMore}
          />
        </div>
      </div>
    </div>
  );
}
