import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: 15,
  },
  right: { display: "flex", flexDirection: "column", paddingLeft: 10 },
  avatar: {
    width: 20,
    height: 20,
    borderRadius: 10,
  },
  actionBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  userName: {
    margin: 0,
    fontSize: 15,
    cursor: "pointer",
    fontWeight: 500,
    textTransform: "capitalize",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  normalText: {
    margin: 0,
    fontSize: 12,
    fontWeight: "bold",
    cursor: "pointer",
    color: theme.palette.text.secondary,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  timestamp: {
    fontSize: 12,
    color: theme.palette.text.primary,
  },
  icon: {
    marginLeft: 5,
    color: "green",
  },
  likeIcon: {
    marginLeft: 5,
    color: "orange",
  },
  actionWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  iconColor: {
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
