import { useState } from "react";
// import { subDays, subHours } from "date-fns";
// import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
// import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";
// import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  // Button,
  Container,
  Stack,
  // SvgIcon,
  Typography,
} from "@mui/material";
// import { useSelection } from "../utils/Hooks/use-selection";
import { CustomersSearch } from "../adminComponents/Users/customersSearch";
import { CustomersTable } from "../adminComponents/Users/customersTable";
// import { applyPagination } from "../utils/applyPagination";
import { gql } from "../__generated__";
import { useQuery } from "@apollo/client";
import PageLoader from "../components/PageLoader";
import AccountWrapper from "../adminComponents/Users/AccountWrapper";

// const now = new Date();

// const useCustomers = (page: number, rowsPerPage: number) => {
//   return useMemo(() => {
//     return applyPagination(data, page, rowsPerPage);
//   }, [page, rowsPerPage]);
// };

// const useCustomerIds = (customers: any) => {
//   return useMemo(() => {
//     return customers.map((customer: any) => customer.id);
//   }, [customers]);
// };

const UsersPage = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userEditId, setEditUser] = useState<null | number>(null);
  // const customers = useCustomers(page, rowsPerPage);
  // const customersIds = useCustomerIds(customers);
  // const customersSelection = useSelection(customersIds);

  const {
    data: userList,
    loading,
    refetch,
  } = useQuery(ACCOUNT_SEARCH, {
    notifyOnNetworkStatusChange: true,
    variables: {
      info: { email: "" },
      pagination: { nextPage: page, perPage: rowsPerPage },
    },
  });

  const handlePageChange = (event: any, value: number) => {
    setPage(value + 1);
    refetch({
      pagination: { nextPage: value + 1, perPage: rowsPerPage },
    });
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(event.target.value);
    refetch({
      pagination: { nextPage: 1, perPage: event.target.value },
    });
  };

  if (userEditId) {
    return (
      <AccountWrapper
        userId={userEditId}
        handleClose={() => setEditUser(null)}
        refetch={() => refetch()}
      />
    );
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Users List</Typography>
                {/* <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowUpOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Import
                  </Button>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Export
                  </Button>
                </Stack> */}
              </Stack>
              {/* <div>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Add
                </Button>
              </div> */}
            </Stack>
            <CustomersSearch
              handleSearch={(search) =>
                refetch({
                  info: {
                    email: search.email,
                    firstName: search.firstName,
                    lastName: search.lastName,
                  },
                  pagination: {
                    nextPage: 1,
                    perPage: rowsPerPage,
                  },
                })
              }
            />
            {loading && <PageLoader />}
            {userList && (
              <CustomersTable
                count={userList.accountsSearch.paginationDetails?.totalRecords}
                items={userList.accountsSearch.data}
                // onDeselectAll={customersSelection.handleDeselectAll}
                // onDeselectOne={customersSelection.handleDeselectOne}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                // onSelectAll={customersSelection.handleSelectAll}
                // onSelectOne={customersSelection.handleSelectOne}
                page={userList.accountsSearch.paginationDetails?.currentPage}
                rowsPerPage={rowsPerPage}
                setEditUser={setEditUser}
                // selected={customersSelection.selected}
              />
            )}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default UsersPage;

const ACCOUNT_SEARCH = gql(`
  query AccountsSearch($pagination:Pagination,$info:AccountSearchInput!){
    accountsSearch(pagination:$pagination,info:$info){
      paginationDetails{
        currentPage
        lastPage
        totalRecords
      }
      data{
        userId
        email
        firstName
        lastName
        phone
        address1
        address2
        city
        state
        zip
        userName
        profile{
          userName
          avatarImage
        }
        roles
        createdAt
      }
    }
  }
`);
