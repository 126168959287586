import React from "react";

import useStyles from "./styles";

interface InfoProps {
  message: string;
}

export default function Info({ message }: InfoProps): JSX.Element {
  const { classes: s } = useStyles();
  return (
    <div className={s.root}>
      <div className={s.message}>{message}</div>
    </div>
  );
}
