import React from "react";
import { Box, Grid } from "@mui/material";
import { Container } from "@mui/system";
import CoverImage from "../../components/CoverImage";
import useStyles from "../../pages/ProfilePage/styles";
import { Account } from "../../__generated__/graphql";
import { ProfileComponentProps } from "../../types/commom";

interface CoverThemeProps {
  userDetails: Pick<Account, "profile">;
  blogDetails: any;
  profileComponent: (props?: ProfileComponentProps) => JSX.Element;
  mainComponent: () => JSX.Element;
  acitivityComponent: () => JSX.Element;
  tabComponent: () => JSX.Element;
}

export default function CoverTheme({
  blogDetails,
  mainComponent,
  profileComponent,
  acitivityComponent,
  tabComponent,
}: CoverThemeProps): JSX.Element {
  const { classes: s } = useStyles();

  const coverImage = blogDetails?.themeMediaInfo?.url;
  return (
    <div className={s.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CoverImage
            imageUrl={coverImage || null}
            themeId={blogDetails.theme}
            blogId={blogDetails.id}
            userId={blogDetails.userId}
            title={blogDetails.name}
          />
        </Grid>
      </Grid>
      <Container sx={{ pt: 5, maxWidth: "95% !important" }}>
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            mt={5}
            sx={{ display: { sm: "flex", md: "none" } }}
          >
            <Grid item xs={12}>
              {profileComponent({ center: true })}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {tabComponent()}
          </Grid>
          <Grid item md={8} xs={12}>
            {mainComponent()}
          </Grid>
          <Grid
            item
            sm={4}
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
            }}
          >
            <Box sx={{ mb: 5 }}>
              {profileComponent()}
              {acitivityComponent()}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
