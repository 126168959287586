import React, { ReactElement } from "react";
import { gql } from "../../__generated__";
import { useQuery } from "@apollo/client";
import PageLoader from "../../components/PageLoader";
import { BlogTypes } from "../../__generated__/graphql";
import { BLOGS_PRIVACY_LEVEL } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../utils/routes";
import { MobsContext } from "../../Context";
import { Box, Container } from "@mui/material";

type Props = { mobId: number; children: ReactElement };

export default function BlogPermissionContainer({ mobId, children }: Props) {
  const navigate = useNavigate();
  const { data, loading, refetch } = useQuery(BLOG_PERMISSION, {
    variables: { blogId: mobId },
    fetchPolicy: "network-only",
  });
  if (loading) return <PageLoader />;
  if (!loading && !data)
    return (
      <Container>
        <Box sx={{ p: 3, textAlign: "center" }}>Someting went wrong</Box>
      </Container>
    );
  const isMob = data?.loggedUserBlogPermission.blogType === BlogTypes.Mobs;
  const isLog = data?.loggedUserBlogPermission.blogType === BlogTypes.Logs;
  const isPersonal =
    data?.loggedUserBlogPermission.blogType === BlogTypes.Personal;
  const isPrivate =
    data?.loggedUserBlogPermission.blogPrivacyLevel ===
    BLOGS_PRIVACY_LEVEL.PRIVATE;
  const isMember = !!data?.loggedUserBlogPermission.alreadyMember;
  const isOwner = !!data?.loggedUserBlogPermission.isOwner;

  const redirectToDashboard = () => {
    navigate(ROUTES.DASHBOARD);
  };
  // check permissions
  if (isMob && isPrivate && !isMember) {
    console.log("private mob");
    redirectToDashboard();
  } else if (isLog && !isOwner) {
    console.log("private log");
    redirectToDashboard();
  } else if (isPersonal) {
    console.log("personal blog");
    redirectToDashboard();
  }
  return (
    <MobsContext.Provider
      value={{
        refetch: () => refetch(),
        alreadyMember: isMember,
        isOwner,
        blogType: data?.loggedUserBlogPermission.blogType || BlogTypes.Mobs,
        blogPrivacyLevel:
          data?.loggedUserBlogPermission.blogPrivacyLevel ||
          BLOGS_PRIVACY_LEVEL.PUBLIC,
      }}
    >
      <div>{children}</div>
    </MobsContext.Provider>
  );
}

const BLOG_PERMISSION = gql(`query LoggedUserBlogPermission($blogId:Int!){
    loggedUserBlogPermission(blogId:$blogId){
      blogType
      alreadyMember
      isOwner
      blogPrivacyLevel
    }
  }`);
